@import '/src/core/styles/mixins';

.statusChangeEvent {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &__tag {
    width: auto;
    max-width: 100%;
    margin-top: 2px;
    margin-bottom: 2px;
    text-transform: none;

    &--prev {
      margin-right: 8px;
    }
  }

  &__arrow {
    margin-right: 8px;
  }

  &__tooltip {
    @include text-overflow($width: calc(100% + 1px), $max-width: calc(100% + 1px)); // need this values because of safari bug (e.g. INA4VAN-2707)
    display: block;
  }

  &__overlay {
    @include tooltip-sm();
  }
}
