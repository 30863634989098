@import "src/core/styles/mixins";
@import "src/core/styles/variables";

.timeline {
  @include flex-column();
  align-items: flex-start;
  .ant-timeline-item {
    width: 100%;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    [class*=ant-timeline-item-label] {
      white-space: nowrap;
      width: 170px;
      margin-right: 20px;
      text-align: left !important;
      color: $main-dark-blue !important;
      z-index: 1;
      @media (max-width: $lg) {
        width: 135px;
        font-size: $fsz-12;
      }
    }

    [class*=ant-timeline-item-tail] {
      left: 200px !important;
      border-color: $main-dark-blue !important;
      top: 20px !important;
      height: 100% !important;
      @media (max-width: $lg) {
        left: 150px !important;
      }
    }
    [class*=ant-timeline-item-head] {
      left: 190px !important;
      width: 30px !important;
      height: 30px !important;
      border-color: $main-dark-blue !important;
      @media (max-width: $lg) {
        left: 140px !important;
      }
    }
    [class*=ant-timeline-item-content] {
      left: 0 !important;
      width: 100% !important;
      max-width: 800px;
      padding-left: 245px;
      margin: 0;
      color: $main-dark-blue !important;

      @media (max-width: $lg) {
        padding-left: 175px;
        font-size: $fsz-12;
      }
    }
  }
  .timeline-info {
    &__time {
      font-weight: $font-weight-bold;
    }

    &__initiator {
      display: flex;
    }

    &__initiator-name {
      @include text-overflow($max-width: 100%);
    }
  }

  b {
    font-weight: $font-weight-bold;
  }

  .customButton {
    height: auto;
    padding: 0;
    background-color: transparent;
    font-weight: $font-weight-regular;
    line-height: inherit;
    font-size: inherit;
    @include link();
  }

  .entityName {
    text-decoration: underline;
    color: $main-dark-blue;
    &.not-link {
      display: inline;
      text-decoration: none;
    }
  }
}

.timeline-info__tooltip {
  .ant-tooltip-inner {
    @media (max-width: $lg) {
      max-width: 250px;
      padding: 10px !important;
      font-size: 14px;
    }
  }
}
