.suitableForVehiclesChangeTh {
  &--number {
    min-width: 60px;
  }
  &--groupName {
    width: 50%;
    min-width: 120px;
  }
  &--numberOfVehicleProfiles {
    width: 50%;
    min-width: 220px;
  }
  &--code {
    width: 30%;
    min-width: 100px;
  }
  &--manufacturer {
    width: 30%;
    min-width: 120px;
  }
  &--modelDescription {
    width: 40%;
    min-width: 160px;
  }
}


