@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.OpenEmailClientModal {
  &__content {
    @include flex-column();
  }

  &__text {
    font-size: $fsz-16;
  }

  &__button.customButton {
    padding-top: 10px !important;
    line-height: 1;
    align-self: center;

    @media (max-width: $lg) {
      padding-top: 8px !important;
    }
  }
}
