@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.createEmployeeForm {
  .customDragger {
    .ant-upload-list {
      min-height: 0;
    }

    .ant-upload.ant-upload-drag {
      margin-bottom: 0;
    }

    .fileItem {
      margin-top: 12px;
      margin-bottom: 0;
    }
  }

  .tableWrap {
    @media (max-width: $lg) {
      position: static;
      margin: 0;
      padding-left: 0;

      table::before {
        display: none;
      }
    }
  }

  .details-form__actions {
    margin-top: 30px;
  }
}
