@import '../variables';
@import '../mixins';

.contacts {
  &__wrapper {
    margin-top: 20px;
    @media (max-width: $lg) {
      margin-top: 15px;
    }
  }

  &__loader {
    display: block;
    padding: 8px 0;
    color: $main-dark-blue;
    font-weight: $font-weight-bold;
    text-align: center;
  }
}

.contact {
  @include flex-between();
  border: $border-dark-gray;
  border-radius: 10px;
  padding: 15px 20px;
  @media (max-width: $lg) {
    align-items: start;
    padding: 10px;
  }

  &__content {
    width: calc(98% - 80px);
    display: grid;
    grid-template-columns: 21% 21% 15% 15% 28%;
    gap: 20px;
    color: $main-dark-blue;
    @media (max-width: $lg) {
      width: calc(100% - 30px);
      gap: 10px;
      grid-template-columns: 100%;
    }
  }

  &__user {
    @include flex-start();
  }

  &__icon {
    height: 50px;
    margin-right: 15px;
    @media (max-width: $lg) {
      height: 30px;
      width: 30px;
      margin-right: 10px;
    }
  }

  &__name-wrap {
    @include flex-column();
    overflow: hidden;
  }

  &__name {
    @include text-overflow($max-width: 100%);
    margin-bottom: 0;
    font-size: $fsz-16;
    font-weight: $font-weight-bold;

    @media (max-width: $lg) {
      font-size: $fsz-14;
    }
  }

  &__position {
    @include text-overflow($max-width: 100%);
    font-size: $fsz-14;
  }

  &__info {
    @media (min-width: $lg + 1) {
      @include flex-start();
    }
    @media (max-width: $lg) {
      display: grid;
      grid-template-columns: 40px 1fr;
    }

    &:not(:last-of-type) {
      .contact__info-value {
        @include text-overflow($max-width: 100%);
      }
    }

    &:last-of-type {
      padding-right: 0;
      .contact__info-value {
        width: 100%;
        @include vertical-text-overflow();
      }
    }
  }

  &__info-value {
    font-size: $fsz-14;

    &:empty {
      &::before {
        content: '-';
      }
    }
  }

  &__info-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  &__dropdown {
    flex-shrink: 0;
    outline: none;
    border: none;
    background: none;
    padding: 0 8px;
    @media (max-width: $lg) {
      margin-left: 8px;
      margin-top: 10px;
    }

    &:focus-visible {
      @include icon-focus();
    }

    &:disabled {
      cursor: not-allowed;
      .svg-inline--fa {
        color: $main-gray;
      }
    }

    &:hover .svg-inline--fa {
      color: $main-dark-blue;
    }

    .svg-inline--fa {
      font-size: $fsz-18;
      color: $dark-gray;
      transition: $transition;
      transform: none !important;
    }
  }

  &--selectable {
    .contact__checkbox {
      @include table-checkbox();
      margin-right: 20px;
    }

    @media (max-width: $lg) {
      flex-direction: column;
    }

    @media (min-width: $lg + 1) {
      padding: 10px 15px;
      .contact {
        &__content {
          grid-template-columns: repeat(3, minmax(0, 1fr));
          width: 100%;
        }

        &__user {
          grid-column: 1/2;
          grid-row: span 2;
        }

        &__icon {
          height: 40px;
          margin-right: 10px;
        }
        &__info-icon {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

.hiddenContactInfo {
  display: none !important;
}
