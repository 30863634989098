@import "src/core/styles/mixins";
@import "src/core/styles/variables";

.upload {
  &-btn {
    border-radius: 5px !important;
  }
}

.upload-label {
  @include field-label();
}

.customDragger {
  &__image {
    width: 60px;
    height: 60px;
  }

  .fileItem {
    @include flex-between();
    border: $border-main-gray;
    border-radius: 10px !important;
    padding: 8px 16px;
    margin-bottom: 12px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;

    &-icon {
      flex-shrink: 0;
      cursor: pointer;
      color: $main-dark-blue;
      margin-left: 8px;
    }
  }

  .ant-upload-list {
    min-height: 10px;
    max-height: 140px !important;
    overflow: auto;
    @include scroll(4px);
  }

  .ant-upload-list-item-info {
    border: $border-main-gray;
    border-radius: 5px;
    min-height: 26px;
    padding-left: 8px;
  }

  .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    color: $main-dark-blue;
    font-size: 60px;
  }

  .ant-upload.ant-upload-drag {
    border-radius: 10px;
    margin-bottom: 24px;
  }

  .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover,
  .ant-upload.ant-upload-drag:not(.ant-upload-disabled):focus,
  .ant-upload.ant-upload-drag:not(.ant-upload-disabled):active {
    border-color: $main-dark-blue;
  }

  .ant-upload-disabled {
    color: $accent-gray;
    p.ant-upload-hint,
    p.ant-upload-text {
      color: $accent-gray;
    }
  }
}
