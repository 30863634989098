.stockPartRequestLinesViewTh {
  &--ordinalNumber {
    min-width: 60px;
  }
  &--customer {
    min-width: 130px;
    width: 33.333%;
  }
  &--partRequestNumber {
    min-width: 155px;
  }
  &--linkedEntityNumber {
    min-width: 185px;
  }
  &--linkedEntityStatus {
    min-width: 190px;
  }
  &--category {
    min-width: 120px;
  }
  &--productNumber {
    min-width: 170px;
    width: 33.333%;
  }
  &--productName {
    min-width: 190px;
    width: 33.333%;
  }
  &--source {
    min-width: 85px;
  }
  &--quantity {
    min-width: 85px;
  }
  &--ready {
    min-width: 85px;
  }
  &--collected {
    min-width: 100px;
  }
  &--status {
    min-width: 160px;
  }
}
