@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

$column-width: 278px;
$column-width-mobile: 260px;

.jobsCalendarDatesRange {
  @media (max-width: $lg) {
    height: 40px !important;
  }
  .ant-segmented-group .ant-segmented-item-label {
    padding-right: 15px;
    padding-left: 15px;

    @media (max-width: $lg) {
      height: 40px;
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: $fsz-16;
    }
  }
}

@mixin jobs-table() {
  display: inline-grid;
  grid-template-columns: repeat(var(--columns-count), auto);
  column-gap: 20px;
}

.jobsList {
  overflow-x: scroll;
  margin-bottom: -20px;
  margin-right: -20px;
  padding-bottom: 10px;
  padding-right: 20px;
  @include scroll(6px);
  @media (max-width: $lg) {
    margin-right: -10px;
    margin-bottom: -10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }

  &__heading {
    @include jobs-table();
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: $color-white;
  }

  &__content {
    @include jobs-table();
  }

  &__column {
    background-color: $color-gray;
    min-height: 240px;
    border-radius: 0 0 10px 10px;
    padding: 18px 12px;

    @media (max-width: $lg) {
      padding: 12px;
      padding-top: 14px;
    }

    &-head {
      @include flex-between();
      position: relative;
      box-sizing: content-box;
      width: $column-width;
      border-bottom: 2px solid transparent;
      border-radius: 10px 10px 0 0;
      padding: 18px 12px 12px 12px;
      background-color: $color-gray;

      &::after {
        content: '';
        position: absolute;
        right: 12px;
        left: 12px;
        bottom: 0;
        height: 2px;
        background-color: $main-dark-blue;
      }
      @media (max-width: $lg) {
        width: $column-width-mobile;
        padding: 12px;
        padding-top: 14px;
      }

      &__label {
        font-size: $fsz-15;
        margin-right: 10px;
        white-space: nowrap; // bugfix: this line should be displayed in 1 row only
        font-weight: $font-weight-bold;
        @media (max-width: $lg) {
          font-size: $fsz-14;
          margin-right: 8px;
        }
      }

      &__total {
        font-size: $fsz-14;
        white-space: nowrap; // bugfix: this line should be displayed in 1 row only
        color: $primary-dark-blue;

        .totalPerFilter {
          font-weight: $font-weight-bold;
        }
      }
    }

    &-content {
      width: $column-width;
      height: 100%;
      border-radius: 0 0 5px 5px;
      transition: $transition;
      @media (max-width: $lg) {
        width: $column-width-mobile;
      }

      &.dragginOver {
        background-color: rgba(115,108,237, .1);
        transition: $transition;
      }
    }

    &-loadMore {
      width: 100%;
      height: 30px !important;
      font-size: $fsz-14 !important;
      background-color: $main-dark-blue !important;
      color: $main-yellow !important;
      font-weight: $font-weight-bold !important;
      border-radius: 8px !important;
      border: $border-dark-blue !important;
      display: block;

      &:hover {
        color: $color-white !important;
        border: $border-dark-blue !important;
      }

      &.loadMoreHidden {
        display: none;
      }
    }
  }
}
