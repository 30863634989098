@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.vehicleDetailsSection {
    .vehicleManualFields,
    .commonVehicleFields {
        @include expanded-sidebar(1550px) {
            &__field--2,
            &__field--3 {
                grid-column: 1/-1;
            }
        }

        @media (min-width: $lg + 1) and (max-width: 1320px) {
            &__field--2,
            &__field--3 {
                grid-column: 1/-1;
            }
        }
    }
}

