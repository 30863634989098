@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.customButton {
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 25px;
  font-size: $fsz-18;
  font-weight: $font-weight-bold;
  line-height: 1;
  border: $border-dark-gray;
  border-radius: 10px;
  box-shadow: none;
  color: $main-dark-blue;
  transition: $transition;
  @include hover-focus-active() {
    color: $main-dark-blue;
    border-color: $main-dark-blue;
  }

  @media (max-width: $lg) {
    height: 40px;
    font-size: $fsz-14;
    padding: 8px 12px;
  }

  & > svg {
    margin-right: 10px;
  }

  &--offset-right {
    margin-right: 20px;
  }

  &--with-count {
    position: relative;
    &::after {
      display: block;
      counter-reset: count var(--count-value);
      content: counter(count);
      position: absolute;
      top: 5px;
      right: 5px;
      padding: 4px;
      background-color: $main-yellow;
      color: $main-dark-blue;
      font-size: $fsz-10;
      border-radius: 8px;
      min-width: 16px;
      //styles for preventing ant design ripple effect
      bottom: auto;
      left: auto;
      animation: none;
      box-shadow: none;
      opacity: 1;

      @media (max-width: $lg) {
        top: 1px;
        right: 1px;
      }
    }
  }

  //designType="light-inverse"
  &--light-inverse {
    @include hover-focus-active() {
      color: $color-white;
      background-color: $main-dark-blue;
    }
    &.customButton--is-activated {
      color: $main-yellow;
      background-color: $main-dark-blue;
      border-color: $main-dark-blue;
      &:disabled {
        background-color: $accent-gray;
        border-color: $accent-gray;
        color: $main-gray;

        @include hover-focus-active() {
          background-color: $accent-gray;
          border-color: $accent-gray;
          color: $main-gray;
        }
      }
    }
  }

  //designType="dark"
  &--dark {
    border-color: $main-dark-blue;
    color: $main-yellow;
    background-color: $main-dark-blue;

    @include hover-focus-active() {
      color: $color-white;
      background-color: $main-dark-blue;
    }

    &:disabled {
      color: $accent-gray;
      background-color: $main-dark-blue;
      border-color: $main-dark-blue;
      @include hover-focus-active() {
        color: $accent-gray;
        background-color: $main-dark-blue;
        border-color: $main-dark-blue;
      }
    }
  }

  &--reversed {
    flex-direction: row-reverse;
    & > svg {
      margin-left: 10px;
      margin-right: 0;
    }
    .ant-btn-loading-icon .anticon {
      padding-right: 0;
    }
    .ant-btn-loading-icon {
      margin-left: 10px;
    }
  }

  // type="text"
  &.ant-btn-text {
    height: 32px;
    padding: 4px 15px;
    font-size: $fsz-14;
    font-weight: $font-weight-bold;
    line-height: 1.57;
    border: 0;
    border-radius: 4px;

    &:not([disabled]) {
      @include hover-focus-active() {
        color: $main-dark-blue;
      }
    }

    & > svg {
      font-size: $fsz-16;
    }
  }

  //single icon (without 'label' prop)
  &.ant-btn-icon-only {
    width: 50px;
    display: inline-flex;
    align-items: center;
    padding: 0;

    & > svg {
      margin: 0;
      font-size: $fsz-22;
      @media (max-width: $lg) {
        font-size: $fsz-18;
      }
    }

    &:not(:disabled) {
      color: $dark-gray;
      &:hover,
      &:focus,
      &.customButton--is-activated {
        color: $main-dark-blue;
        border-color: $main-dark-blue;
      }
    }

    @media (max-width: $lg) {
      width: 40px;
    }
  }

  &.customButton--expand {
    width: auto;
    height: auto;
    margin-right: 8px;
    border: 0;

    &:not(:disabled) {
      color: $main-dark-blue;
    }

    & > svg {
      font-size: 16px;
    }
  }
}

// type="link" && disableDefaultStyles
.ant-btn-link {
  padding: 0;
  border: 0;
  color: $main-dark-blue;

  span {
    text-decoration: underline;
  }

  @include hover-focus-active() {
    color: $main-dark-blue;
    span {
      text-decoration: none;
    }
  }
}



