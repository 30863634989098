@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.dashboardSkeleton {
    width: calc(100% + 20px);
    overflow: hidden;
    display: inline-grid;
    grid-auto-flow: column;
    column-gap: 20px;
    margin-bottom: -20px;

    @media (max-width: $lg) {
        width: calc(100% + 10px);
        margin-right: -10px;
        margin-bottom: -10px;
    }

    &__column {
        padding: 0 12px 18px;
        background-color: $color-gray;
        border-radius: 10px;
    }

    &__heading {
        @include flex-between();
        flex-wrap: wrap;
        height: 59.57px;
        border-bottom: 2px solid $main-dark-blue;
        margin-bottom: 18px;

        @media (max-width: $lg) {
            height: 48px;
            margin-bottom: 16px;
        }

        &--with-cost {
            align-content: center;
            height: 79.14px;
        }
    }

    &__title,
    &__count,
    &__cost {
        height: 20px;

        @media (max-width: $lg) {
            height: 18px;
        }
    }

    &__title {
        width: 110px;
    }

    &__count {
        width: 65px;
    }

    &__cost {
        width: 100%;
        margin-top: 14px;
        border-radius: 6px;
        background-color: $color-white;
    }
}
