@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.accountField {
  &__label {
    @include field-label();
  }

  &__content {
    @include flex-start();
    padding-bottom: 10px;
    border-bottom: $border-main-gray;
  }

  &__value {
    @include text-overflow($max-width: 100%);
    font-weight: $font-weight-bold;
  }

  &__button.ant-btn-icon-only {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    border: 0;
    background-color: transparent;

    & > svg {
      font-size: $fsz-20;
    }
  }
}
