@import "src/core/styles/mixins";
@import "src/core/styles/variables";

.spinner {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 50;
  @include flex-center();
  background-color: rgba(255,255,255,.5);

  &--global {
    position: fixed;
    z-index: 999;
  }
}

.ant-spin-dot-item {
  background-color: $main-yellow;
  opacity: 1;
  animation: none;
}
