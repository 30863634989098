.unfinishedEntitiesReportTh {
  &--number {
    min-width: 70px;
  }
  &--entityType {
    width: 15%;
    min-width: 150px;
  }
  &--entityNumber {
    width: 15%;
    min-width: 150px;
  }
  &--customerName {
    width: 25%;
    min-width: 170px;
  }
  &--entityStatus {
    width: 15%;
    min-width: 160px;
  }
  &--salesNet {
    width: 15%;
    min-width: 155px;
  }
  &--creationDate {
    width: 15%;
    min-width: 170px;
  }
}
