@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.partReqViewPageHead {
  @media (max-width: $lg) {
    height: 180px;
  }

  .head__inner {
    grid-template-columns: 50px repeat(7, minmax(0, 1fr)) 50px;
    @media (max-width: $md) {
      grid-template-columns: 40px repeat(7, minmax(0, 1fr)) 40px;
      column-gap: 10px;
    }

    .dropdownActions {
      grid-column: -1/-2;
    }
  }

  .head__inner-info-item {
    &:nth-child(3) {
      @media (max-width: $lg) {
        grid-column: 1/4;
      }
    }
    &:nth-child(4) {
      grid-column: 4/5;
      @media (max-width: $lg) {
        grid-column: 4/7;
      }
    }
    &:nth-child(5) {
      grid-column: 5/6;
      @media (max-width: $lg) {
        grid-column: 7/10;
      }
    }
    &:nth-child(6) {
      grid-column: 6/7;
      @media (max-width: $lg) {
        grid-column: 1/3;
      }
    }
    &:nth-child(7) {
      @media (max-width: $lg) {
        grid-column: 4/7;
      }
    }
    &:nth-child(8) {
      @media (max-width: $lg) {
        grid-column: 7/10;
      }
    }
  }
}

.stockPartReqVehicleSection {
  margin: 24px 0;
}

.stockPartReqTh {
  &--ordinalNumber {
    min-width: 85px;
  }
  &--categoryId {
    width: 13%;
    min-width: 160px;
  }
  &--productNumber {
    width: 14%;
    min-width: 175px;
  }
  &--productName {
    width: 23%;
    min-width: 200px;
  }
  &--notes {
    width: 13%;
    min-width: 110px;
  }
  &--priceSources {
    width: 11%;
  }
  &--quantity {
    width: 9%;
    min-width: 80px;
  }
  &--readyQuantity {
    width: 9%;
    min-width: 100px;
  }
  &--collectedQuantity {
    width: 9%;
    min-width: 100px;
  }
  &--status {
    width: 12%;
    min-width: 120px;
  }
}
