.partsKitTimelineComponentsTh {
  &--number {
    min-width: 65px;
    width: 65px;
    max-width: 65px;
  }
  &--categoryId {
    width: 16%;
    min-width: 120px;
  }
  &--partNumber {
    width: 17%;
    min-width: 110px;
  }
  &--name {
    width: 17%;
    min-width: 130px;
  }
  &--quantity {
    width: 10%;
    min-width: 65px;
  }
  &--unitPrice {
    width: 10%;
    min-width: 105px;
  }
  &--netPrice {
    width: 10%;
    min-width: 100px;
  }
  &--tax {
    width: 10%;
    min-width: 60px;
  }
  &--weight {
    width: 10%;
    min-width: 95px;
  }
}

