.generatePurchaseOrderTh {
  &--suppliers{
    width: 23%;
    min-width: 100px;
  }
  &--productNumber {
    width: 27%;
    min-width: 220px;
  }
  &--productName {
    width: 23%;
    min-width: 200px;
  }
  &--sourceId {
    width: 17%;
    min-width: 100px;
  }
  &--quantity {
    width: 10%;
    min-width: 100px;
  }
}
