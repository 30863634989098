@import "src/core/styles/mixins";
@import "src/core/styles/variables";

.stockSearch {
  &__filters {
    @include flex-column();
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: $color-gray;
    padding: 20px 15px !important;
    @media (max-width: $lg) {
      position: relative;
      padding: 15px 10px 65px 10px !important;
    }

    .ant-badge-count {
      background-color: $main-yellow;
      color: $main-dark-blue;
      @media (max-width: $lg) {
        height: 15px;
        min-width: 15px;
        padding: 0;
        font-size: $fsz-10;
        line-height: 1.6;
      }
    }
  }

  &__simple {
    @include flex-center();
    .ant-badge {
      flex-shrink: 0;
      @media (min-width: $lg + 1) {
        margin-right: 15px;
      }
    }
  }

  &__simple-input {
    flex: 1;
    margin-right: 15px;
  }

  &__search-btn {
    @media (max-width: $lg) {
      position: absolute;
      bottom: 15px;
      width: 115px;
    }
  }

  &__expand-btn.ant-btn-icon-only {
    @media (max-width: $lg) {
      width: 50px;
      height: 50px;
    }
  }

  &__advanced {
    padding-top: 30px;
    @media (min-width: $lg + 1) {
      padding-left: 0;
      margin-left: -10px;
    }
    @media (max-width: $lg) {
      padding: 10px 0 0 0;
    }
    &.advancedHidden {
      display: none;
    }
  }

  &__paging {
    margin-bottom: 20px;
    @media (max-width: $lg) {
      margin-bottom: 15px;
    }
  }
}
