@import "src/core/styles/mixins";
@import "src/core/styles/variables";

.sourceModalInner {
  @include flex-column();

  &__head {
    margin-bottom: 20px;

    &-icon {
      margin-right: 12px;
    }

    &-title {
      text-transform: uppercase;
    }
  }

  .valueCell {
    @include flex-center();
    min-width: 160px;
    border: $border-main-gray;
    border-radius: 10px;
    width: 80px;
    height: 32px;
    background-color: $color-gray;
    cursor: not-allowed;
    font-size: $fsz-16;
    @media (max-width: $lg) {
      font-size: $fsz-14;
    }
  }

  .table-footer {
    font-weight: $font-weight-bold;
  }
}

.manualInputUnitPrice {
  .regularInputNumber__numFieldWrap-field {
    min-height: 32px;
    height: 32px;
    text-align: center;
  }
}

.productSourceTh {
  &--checkbox {
    min-width: 60px;
    width: 60px;
    max-width: 60px;
  }

  &--name {
    width: 100%;
    min-width: 220px;
  }
}

.sourceQtyLine {
  &__text:not(:last-child) {
    margin-right: 6px;
  }

  &__tooltip-overlay {
    @include tooltip-sm();
  }
}

