.jobPartsTh {
  &--ordinalNumber {
    min-width: 85px;
  }
  &--categoryId {
    width: 13%;
    min-width: 160px;
  }
  &--productNumber {
    width: 16%;
    min-width: 175px;
  }
  &--productName {
    width: 24%;
    min-width: 200px;
  }
  &--notes {
    width: 13%;
    min-width: 110px;
  }
  &--quantity {
    width: 11%;
    min-width: 80px;
  }
  &--ready {
    width: 11%;
    min-width: 100px;
  }
  &--collected {
    width: 11%;
    min-width: 100px;
  }
  &--status {
    width: 14%;
    min-width: 120px;
  }
}

.jobParts {
  &__line-tip {
    margin-left: 4px;
  }
}
