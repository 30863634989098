.partSubcategoriesTh {
  &--keyword {
    width: 45%;
    min-width: 190px;
  }
  &--createdAt,
  &--updatedAt {
    width: 27.5%;
    min-width: 195px;
  }
}

.affectedCategoriesTh {
  &--entityType {
    width: 20%;
    min-width: 150px;
  }
  &--entityNumber {
    width: 20%;
    min-width: 150px;
  }
  &--createdAt {
    width: 20%;
    min-width: 130px;
  }
  &--entityStatus {
    width: 20%;
    min-width: 130px;
  }
  &--businessArea {
    width: 20%;
    min-width: 140px;
  }
}

.partSubcategoryCell__action-overlay {
  .ant-dropdown-menu-title-content {
    white-space: nowrap;
  }
}
