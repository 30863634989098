@import 'fonts';

/* Fonts */
$fsz-10 : 10px;
$fsz-11 : 11px;
$fsz-12 : 12px;
$fsz-13: 13px;
$fsz-14 : 14px;
$fsz-15 : 15px;
$fsz-16 : 16px;
$fsz-18 : 18px;
$fsz-20 : 20px;
$fsz-22 : 22px;
$fsz-24 : 24px;
$fsz-26 : 26px;
$fsz-28 : 28px;
$fsz-30 : 30px;
$fsz-32 : 32px;
$fsz-36 : 36px;
$fsz-38 : 38px;

// // font-family
$montserrat-font: "Montserrat", "Arial", sans-serif;

// // font-weight
$font-weight-regular: 400;
$font-weight-bold: 700;

/* Colors */
$color-black: #000000;
$color-white: #ffffff;
$color-orange: #FFCF1F;
$color-red: #D61C4E;
$color-violet: #736ced;
$color-green: #4E9F3D;
$main-light-gray: #F5F5F5;
$main-light-gray-100: #F2F2F2;
$main-gray: #d9d9d9;
$accent-gray: #7F8897;
$color-secondary-dark: #49525E;
$primary-dark-blue: #2C4251;
$light-blue: #F3F6F9;
$transparent: rgba(#ffffff, 0);

$gray-stripes: repeating-linear-gradient(
                to top left,
                $main-light-gray-100,
                $main-light-gray-100 30px,
                $color-white 30px,
                $color-white 60px
);

/* UI Kit Colors */
$main-dark-blue: #3A4557;
$accent-blue: #4D5767;
$color-gray: #F5F6F6;
$accent-gray: #AFB3BA;
$dark-gray: #89909A;
$secondary-dark-gray: #A2A7B0;
$main-yellow: #FFCC11;
$skeleton-color: darken($color-gray, 10%);
$placeholder-color: #BFBFBF;

/* Borders */
$border-main-gray: 1px solid $main-gray;
$border-accent-gray: 1px solid $accent-gray;
$border-main-yellow: 1px solid $main-yellow;
$border-accent-gray: 1px solid $accent-gray;
$border-dark-gray: 1px solid $dark-gray;
$border-dark-blue: 1px solid $main-dark-blue;
$border-dark-blue-alpha: 1px solid rgba($main-dark-blue, 0.5);
$table-line: 2px solid $main-dark-blue;
$skeleton-border: 1px solid $skeleton-color;

$table-border-radius: 10px;

/* Sizes */
$sidebar-wide: 315px;
$sidebar-collapsed: 100px;
$sidebar-wide-mobile: 300px;
$sidebar-collapsed-mobile: 70px;
$header-height-desktop: 80px;
$header-height-mobile: 70px;

// transition
$transition: .15s linear;

/* Media breakpoint */
$xs: 400px;
$sm: 575px;
$md: 768px;
$lg: 1180px;
$xl: 1600px;

// gaps
$gap16: 16px;
$gap10: 10px;

//z-indexes
$z-index-header: 10;
$z-index-subheader: 9;
$z-index-sticky-filters: 6;
$z-index-select-dropdown: 5;
