@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.sourceQty {
  display: flex;
  align-items: center;

  &__plus,
  &__minus {
    &.ant-btn-icon-only {
      width: 32px;
      height: 32px;

      & > svg {
        font-size: $fsz-10;
      }
    }
  }

  &__minus {
    margin-right: 12px;
  }
  &__plus {
    margin-left: 12px;
  }

  &__value {
    width: 80px;

    .regularInputNumber__numFieldWrap-field {
      min-height: 32px;
      height: 32px;
      text-align: center;
    }
  }
}
