@import 'src/core/styles/mixins';
@import 'src/core/styles/variables';

.addPartModal {
  @include flex-column();

  &__filters {
    margin-bottom: 20px;
    @media (max-width: $lg) {
      margin-bottom: 15px;
    }
  }

  &__filters-list {
    margin-bottom: 20px;
    @media (max-width: $lg) {
      margin-bottom: 15px;
    }
  }

  &__pagination {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    @media (max-width: $lg) {
      margin-bottom: 15px;
    }
  }
}

.addPartToPricelistTh {
  &--number {
    min-width: 65px;
    width: 65px;
    max-width: 65px;
  }
  &--partCode {
    width: 26%;
    min-width: 165px;
  }
  &--manufacturerCode {
    width: 22%;
    min-width: 240px;
  }
  &--name {
    width: 26%;
    min-width: 170px;
  }
  &--description {
    width: 26%;
    min-width: 185px;
  }
}
