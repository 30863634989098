@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.priceListFilters {
  @media (max-width: $lg) {
    margin-bottom: 15px;
  }
}

.pricelistLine {
  @include line-link();
  @media (min-width: $lg + 1) {
    @include flex-start();
  }
  @media (max-width: $lg) {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    row-gap: 15px;
    padding: 10px;
  }

  &__title {
    @include flex-center();
    justify-content: flex-start;
    flex-grow: 1;
    margin-right: 20px;
    font-size: $fsz-16;
    overflow: hidden;
    @media (max-width: $lg) {
      grid-column: 1/-2;
      font-size: $fsz-14;
    }
  }

  &__icon {
    margin-right: 20px;
    width: 30px;
    height: 30px;
    @media (max-width: $lg) {
      margin-right: 10px;
      width: 24px;
      height: 24px;
    }
  }

  &__name {
    @include text-overflow($max-width: 100%);
  }

  &__info {
    display: flex;
    margin-left: 20px;
    @media (max-width: $lg) {
      grid-column: 1/-1;
      margin-left: 0;
      font-size: $fsz-12;
    }
  }

  &__status {
    @media (max-width: $lg) {
      justify-self: end;
    }
  }

  &__tag {
    min-width: 90px;
    @media (max-width: $lg) {
      min-width: 80px;
    }
  }

  &__areas {
    @include flex-center();
    text-align: center;
    width: 210px;
    padding: 0 20px;
    border-left: $border-dark-blue;
    border-right: $border-dark-blue;
    @media (max-width: $lg) {
      width: 50%;
      padding: 0 10px;
      border-left: 0;
    }
  }

  &__count {
    text-align: center;
    @media (min-width: $lg + 1) {
      @include flex-column();
      justify-content: center;
      width: 120px;
      padding-left: 16px;
    }
    @media (max-width: $lg) {
      @include flex-center();
      padding: 0 10px;
      width: 50%;
    }
  }

  &__count-label {
    @media (max-width: $lg) {
      margin-right: 5px;
    }
  }

  &__count-value {
    font-weight: $font-weight-bold;
  }
}
