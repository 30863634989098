@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

@mixin move-search-top() {
  display: grid;
  grid-template-columns: 1fr 30px;
  row-gap: 15px;

  .keywordFiltersSection__search {
    grid-template-columns: 1/2;
    margin: 0 10px;
    padding: 0 5px;
    border-radius: 8px;
    border: $border-dark-blue-alpha;
  }

  .keywordFiltersSection__filters {
    grid-column: 1/2;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  }
}

.keywordFiltersSection {
  align-items: center;
  padding: 9px 10px;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr 30px;
  background-color: $color-gray;

  &.isSectionItself {
    margin-top: 0;
  }

  &--offset-bottom {
    margin-bottom: 15px;
  }
  &--search {
    grid-template-columns: 210px auto 30px;
    padding-left: 10px;
  }
  &--no-filters {
    grid-template-columns: 270px 30px;
  }
  &--full-width {
    .keywordFiltersSection {
      &__filters {
        grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
      }
    }
  }
  &.keywordFiltersSection--search.keywordFiltersSection--full-width {
    display: flex;
    .keywordFiltersSection {
      &__search {
        flex-grow: 1;
      }
      &__filters {
        grid-template-columns: repeat(var(--filters-count), 220px);
      }
    }

    &:not(.keywordFiltersSection--disable-move-search) {
      @media (min-width: $lg + 1) and (max-width: 1500px) {
        @include move-search-top();
      }
      &.expanded-sidebar {
        @include move-search-top();
      }
    }
  }

  &__search {
    .input-field {
      background-color: transparent;
      border: 0;
      min-height: 30px;
      padding: 0 0 0 10px;

      &:hover,
      &:focus,
      &:active {
        border: 0;
      }

      &::placeholder {
        font-style: italic;
      }
    }
  }

  &__filters {
    display: grid;
    grid-template-columns: repeat(var(--filters-count, 3), 210px);
    row-gap: 10px;
  }

  &__clear {
    @media (min-width: $lg + 1) {
      align-self: start;
    }
    @media (max-width: $lg) {
      display: none;
      margin-top: 10px;
      &--visible {
        display: block;
      }
    }
  }

  &__clear-btn {
    @media (max-width: $lg) {
      width: auto;
      border: $border-dark-gray;
    }
  }

  &__clear-btn.ant-btn-icon-only {
    width: 30px;
    height: 30px;
    border: none;
    outline: none;
    background: none;
    transition: none;
    @media (min-width: $lg + 1) {
      transform: translateY(1px);
    }

    & > svg {
      font-size: $fsz-16;
    }
  }

  .selectableDropdown {
    padding: 0 10px;

    @media (max-width: $lg) {
      padding: 0;
    }
  }

  .dropdownButton {
    background-color: $color-white;
    border-radius: 10px;
  }

  @media (max-width: $lg) {
    @include flex-column();
    width: 100%;
    padding: 10px;

    &__search {
      width: 100%;
      margin-bottom: 10px;
      border-radius: 8px;
      border: $border-dark-blue-alpha;
    }

    &__filters {
      @include flex-column();
      width: 100%;
      row-gap: 0;
    }
    .selectableDropdown:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}
