.priceChangeEventTh {
  &--number {
    width: 10%;
    min-width: 70px;
  }
  &--costPrice,
  &--sellPrice {
    width: 20%;
    min-width: 130px;
  }
  &--effectiveStartDate,
  &--effectiveEndDate {
    width: 25%;
    min-width: 170px;
  }
}
