.fitmentTimeReportTh {
  &--number {
    min-width: 60px;
  }
  &--jobNumber {
    width: 15%;
    min-width: 155px;
  }
  &--jobCategory {
    width: 13%;
    min-width: 135px;
  }
  &--jobType {
    width: 8%;
    min-width: 125px;
  }
  &--jobStatus {
    width: 8%;
    min-width: 135px;
  }
  &--assignee {
    width: 8%;
    min-width: 145px;
  }
  &--spentByAssigneeMinutes {
    width: 10%;
    min-width: 230px;
  }
  &--totalSpentMinutes {
    width: 10%;
    min-width: 170px;
  }
  &--totalEstimatedMinutes {
    width: 8%;
    min-width: 210px;
  }
  &--differenceMinutes {
    width: 10%;
    min-width: 170px;
  }
  &--creationDate {
    width: 10%;
    min-width: 170px;
  }
}
