@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.affectedModal {
  @include flex-column();

  &__description {
    @include flex-column();
    margin-bottom: 10px;
    font-size: $fsz-16;

    @media (max-width: $lg) {
      font-size: $fsz-14;
    }
  }

  &__text,
  &__acceptance {
    margin-bottom: 15px;
    font-size: $fsz-16;

    @media (max-width: $lg) {
      font-size: $fsz-14;
    }
  }
}
