@import "src/core/styles/mixins";
@import "src/core/styles/variables";

.errorPage {
  width: 100%;
  min-height: 100vh;
  @include flex-center();
  flex-direction: column;
  &__notFound {
    color: $main-dark-blue;
    font-weight: $font-weight-bold;
    font-size: $fsz-32;
  }
  &__words {
    @include flex-column();
    align-items: center;
    color: $main-dark-blue;
    font-weight: $font-weight-bold;
    font-size: $fsz-18;
    margin-bottom: 20px;
    &-firstLine {

    }
    &-secLine {

    }
  }

  &__linkWrap {
    color: $main-dark-blue;
    font-size: $fsz-18;
    &-link {
      color: $main-dark-blue;
      text-decoration: underline;
      &:hover {
        color: $main-dark-blue;
      }
    }
  }
}