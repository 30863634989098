@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.tableControl {
  padding-top: 0;
  &--not-in-modal {
    z-index: 1;
  };

  .ant-checkbox-group {
    @include flex-column();
  }

  .checkbox-label {
    font-weight: $font-weight-bold;
  }

  .ant-checkbox-group-item {
    width: 180px;
    @include table-checkbox(sm)
  }

  .ant-checkbox-inner {
    width: 18px;
    height: 18px;
  }

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
    margin-top: 6px;
  }

  .ant-input-number-input {
    height: 40px;
    font-size: $fsz-12 !important;
    border: $border-dark-gray;
    border-radius: 10px !important;
    background-color: $color-white;
    &:hover {
      border: $border-dark-blue;
    }

    &:focus, &:focus-visible {
      border: $border-dark-blue;
      background-color: $color-gray;
      outline: 0;
    }
  }

  .ant-input-number {
    background-color: transparent;
    border-radius: 0;
    border: 0;
    box-shadow: none !important;
  }

  .ant-input-number-handler:active {
    &:first-of-type {
      border-top-right-radius: 10px;
    }
    &:last-of-type {
      border-bottom-right-radius: 10px;
    }
  }

  .ant-input-number-handler-wrap {
    border-radius: 0 10px 10px 0;
    top: 1px;
    bottom: 1px;
    right: 1px;
    height: auto;
  }

  .table-searchInput-field {
    width: 155px;
    .input-field {
      min-height: 40px;
      padding: 4px 10px;
      font-size: $fsz-12;
    }
  }

  .regularInputNumber__numFieldWrap-field {
    @include form-input($minheight: 40px);
    width: 130px;
    padding: 4px 10px;
    font-size: $fsz-12 !important;

    &:focus-visible {
      background-color: $color-white;
    }
  }
}

.thContent {
  @include flex-center();
  justify-content: flex-start;
  white-space: nowrap;
  font-size: $fsz-16;

  .table__button {
    width: fit-content;
    height: 32px;
    margin-left: 4px;
    padding: 0 4px;
    border: none;
    color: $main-dark-blue;

    & > svg {
      font-size: $fsz-16;
    }

    &:focus-visible {
      @include icon-focus();
      outline-offset: 0;
    }

    &:focus, {
      color: $dark-gray;
    }

    &--disabled {
      color: $main-gray;
      @include hover-focus-active() {
        color: $main-gray;
      }
    }

    &--active {
      &,
      &:hover,
      &:focus {
        &.ant-btn-icon-only {
          color: $main-yellow;
        }
      }
    }

    &--order-desc {
      transform: scaleY(-1);
    }
  }
}

.table-input {
  min-width: 80px;
  max-width: 540px;
  [class*=ant-input] {
    height: 22px !important;
  }
}

.table-inputNumber {
  max-width: 80px;
  [class*=ant-input-number-input] {
    height: 22px !important;
  }
}

.errorTableInput {
  .ant-input {
    border-color: $color-red;
    &:focus, &:hover {
      border-color: $color-red;
    }
  }
}

.errorTableInputNum {
  .ant-input-number {
    border-color: $color-red;
    &:focus, &:hover {
      border-color: $color-red;
    }
  }
}
