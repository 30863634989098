.nominalCodesTh {
  &--code {
    width: 20%;
    min-width: 110px;
  }
  &--description {
    width: 40%;
    min-width: 180px;
  }
  &--type {
    width: 20%;
    min-width: 100px;
  }
  &--createdDate {
    width: 20%;
    min-width: 170px;
  }
}

.nominalCodeForm {
  display: grid;
  gap: 15px;
}
