@import 'src/core/styles/mixins.scss';
@import 'src/core/styles/variables.scss';

.ant-modal {
    @media (max-width: $lg) {
      max-width: calc(100vw - 32px) !important;
    }

    &-content {
      border-radius: 10px !important;
    }

    &-title {
      font-size: $fsz-20 !important;
      font-weight: $font-weight-bold !important;
      @media (max-width: $lg) {
        font-size: $fsz-16 !important;
      }
    }

    &-header {
      padding: 24px 56px 24px 24px !important;
      border-radius: 10px 10px 0 0 !important;
      border-bottom: 2px solid $main-dark-blue !important;
      @media (max-width: $lg) {
        padding: 16px 56px 16px 16px !important;
      }
    }

    &-close {
      z-index: auto;
      @media (min-width: $lg + 1) {
        transform: translateY(7px);
      }
    }

    &-body {
      @media (max-width: $lg) {
        padding: 16px !important;
        padding-top: 10px !important;
      }

      .confirmationText {
        @include flex-column();
        font-family: $montserrat-font;
        font-size: $fsz-16;
        color: $main-dark-blue;
        margin-bottom: 15px;
        text-align: left;
        align-items: flex-start;

        @media (max-width: $lg) {
          font-size: $fsz-14;
        }

        &__proceed {
          margin-top: 15px;
        }
      }
    }
  }
