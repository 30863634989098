@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.stockCardsSection {
  padding: 20px 0 30px 0;
  border-top: $border-main-gray;

  &:last-of-type {
    border-bottom: $border-main-gray;
  }

  &__title {
    font-weight: $font-weight-bold;
    font-size: $fsz-20;
    line-height: 1;
    margin-bottom: 15px;
  }

  &__cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px 40px;

    @media (max-width: $sm) {
      grid-template-columns: 1fr 1fr;
      gap: 15px;
    }
  }
}

.stockCardCounter {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-weight: $font-weight-bold;
  border-radius: 10px;
  background-color: $color-white;
  color: $main-dark-blue;
  border: $border-dark-gray;
  transition: $transition;
  user-select: text;

  @media (max-width: $sm) {
    padding: 15px;
  }

  &--link {
    cursor: pointer;
    &:hover,
    &:focus {
      background-color: $main-dark-blue;
      color: $color-white;

      .stockCardCounter__text {
        color: $main-yellow;
      }
    }
  }

  &__value {
    margin-bottom: 10px;
    font-size: $fsz-32;
    @media (max-width: $sm) {
      font-size: $fsz-24;
      margin-bottom: 5px;
    }
  }

  &__text {
    transition: $transition;
  }
}
