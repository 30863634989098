.Chart {
  width: 100%;

  .apexcharts-toolbar {
    z-index: 1;
  }

  .apexcharts-menu {
    min-width: 120px;
  }
}
