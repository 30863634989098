@import "src/core/styles/mixins";
@import "src/core/styles/variables";

.quoteWrap {
  @include flex-column();
  height: 100%;

  &__tabs {
    width: 100%;

    &.tabsEmpty {
      [class*=ant-tabs-nav-wrap] {
        display: none !important;
      }
    }

    .ant-tabs-nav {
      margin-bottom: 28px !important;
      @media (max-width: $lg) {
        margin-bottom: 15px !important;
      }

      &::before {
        border: none !important;
      }

      .ant-tabs-nav-more {
        padding: 8px 12px !important;
        @media (max-width: $lg) {
          padding: 8px 10px!important;
        }
      }
    }

    .ant-tabs-tab {
      padding: 0;
    }

    .ant-tabs-ink-bar {
      background-color: $transparent;
    }

    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0 0 0 20px;
      @media (max-width: $lg) {
        margin: 0 0 0 10px;
      }
    }

    .ant-tabs-tab .ant-tabs-tab-btn {
      min-height: 50px;
      padding: 0 40px;
      padding-top: 12px;
      font-size: $fsz-16;
      font-weight: $font-weight-bold;
      border-radius: 10px;
      background-color: $color-white;
      border: $border-dark-gray;
      color: $main-dark-blue;
      @media (max-width: $lg) {
        padding-top: 8px;
        min-height: 40px;
        font-size: $fsz-14;
      }

      @include hover-focus-visible() {
        background-color: $main-dark-blue;
        color: $color-white;
      }
    }

    .ant-tabs-tab-active {
      border: none !important;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      background-color: $main-dark-blue;
      color: $main-yellow;
    }
  }

  .vehicleContent {
    @include flex-column();
    &-carousel {
      @include flex-between();
      border-radius: 10px;
      border: $border-dark-gray;
      padding: 10px 18px;
      padding-right: 12px;
      width: 100%;
      margin-bottom: 28px;
      @media (max-width: $lg) {
        padding: 10px;
        margin-bottom: 18px;
      }

      &__btn {
        width: 13px;
        height: 45px;
        border: 0;

        &:focus-visible {
          color: $main-dark-blue;
          @include icon-focus();
        }

        & > svg {
          font-size: $fsz-18;
          stroke: $dark-gray;
          stroke-width: 20px;
        }
      }

      &__inner {
        @include scroll-hidden();
        @include flex-start();
        overflow-x: auto;
        scroll-behavior: smooth;
        white-space: nowrap;
        max-width: 92%;
        width: 100%;

        &-field {
          @include flex-column();
          font-size: $fsz-14;

          &:not(:last-child) {
            margin-right: 120px;

            @media (max-width: $lg) {
              margin-right: 22px;
            }
          }

          @media (max-width: $lg) {
            font-size: $fsz-12;
          }

          & span:nth-child(2) {
            font-size: $fsz-16;
            font-weight: $font-weight-bold !important;
            @media (max-width: $lg) {
              font-size: $fsz-14;
            }
          }
        }

        @media screen and (max-width: 1280px) {
          max-width: 85%;
        }
        @media screen and (max-width: 900px) {
          max-width: 76%;
        }
      }

      &__dropdown {
        position: relative;
        border: none !important;
        outline: none !important;
        background: none !important;
        margin-left: 6px;
        padding-left: 16px !important;
        @media (max-width: $lg) {
          padding: 0;
          padding-left: 10px !important;
        }

        &:hover .svg-inline--fa {
          color: $main-dark-blue;
        }

        &:focus-visible .svg-inline--fa {
          color: $main-dark-blue;
          @include icon-focus();
        }

        &::before {
          content: '';
          position: absolute;
          left: 0;
          width: 1px;
          height: 50px;
          background-color: rgba($main-dark-blue, 0.5);
          transform: translateY(-13px);
        }

        .svg-inline--fa {
          font-size: $fsz-18;
          color: $dark-gray;
          transition: $transition;
          transform: rotate(90deg) translateX(1px);
        }

        &.vehicleActionsDisabled {
          cursor: not-allowed !important;
          svg {
            color: $main-gray !important;
          }
        }
      }
    }
  }
}

.ant-tabs-dropdown.ant-tabs-dropdown-placement-bottomLeft {
  .ant-tabs-dropdown-menu.ant-tabs-dropdown-menu-root.ant-tabs-dropdown-menu-vertical {
    @include scroll;
  }
}
