@import "../../styles/mixins";
@import "../../styles/variables";

ol, li {
  list-style: none;
  margin: 0;
  padding: 0;
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
}

.carousel {
  margin: 20px 0;
  position: relative;
  perspective: 100px;
  width: 100%;
  min-height: 50px;

  &__viewport {
    position: relative;
    height: 100%;
    min-height: 50px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;

    .carouselSlide {
      @include flex-center();
      position: relative;
      flex: 0 0 100%;
      width: 100%;
      padding: 0 55px;
      color: $primary-dark-blue;
      font-size: $fsz-18;
      border-radius: 10px;
      border: $border-accent-gray;
      background-color: $color-white;
      @media (max-width: $lg) {
        padding: 0 38px;
        font-size: $fsz-14;
      }

      &-text {
        display: none;
        &.activeContent {
          margin: 0 auto;
          animation: opacity .7s linear, 1;
          visibility: visible;
          display: block;
        }
      }

      .carouselSnapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        scroll-snap-align: center;
      }
    }
  }
}

.carousel:hover .carouselSnapper,
.carousel:focus-within .carouselSnapper {
  animation-name: none;
}

.carousel::before,
.carousel::after,
.carousel__prev,
.carousel__next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 0;
  outline: 0;
}

.carousel::before,
.carousel__prev {
  left: 2rem;
  @media (max-width: $lg) {
    left: 0.8rem;
  }
}

.carousel::after,
.carousel__next {
  right: 2rem;
  @media (max-width: $lg) {
    right: 0.8rem;
  }
}

.carousel::before,
.carousel::after {
  content: '';
  z-index: 1;
  color: #fff;
  font-size: 2.5rem;
  line-height: 4rem;
  text-align: center;
  pointer-events: none;
}

.carousel::before {
  clip-path: polygon(50% 0, 13% 50%, 50% 100%, 34% 100%, 0% 50%, 34% 0%);
  height: 15px;
  width: 20px;
  background-color: $main-dark-blue;
}

.carousel::after {
  clip-path: polygon(50% 0, 95% 50%, 50% 100%, 34% 100%, 80% 50%, 34% 0%);
  height: 15px;
  width: 18px;
  background-color: $main-dark-blue;

}
