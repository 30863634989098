@import '../../../../core/styles/variables';
@import '../../../../core/styles/mixins';

.entitiesForExport {
  &__buttons {
    margin-bottom: 20px;
  }

  &__checkbox {
    @include table-checkbox();
    width: fit-content;

    @media (min-width: $lg) {
      .checkbox-label {
        font-size: $fsz-16;
      }
    }

    .ant-checkbox + span {
      transform: translateY(-4px);
    }

    & + & {
      margin-top: 20px;
      margin-left: 0;
    }
  }
}

.draftEntitiesContent {
  @include flex-column();
}

.specificEntitiesTh {
  &--number {
    min-width: 60px;
  }
  &--supplier {
    width: 25%;
    min-width: 110px;
  }
  &--purchaseOrderNumber {
    width: 25%;
    min-width: 175px;
  }
  &--entityNumber {
    width: 20%;
    min-width: 100px;
  }
  &--net {
    width: 15%;
    min-width: 90px;
  }
  &--gross {
    width: 15%;
    min-width: 90px;
  }
  &--status {
    min-width: 110px;
  }
}
