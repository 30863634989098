@import 'src/core/styles/variables.scss';

.layout {
  width: 100%;
  display: flex;
  flex-direction: column;

  .layoutMain {
    padding: 0 20px 20px 335px;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    @media (max-width: $lg) {
      padding: 0 10px 10px 10px;
    }
    &.layoutNotCollapsed {
      padding: 0 20px 20px 120px;
      @media (max-width: $lg) {
        padding: 0 10px 10px 10px;
      }
    }

    &.layoutWithoutParts {
      padding: 0;
    }
  }
  .scrollableMainArea {
    height: calc(var(--doc-height, 100vh) - $header-height-desktop);

    @media (max-width: $lg) {
      height: calc(var(--doc-height, 100vh) - $header-height-mobile);
    }
  }
  .layoutHomepage {
    flex: 1;
  }
}

