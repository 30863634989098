@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.tag {
  display: inline-block;
  text-align: center;
  width: fit-content;
  margin-right: 0;
  padding: 0 10px;
  border: 0;
  border-radius: 8px;
  font-size: $fsz-12;
  font-weight: $font-weight-bold;
  line-height: $fsz-26;
  text-transform: uppercase;
  background-color: $main-dark-blue;
  color: $main-yellow;

  &--light {
    background-color: $main-yellow;
    color: $main-dark-blue;
  }

  &--text-md {
    font-size: $fsz-14;
    @media (max-width: $lg) {
      font-size: $fsz-12;
    }
  }

  &--truncate {
    max-width: 100%;

    span {
      @include text-overflow($max-width: 100%);
      display: block;
    }
  }

  &--reversed {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    svg {
      margin-left: 3px;
    }
  }

  &--crossed {
    background-color: darken($main-light-gray, 25%);
    color: $color-white;
    text-decoration: line-through;
  }

  svg + span {
    display: inline-flex;
    flex: 1;
    overflow: hidden;
  }
}
