@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.reportFilters {
  &__head {
    @include flex-between();
    flex-wrap: wrap;
    margin: -4px 0;

    & > * {
      margin: 4px 0;
    }
  }

  &__report-select {
    width: 250px;
  }

  &__filters-wrapper {
    @include flex-start();
    align-items: flex-start;
    margin-top: 15px;
    padding: 15px 15px 15px 0;
    background-color: $color-gray;
    border-radius: 10px;

    @media (max-width: $lg) {
      flex-direction: column-reverse;
      padding-right: 0;
    }
  }

  &__filters {
    flex: 1;

    @media (max-width: $lg) {
      display: none;

      &--expanded {
        @media (max-width: $lg) {
          display: block;
        }
      }
    }

    .keywordFiltersSection__filters {
      grid-template-columns: repeat(auto-fit, minmax(275px, 1fr)) !important;
    }

    .keywordFiltersSection__clear-btn {
      display: block;
      margin: 0 auto;
    }

    &.keywordFiltersSection--search.keywordFiltersSection--full-width {
      display: grid;
      row-gap: 15px;

      @media (max-width: $lg) {
        display: none;

        &.reportFilters__filters--expanded {
          display: flex;
        }
      }

      .keywordFiltersSection__search {
        grid-row: 1/2;
        grid-column: 1/-2;
        margin: 0 10px;
        padding: 0 5px;
        border-radius: 8px;
        border: $border-dark-blue-alpha;
      }

      .keywordFiltersSection__filters {
        grid-row: 2/3;
        grid-column: 1/-2;
      }
    }
  }



  &__actions {
    margin-left: auto;
    @include flex-start();

    @media (max-width: $lg) {
      width: 100%;
      margin-left: 0;
      padding: 0 10px;
    }
  }

  &__expand-btn {
    display: none;
    @media (max-width: $lg) {
      display: block;
      margin-right: auto;

      &--active {
        @include button-badge();
      }
    }
  }

  &__action-button {
    padding-left: 15px;
    padding-right: 15px;

    &:not(&--single) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &__action-dropdown {
    border: $border-dark-gray;
    border-radius: 0 10px 10px 0;
    width: 50px;
    height: 50px;
    background-color: $color-white;

    &:disabled {
      .svg-inline--fa {
        color: $main-gray;
      }
    }

    @media (max-width: $lg) {
      width: 40px;
      height: 40px;
    }
  }
}
