@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.timeline-modal {
  &__actions {
    @include flex-between();
    margin-bottom: 20px;

    .pagination {
      width: auto;

      @media (max-width: $md) {
        margin-top: 5px;
        margin-right: auto;
      }
    }

    .ant-pagination-total-text {
      display: none;
    }

    @media (max-width: $md) {
      flex-direction: column-reverse;
      align-items: flex-end;
      margin-bottom: 10px;
    }
  }

  &__timeline-wrapper {
    position: relative;
    margin-bottom: 20px;
    padding: 25px 15px 0 15px;
    background-color: $color-gray;
    border-radius: 8px;

    .spinner {
      top: 0;
      left: 0;
    }

    .empty-content {
      margin-bottom: 20px;
    }
  }
}
