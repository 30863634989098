@import "src/core/styles/mixins";
@import "src/core/styles/variables";

.summaryOrderReq {
  background-color: $color-gray;
  border-radius: 10px;
  padding: 15px 20px 20px;
  @media (max-width: $lg) {
    padding: 14px 10px;
  }
  &-title {
    width: 100%;
    @include summaryTitle();
  }

  &-linesWrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @media (max-width: $lg) {
      display: flex;
      flex-direction: column;
    }

    .reqVehSum {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 20px;
      border-right: $table-line;
      padding-right: 30px;
      @media (max-width: $lg) {
        padding-right: 0;
      }
      @media (max-width: $md) {
        grid-template-columns: 100%;
      }

      &:first-child {
        @media (max-width: $lg) {
          padding-bottom: 24px;
          margin-bottom: 20px;
          border-right: none;
          border-bottom: $table-line;
        }
      }

      &:last-child {
        border-right: none;
        padding-right: 0;
        padding-left: 30px;
        @media (max-width: $lg) {
          padding-left: 0;
        }
      }

      .reqSumLine {
        font-size: $fsz-14;
        @include flex-between();
        .reqSumValue {
          white-space: nowrap;
          text-align: end;
        }

        &--bold {
          font-weight: $font-weight-bold;
        }
      }
    }
  }
}

.orderPartRequestTable {
  margin-bottom: 24px;
  @media (max-width: $lg) {
    margin-bottom: 15px;
  }
}

.orderPartRequestTh {
  &--ordinalNumber {
    min-width: 70px;
  }
  &--categoryId {
    width: 14%;
    min-width: 175px;
  }
  &--productNumber {
    width: 14%;
    min-width: 150px;
  }
  &--productName {
    width: 19%;
    min-width: 200px;
  }
  &--notes { //
    width: 10%;
    min-width: 160px;
  }
  &--quantity {
    width: 10%;
    min-width: 70px;
  }
  &--unitPrice {
    width: 7%;
  }
  &--priceSources {
    width: 7%;
  }
  &--discount { //
    width: 7%;
    min-width: 95px;
  }
  &--netPrice {
    width: 7%;
    min-width: 155px;
  }
  &--tax {
    width: 6%;
    min-width: 100px;
  }
  &--weight {
    width: 6%;
    min-width: 140px;
  }
  &--status {
    width: 10%;
    min-width: 135px;
  }
}

.orderPartReqVehicle {
  margin-bottom: 30px;
}
