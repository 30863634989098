@import 'src/core/styles/mixins';

.partViewPage {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;
  padding-top: 20px;

  @media (max-width: $lg) {
    grid-template-columns: 100%;
    padding-top: 15px;
    gap: 15px;
  }

  &__title {
    grid-column: 2/-2;
    margin-bottom: 0;
    font-size: $fsz-16;
    font-weight: $font-weight-bold;
    color: $main-dark-blue;
  }
  &__title-text {
    @include text-overflow($max-width: 100%);
    display: block;
  }
}

.partViewPageHead {
  height: auto;
}
