@import "../../../../../../../core/styles/mixins";
@import "../../../../../../../core/styles/variables";

.addPartToOrderBtns {
  margin-bottom: 20px;

  & > .customButton {
    margin-top: 2px;
    margin-bottom: 2px;
  }
}

.addPartToOrderFilters {
  margin-bottom: 20px;
  @media (max-width: $lg) {
    margin-bottom: 15px;
  }
}

.addPartToOrderSearch {
  @include placeholder-italic(false);
  margin-bottom: 20px;
}

.addPartToOrderPaging {
  margin-bottom: 20px;
}

.addPartToOrderTh {
  &--number {
    min-width: 75px;
  }
  &--partCode {
    width: 20%;
    min-width: 160px;
  }
  &--manufacturerCode {
    width: 30%;
    min-width: 240px;
  }
  &--name {
    width: 20%;
    min-width: 180px;
  }
  &--description {
    width: 30%;
    min-width: 180px;
  }
  &--action {
    min-width: 105px;
    width: 105px;
    max-width: 105px;
  }
}
