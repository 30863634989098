@import "src/core/styles/variables";

.buttonActions {
  display: flex;
  justify-content: center;

  &--offsetTop {
    margin-top: 24px;
    @media (max-width: $lg) {
      margin-top: 15px;
    }
  }

  &__cancel,
  &__create {
    min-width: 120px;
    @media (max-width: $md) {
      min-width: 100px;
    }
  }

  &__cancel {
    margin-right: 20px;

    &.ant-btn-loading {
      .ant-btn-loading-icon {
        display: none;
      }
    }
  }
}
