@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.specificEntitiesSummary {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 15px;
  margin: 20px 0;

  @media (max-width: $lg) {
    grid-template-columns: 100%;
  }

  &__col {
    background-color: $color-gray;
    border-radius: 10px;
    padding: 12px;
    @media (max-width: $lg) {
      padding: 14px 10px;
    }
  }

  &__title {
    @include summaryTitle();
    font-size: $fsz-16;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }

  &__content-line {
    display: grid;
    grid-template-columns: 110px repeat(2, minmax(0, 1fr));
    gap: 10px;
    margin: 0;

    @media (max-width: $sm) {
      grid-template-columns: 80px repeat(2, minmax(0, 1fr));
      gap: 8px;
    }

    & + & {
      margin-top: 8px;
    }
  }
}
