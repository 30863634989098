@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.sentWrap {
  @include flex-column();
  &__title {
    margin-bottom: 20px;
    font-size: $fsz-16;
    text-align: left;
  }
}

.rejectWrap {
  &__textarea {
    margin-bottom: 20px;
  }
}

.acceptWrap {
  &__text {
    @include flex-column();
    &-title {
      margin-bottom: 20px;
      &:first-child {
        margin-bottom: 0;
      }
    }
  }
  &__comment {
    margin: 20px 0;
  }
  &__subtext {
    margin: 20px 0;
  }
}

.boldText {
  font-weight: $font-weight-bold;
}