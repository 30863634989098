@import 'src/core/styles/variables';

.readyCollectedPartsListName {
  display: block;
  margin-bottom: 4px;
  font-size: $fsz-16;
}

.readyCollectedPartsListTh {
  &--ordinalNumber {
    min-width: 70px;
  }
  &--source {
    min-width: 100px;
  }
  &--quantity {
    min-width: 95px;
  }
  &--costPrice {
    min-width: 110px;
  }
  &--addedDate {
    min-width: 150px;
  }
  &--status {
    min-width: 110px;
  }
  &--purchaseOrderNo {
    min-width: 232px;
  }
  &--owner {
    min-width: 150px;
  }
  &--collectionDate {
    min-width: 185px;
  }
}
