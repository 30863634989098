@import "../../../core/styles/variables.scss";

.partskitsViewPageDetailsHead {
  .head__inner-info-item {
    &:nth-child(2) {
      @media (min-width: $lg + 1) {
        grid-column: 2/4
      }
    }

    &:nth-child(3) {
      grid-column: 4/5
    }

    @media (max-width: $lg) {
      &:nth-child(3) {
        grid-column: 1/-1
      }
    }
  }

  @media (max-width: $lg) {
    height: 180px;
  }
}
