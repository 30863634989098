@import '../../core/styles/mixins';
@import '../../core/styles/variables';

.columnsGroup {
  display: flex !important;
  flex-direction: column !important;

  .ant-checkbox-wrapper {
    @include table-checkbox();
    padding-bottom: 12px;
    margin-left: 0 !important;
    margin-bottom: 10px;
    border-bottom: $border-main-gray;
    @media (max-width: $lg) {
      padding-bottom: 8px;
      margin-bottom: 6px;
    }

    &:first-child {
      display: none;
    }

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
    }

    .ant-checkbox {
      transform: translateY(2px);
    }

    .checkbox-label {
      font-size: $fsz-16;
      padding-left: 14px;
      @media (max-width: $lg) {
        font-size: $fsz-14;
        padding-left: 12px;
      }
    }
  }
}

.settingsFilters {
  @include flex-column();
  flex-shrink: 0;
  margin-bottom: 20px;
  @media (max-width: $lg) {
    margin-bottom: 10px;
  }

  &__head {
    @include flex-between();
  }

  &__permission-modules {
    margin-left: 8px;
  }

  .head {
    &__button {
      @include btn-unstyled();
      height: 50px;
      width: 50px;
      margin-right: 15px;
      border-radius: 10px !important;
      border: $border-dark-gray;
      transition: $transition;
      box-shadow: none !important;
      @media (max-width: $lg) {
        height: 40px;
        width: 40px;
        margin-right: 5px;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &__button:focus {
      border: $border-dark-gray;
    }

    &__button:hover,
    &__button:focus-visible,
    &__button:active {
      border: $border-dark-blue;
      box-shadow: none;

      .head__right-icon {
        transition: $transition;
        color: $primary-dark-blue;
      }
    }

    &__icon {
      color: $accent-gray;
      height: 20px !important;
      margin-right: 24px;
      transition: .15s;

      &:last-child {
        margin-right: 0;
      }
    }

    &__icon {
      color: $dark-gray;
      height: 22px !important;
      transition: $transition;
      transform: translateY(8%);
      @media (max-width: $lg) {
        height: 18px !important;
      }
    }

    &__iconActive {
      height: 22px !important;
      color: $primary-dark-blue;
      transition: $transition;
      transform: translateY(8%);
      @media (max-width: $lg) {
        height: 18px !important;
      }
    }
  }

  &__footer {
    margin-top: 20px;
    @media (max-width: $lg) {
      margin-top: 15px;
    }

    &-right {
      display: flex;
      @media (max-width: $lg) {
        width: 100%;
        flex-direction: column;
      }

      .selectableDropdown:nth-last-child(2) {
        border-right: 1px solid rgba($main-dark-blue, 0.5) !important;
        @media (max-width: $lg) {
          border: none !important;
        }
      }

      .warningsList {
        right: 0;
        @media (max-width: $lg) {
          margin-bottom: 8px;
        }

        .dropdownList.dropdownListVisible {
          min-width: 270px
        }
      }
    }
  }
}

.usersListTh {
  &--name {
    width: 15%;
    min-width: 240px;
  }

  &--email {
    width: 15%;
    min-width: 240px;
  }

  &--businessArea {
    width: 10%;
    min-width: 160px;
  }

  &--jobType {
    width: 10%;
    min-width: 180px;
  }

  &--role {
    width: 10%;
    min-width: 110px;
  }
}
