@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.stockPartReqLine {
  @include line-link();
  display: grid;
  grid-template-columns: 160px auto auto 1fr 95px;
  @media (max-width: $lg) {
    grid-template-columns: 1fr auto;
    align-items: center;
  }
  @include expanded-sidebar(1500px) {
    grid-template-columns: auto 1fr 95px;
    row-gap: 10px;
  }
  @media (min-width: $lg + 1) and (max-width: 1300px) {
    grid-template-columns: 140px auto auto 1fr 75px;
    padding-right: 10px;
    padding-left: 10px;
  }

  &__title {
    display: flex;
    align-items: center;
    padding-right: 5px;
    font-weight: $font-weight-bold;
    font-size: $fsz-16;

    @media (max-width: 1300px) {
      font-size: $fsz-15;
    }

    @include expanded-sidebar(1500px) {
      grid-column: 1/2;
    }
  }

  &__title-value {
    @include text-overflow($max-width: 100%);
  }

  &__icon {
    margin-right: 15px;
    font-size: $fsz-24;
    @media (max-width: 1300px) {
      margin-right: 10px;
    }
  }

  &__info {
    display: grid;
    grid-template-columns: 190px 150px 170px 105px;
    @include expanded-sidebar(1500px) {
      padding-left: 0;
      border-left: 0;
    }
    @media (min-width: $lg + 1) {
      gap: 10px;
      align-items: center;
      padding: 0 15px;
      border-left: $border-dark-blue;
    }
    @media (max-width: 1300px) {
      grid-template-columns: 160px 115px 150px 115px;
      padding: 0 8px;
    }
    @media (max-width: $lg) {
      grid-template-columns: 100%;
      grid-column: 1/-1;
      gap: 6px 5px;
      margin-top: 3px;
      margin-bottom: 5px;
      padding-bottom: 5px;
      border-bottom: $border-dark-blue;
    }
  }

  &__info-item {
    @include flex-column();
    margin-bottom: 0;
  }

  &__info-value {
    @include text-overflow($max-width: 100%);
    font-weight: $font-weight-bold;
    font-size: $fsz-15;

    @media (max-width: 1300px) {
      font-size: $fsz-14;
    }
  }

  &__info-tooltip {
    @include tooltip-sm();
  }

  &__icons {
    @media (min-width: $lg + 1) {
      @include flex-start();
      padding-right: 5px;
      padding-left: 15px;
      border-left: $border-dark-blue;
    }

    @media (max-width: $lg) {
      @include flex-end();
      grid-column: 2/3;
      grid-row: 3/4;
    }

    @media (max-width: 1300px) {
      padding-left: 10px;
    }
  }

  &__round-icon {
    @include flex-center();
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: $main-dark-blue;
    color: $main-yellow;

    &:first-child {
      margin-right: 4px;
    }

    &:last-child {
      margin-left: 4px;
    }
  }

  &__status {
    @include flex-center();
    padding-right: 15px;
    padding-left: 5px;

    @include expanded-sidebar(1500px) {
      grid-column: -1/-3;
      grid-row: 1/2;
      padding: 0;
    }

    @media (max-width: $lg) {
      @include flex-end();
      padding: 0;
      grid-column: 2/3;
      grid-row: 1/2;
    }

    @media (max-width: 1300px) {
      padding-right: 10px;
    }
  }

  &__modified {
    font-size: $fsz-20;
    position: relative;

    &::after {
      display: block;
      counter-reset: count var(--modified-count);
      content: counter(count);
      position: absolute;
      top: -8px;
      right: -11px;
      padding: 2px;
      background-color: #FFCC11;
      color: #3A4557;
      font-size: 10px;
      border-radius: 8px;
      min-width: 16px;
      font-weight: $font-weight-bold;
      text-align: center;
    }
  }

  &__tag {
    @include text-overflow($max-width: 140px);
    width: fit-content;
    margin-left: auto;
    @media (max-width: $xl) {
      font-size: $fsz-12;
    }
  }

  &__count {
    @media (min-width: $lg + 1) {
      @include flex-column();
      padding-left: 15px;
      border-left: $border-dark-blue;
      text-align: center;
    }

    @media (min-width: $lg + 1) and (max-width: 1300px) {
      padding-left: 5px;
    }
  }

  &__count-label {
    @media (max-width: $lg) {
      &::after {
        content: ": "
      }
    }
  }

  &__count-value {
    font-weight: $font-weight-bold;
  }
}

.viewDetails {
  &__content {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 5px 10px;
    padding-left: 0;

    @media (max-width: $md) {
      grid-template-columns: 100%;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
  }

  &__label {
    color: $accent-gray;
  }

  &__value {
    font-weight: $font-weight-bold;
  }
}
