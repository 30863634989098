@import 'src/core/styles/variables';

.custom-popover {
  border-radius: 5px;
  box-shadow: 0 3px 9px 1px rgb(0 0 0 / 11%);
  border: $border-main-gray;

  &--no-arrow {
    &.ant-popover-placement-topLeft,
    &.ant-popover-placement-topRight,
    &.ant-popover-placement-bottomLeft,
    &.ant-popover-placement-bottomRight {
      padding: 0;
    }

    .ant-popover-arrow {
      display: none;
    }
  }

  .ant-popover-inner {
    border-radius: 5px;
    background-color: $light-blue;
  }
}
