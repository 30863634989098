@import 'src/core/styles/variables';

.salesViewPageDetailsHead {
  @media (max-width: $lg) {
    height: 180px !important;
  }

  .head__inner {
    @media (max-width: $md) {
      column-gap: 10px;
    }
  }

  .head__inner-info-item {
    &:nth-child(4) {
      grid-column: 4/5;
    }
    &:nth-child(5) {
      grid-column: 5/6;
    }
    &:nth-child(6) {
      grid-column: 6/7;
    }

    @media (max-width: $lg) {
      &:nth-child(2) {
        grid-column: 2/6;
      }
      &:nth-child(3) {
        grid-column: 1/3;
      }
      &:nth-child(4) {
        grid-column: 3/7;
      }
      &:nth-child(5) {
        grid-column: 1/3;
      }
      &:nth-child(6) {
        grid-column: 3/5;
      }
      &:nth-child(7) {
        grid-column: 5/7;
      }
    }

    @media (max-width: $md) {
      &:nth-child(3) {
        grid-column: 1/4;
      }
      &:nth-child(4) {
        grid-column: 4/7;
      }
    }
  }
}
