@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.vehicleViewPageHead {
  @media (max-width: $lg) {
    height: 180px;
  }

  .head__inner-info-item {
    &:nth-child(2) {
      @media (min-width: $lg + 1) {
        grid-column: 2/4
      }
    }

    &:nth-child(3) {
      @media (min-width: $lg + 1) {
        grid-column: 4/5
      }
      @media (max-width: $lg) {
        grid-column: 1/3
      }
    }

    &:nth-child(4) {
      @media (max-width: $lg) {
        grid-column: 4/7;
      }
    }

    &:nth-child(5) {
      @media (max-width: $lg) {
        grid-column: 1/4;
      }
    }

    &:nth-child(6) {
      @media (max-width: $lg) {
        grid-column: 4/7;
      }
    }
  }
}

.vehicleFilters {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;
  @media (max-width: $xl) {
    grid-template-columns: 100%;
  }

  &__segmented {
    justify-self: start;
  }

  &__actions {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 15px 20px;
    @media (max-width: $lg) {
      grid-template-columns: 100%;
      margin-bottom: 15px;
    }
  }

  &__select-highlighted {
    @include highlighted-select-value();
  }
}

.checksListModal {
  @include modal-choice-buttons();
}
