@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.ant-drawer-header {
  padding: 18px 20px;
  border-bottom: 2px solid $main-dark-blue;
}
.ant-drawer-body {
  overflow: hidden;
  @include flex-column();
  padding-top: 0;
  padding-bottom: 0;

  @media (max-width: $lg) {
    padding: 0 10px;
  }

  .emptyNotifications {
    justify-content: center;
    padding-top: 0;
  }

}
.ant-drawer-title {
  font-size: $fsz-18;
  font-weight: $font-weight-bold;
}

.drawer-list-wrapper {
  @include scroll();
  overflow: auto;
  padding-bottom: 15px;
}

.drawer-list {
  @include list-unstyled();

  &__item {
    & + & {
      margin-top: 16px;
    }
  }
}

.messageWrap {
  position: relative;
  border: $border-dark-blue-alpha;
  border-radius: 10px;
  padding: 16px 32px 16px 16px;
  display: flex;
  align-items: center;

  &__info {
    @include flex-column();
  }

  &__text {
    color: $main-dark-blue;
    font-size: $fsz-16;

    a {
      color: $main-dark-blue;
      text-decoration: underline;
      @include hover-focus-active() {
        text-decoration: none;
      }
    }

    .not-link {
      display: inline;
    }
  }

  &__prices-list {
    @include list-unstyled();
    color: $main-dark-blue;
    font-size: $fsz-16;
  }

  &__timestamp {
    color: $accent-gray;
  }

  &__action {
    position: absolute;
    top: 16px;
    right: 2px;
    width: 30px;
    height: 30px;
    border: none;
    outline: none;
    background: none;
    transition: none;

    &.dropdownTriggerBtn svg {
      transform: none;
    }
  }

  &__check.ant-btn-icon-only {
    width: fit-content;
    height: auto;
    margin-right: 15px;

    @include hover-focus-active() {
      background-color: transparent;
    }

    @media (max-width: $lg) {
      & > svg {
        font-size: $fsz-24;
      }
    }
  }

  &__check--read.ant-btn-icon-only:not(:disabled) {
    color: $color-green;
    @include hover-focus-active() {
      color: $color-green;
    }
  }
}

.drawerActions {
  @include flex-end();
  padding: 10px 0;
}
