@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.employeesSkeletonItem {
    display: grid;
    grid-template-columns: 380px repeat(2, minmax(0, 1fr)) 200px;

    @include expanded-sidebar() {
        grid-template-columns: repeat(3,minmax(0,1fr)) 200px
    }

    @media (max-width: $lg) {
        grid-template-columns: 100%;
    }

    @include skeleton-image-and-text();

    &__info {
        padding-right: 20px;

        @media (max-width: $lg) {
            margin-bottom: 16px;
        }
    }

    &__contact {
        @media (min-width: $lg + 1) {
            padding: 0 20px;
            display: flex;
            align-items: center;
        }

        @media (max-width: $lg) {
            margin-bottom: 11px;

            &:nth-child(2) {
                &::before {
                    width: 130px;
                }
            }

            &:nth-child(3) {
                &::before {
                    width: 90px;
                }
            }
        }

        &::before {
            content: "";
            display: block;
            height: 16px;
            background-color: $skeleton-color;
            width: 110px;
        }
    }

    &__department {
        @media (min-width: $lg + 1) {
            padding-left: 20px;
            border-left: $skeleton-border;
            @include flex-center();
        }

        &::before {
            content: "";
            display: block;
            height: 16px;
            background-color: $skeleton-color;
            width: 110px;
        }
    }
}
