.qualityCheckPartsTh {
  &--ordinalNumber {
    min-width: 70px;
  }
  &--category {
    width: 33.333%;
    min-width: 120px;
  }
  &--productNumber {
    width: 33.333%;
    min-width: 160px;
  }
  &--productName {
    width: 33.333%;
    min-width: 160px;
  }
  &--quantity {
    min-width: 90px;
  }
  &--checkResult {
    min-width: 360px;
  }
}
