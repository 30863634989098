@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.jobTracking {
  background-color: $color-gray;
  padding: 20px 30px;
  border-radius: 10px;
  @media (max-width: $lg) {
    padding: 10px;
  }
  &__estimateField {
    margin-bottom: 20px;
    max-width: 180px;
  }
}

.jobTimeTrackingTable {
  width: 100%;
  max-width: 1000px;

  &__assignee {
    font-weight: $font-weight-bold;
  }

  &__deleted-assignee {
    color: $accent-gray;
  }

  @media (max-width: $md) {
    font-size: $fsz-14;
  }

  .table-footer {
    font-weight: $font-weight-bold;
  }
}

.jobTrackingTh {
  &--jobAssignee {
    width: 34%;
    min-width: 150px;
  }
  &--jobSpentMinutes {
    width: 22%;
    min-width: 90px;
  }
  &--estimated {
    width: 22%;
    min-width: 105px;
  }
  &--remaining {
    width: 22%;
    min-width: 120px;
  }
}
