@import 'src/core/styles/mixins.scss';
@import 'src/core/styles/variables.scss';

.statuses {
  @include flex-column();
  margin-bottom: 24px;
  @media (max-width: $lg) {
    margin-bottom: 12px;
    min-height: 350px;
  }

  &-item {
    @include flex-between();
    padding: 12px;
    font-size: $fsz-16;
    border-radius: 6px;
    color: $main-dark-blue;
    border-bottom: $border-main-gray;
    background-color: $color-white;
    outline: none;
    @media (max-width: $lg) {
      font-size: $fsz-14;
      padding: 8px;
    }

    &:focus-visible {
      font-weight: $font-weight-bold;
    }

    &:last-child {
      border-bottom: none;
    }

    &__left {
      @include flex-start();
      width: 100%;
      margin-right: 10px;
      .listIcon {
        margin-right: 28px;
        @media (max-width: $lg) {
          margin-right: 18px;
        }
      }
    }

    &__value {
      width: 100%;
    }

    &__switch {
      flex-shrink: 0;
    }
  }
}
