.supplierSpendTh {
  &--number {
    min-width: 65px;
  }
  &--supplier {
    width: 20%;
    min-width: 140px;
  }
  &--purchaseOrderNumber {
    width: 14%;
    min-width: 232px;
  }
  &--category {
    width: 15%;
    min-width: 130px;
  }
  &--subcategory {
    width: 15%;
    min-width: 145px;
  }
  &--costNet {
    width: 12%;
    min-width: 150px;
  }
  &--costGross {
    width: 12%;
    min-width: 165px;
  }
  &--purchaseOrderDate {
    width: 12%;
    min-width: 218px;
  }
}
