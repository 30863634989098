@import 'src/core/styles/mixins.scss';
@import 'src/core/styles/variables.scss';

.sidebarWrapper {
  position: fixed;
  width: 100%;
  max-width: $sidebar-wide;
  z-index: 45;
  @media (max-width: $lg) {
    transform: translateX(0);
    max-width: $sidebar-wide-mobile;
  }

  &.sidebarWrapperCollapsed {
    max-width: $sidebar-collapsed !important;
    @media (max-width: $lg) {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      transform: translateX(-150%);
      background-color: rgba($main-dark-blue, .9);
    }
  }

  .ant-menu.ant-menu-dark {
    @include scroll();
    @include flex-column();
    overflow: auto;
    background-color: $accent-blue !important;
    @media (max-width: $lg) {
      background-color: rgba($main-dark-blue, .9) !important;
    }
  }
}

.sidebar {
  width: 100% !important;
  max-width: $sidebar-wide !important;
  position: relative !important;
  height: var(--doc-height, 100vh);
  top: 0;

  &__head {
    position: sticky;
    top: 0;
    margin-bottom: 20px;
    z-index: 1;
    @media (max-width: $lg) {
      margin-bottom: 12px;
    }
  }

  &__head-link {
    display: block;
    height: 80px;
    background-color: $main-dark-blue;
    text-align: center;
    overflow: hidden;
    .sidebarWrapperCollapsed & {
      padding-top: 12px;
    }
    @media (max-width: $lg) {
      height: 70px;
    }
  }

  .ant-menu-item {
    outline: 0 !important;
    width: calc(100% - 36px) !important;
    padding: 0 !important;
    margin-top: 0;
    margin-left: 18px !important;
    margin-right: 18px !important;
    min-height: 50px;
    border: 1px solid transparent;

    &:nth-last-child(2):not(.settingsLink) {
      margin-bottom: auto;
    }

    @media (max-width: $lg) {
      min-height: 40px;
      margin-bottom: 2px !important;
    }

    .ant-menu-title-content {
      display: flex;
      height: 48px;
      align-items: center;
      padding-left: 14px;
      border: 1px solid transparent;
      border-radius: 10px !important;
      color: $main-yellow;
      transition: border-color 0.15s;

      @include hover-focus-visible() {
        border: $border-main-yellow !important;
      }

      .sidebarWrapperCollapsed & {
        justify-content: center;
        padding-left: 0;
      }

      @media (max-width: $lg) {
        height: 35px;
        padding-left: 8px;
      }

      .svgWrap {
        @include flex-center();
        height: 20px;
        min-width: 28px;
      }
    }
  }

  .ant-menu-item-selected {
    background-color: transparent !important;
    .ant-menu-title-content {
      color: $main-dark-blue;
      background-color: $main-yellow;
      .svgWrap {
        svg {
          path {
            fill: $main-dark-blue;
          }
        }
      }
    }
  }

  .settingsLink {
    font-size: $fsz-18;

    @media (min-width: $lg + 1) {
      margin-top: auto;
    }

    @media (max-width: $lg) {
      font-size: $fsz-16;
    }

    .settingsTitle {
      margin-left: 20px;
      font-weight: $font-weight-bold;
    }
  }

  .linkName {
    margin-left: 20px;
    font-size: $fsz-18;
    font-weight: $font-weight-bold;
    @media (max-width: $lg) {
      font-size: $fsz-16;
    }
  }

  &__terms-link {
    margin-right: 26px;

    @media (max-width: $lg) {
      margin-right: 22px;
    }
  }

  &__buttonWrap {
    flex-shrink: 0;
    height: 64px;
    position: sticky;
    bottom: 0;
    @include flex-between();
    background-color: $main-dark-blue;
    padding: 0 18px;
    color: $main-yellow;
    @media (max-width: $lg) {
      height: 45px;
      margin-top: auto;
    }
    .sidebar__expand-btn {
      width: 40px;
      height: 40px;
      flex-shrink: 0;
      color: $main-yellow;
      background: none;
      border: 0;
    }
    .btn:focus-visible {
      outline: $border-main-yellow;
    }

    &.collapsed {
      padding: 0;
      justify-content: center;
      transform: rotate(180deg);
    }
  }
}

.ant-tooltip {
  &-inner {
    padding: 10px 19px !important;
    color: $main-yellow !important;
    font-size: 18px;
    line-height: 18px;
    border-radius: 0 !important;
    background-color: $main-dark-blue !important;
  }
}

.ant-tooltip.ant-tooltip-placement-right {
  @media (max-width: $lg) {
    display: none;
  }
}
