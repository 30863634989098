.partsListTh {
  &--ordinalNumber {
    min-width: 60px;
    width: 60px;
    max-width: 60px;
  }
  &--partNumber {
    width: 13%;
    min-width: 180px;
  }
  &--partName {
    width: 15%;
    min-width: 250px;
  }
  &--notes {
    width: 12%;
    min-width: 180px;
  }
  &--quantity {
    width: 7%;
    min-width: 110px;
  }
  &--costPrice {
    width: 7%;
    min-width: 135px;
  }
  &--net,
  &--gross {
    width: 7%;
    min-width: 110px;
  }
  &--totalReceivedQuantity {
    width: 8%;
    min-width: 130px;
  }
  &--totalReturnedNotReceivedQuantity {
    width: 8%;
    min-width: 135px;
  }
  &--dueIn {
    width: 7%;
    min-width: 105px;
  }
  &--state {
    width: 9%;
    min-width: 130px;
  }
}
