@import "src/core/styles/mixins";
@import "src/core/styles/variables";

.passwordInput {
  @include flex-column();

  &-label {
    @include field-label();
  }

  .ant-input {
    @include form-input();
    padding: 10px !important;
    padding-right: 30px !important;
    border: $border-dark-gray !important;
    &:hover {
      border: $border-dark-blue !important;
    }

    &:focus, &:focus-visible {
      border: $border-dark-blue !important;
    }
  }

  .ant-input-suffix {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 30px;

    .ant-input-password-icon {
      @include flex-center;
      position: absolute;
      inset: 0;
    }

    .anticon-eye path:nth-of-type(3),
    .anticon-eye path:nth-of-type(4) {
      fill: $primary-dark-blue;
    }
  }


  .passwordInput-field {
    padding: 0;
    border: 0;
    box-shadow: none;
  }
  &-error {
    @include error-message();
  }
}
