.quoteLinesGroupTh {
  &--number {
    min-width: 65px;
  }
  &--quoteCategory {
    width: 10%;
    min-width: 120px;
  }
  &--productNumber {
    width: 12%;
    min-width: 110px;
  }
  &--productName {
    width: 12%;
    min-width: 130px;
  }
  &--notes {
    width: 12%;
    min-width: 120px;
  }
  &--quantity {
    width: 7%;
    min-width: 65px;
  }
  &--unitPrice {
    width: 10%;
    min-width: 105px;
  }
  &--netPrice {
    width: 7%;
    min-width: 100px;
  }
  &--tax {
    width: 5%;
    min-width: 60px;
  }
  &--weight {
    width: 8%;
    min-width: 95px;
  }
}
