@import '~antd/dist/antd.css';
@import 'variables';
@import 'components';
@import 'common';

body,
html {
  font-family: $montserrat-font;
  font-style: normal;
  font-weight: $font-weight-regular;
  font-size: $fsz-16;
  color: $main-dark-blue;
  -webkit-font-smoothing: antialiased;
}

img {
  max-width: 100%;
}

.App {
  display: flex;
  min-height: var(--doc-height, 1vh);
}
