.vehicleChecksTh {
	&--vehicleCheckTypes {
		width: 25%;
		min-width: 200px;
	}
	&--mileage {
		width: 16.666%;
		min-width: 120px;
	}
	&--weight {
		width: 16.666%;
		min-width: 110px;
	}
	&--checkDecision {
		width: 25%;
		min-width: 220px;
	}
	&--checkDate {
		width: 16.666%;
		min-width: 170px;
	}
}
