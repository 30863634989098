@import "src/core/styles/mixins";
@import "src/core/styles/variables";

.addContact {
  @include modal-choice-buttons();
}

.existedList {
  @include flex-column();

  &__actions {
    margin-bottom: 20px;
  }

  &__actions-item {
    &:not(:last-of-type) {
      margin-right: 20px;
    }
  }

  &__scrollableDiv {
    @include scroll();
    max-height: 440px;
    overflow: auto;
    margin-bottom: 20px;
    @media (max-width: $lg) {
      max-height: 380px;
    }
  }
}
