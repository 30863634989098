.skillsUpdateEventTh {
  &--number {
    width: 70px;
    min-width: 70px;
  }
  &--categoryName,
  &--name {
    width: 40%;
    min-width: 200px;
  }
  &--level {
    width: 20%;
    min-width: 100px;
  }
}
