.stockSearchTh {
  &--number {
    min-width: 90px;
  }
  &--partCode {
    width: 16%;
    min-width: 160px;
  }
  &--manufacturerCode {
    width: 16%;
    min-width: 240px;
  }
  &--name {
    width: 18%;
    min-width: 180px;
  }
  &--categoryId {
    width: 13%;
    min-width: 130px;
  }
  &--subcategoryId {
    width: 13%;
    min-width: 160px;
  }
  &--businessAreas {
    width: 13%;
    min-width: 140px;
  }
  &--status {
    width: 11%;
    min-width: 115px;
  }
}
