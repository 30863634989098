@import "../../../core/styles/mixins.scss";
@import "../../../core/styles/variables.scss";

.editComment {
  margin-bottom: 32px;

  .input-label {
    margin-bottom: 6px;
  }

  .input-field {
    @include form-input();
  }
}