.salesReportByCustomerTh {
  &--number {
    min-width: 80px;
  }
  &--entityType {
    min-width: 145px;
  }
  &--entityNumber {
    width: 25%;
    min-width: 165px;
  }
  &--customer {
    width: 25%;
    min-width: 200px;
  }
  &--billingCustomer {
    width: 25%;
    min-width: 210px;
  }
  &--shippingCustomer {
    width: 25%;
    min-width: 210px;
  }
  &--costNet {
    min-width: 160px;
  }
  &--salesNet {
    min-width: 160px;
  }
  &--differenceNet {
    min-width: 150px;
  }
  &--completedDate {
    min-width: 185px;
  }
}
