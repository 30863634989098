@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.radio {
  .ant-radio-inner::after {
    background-color: $main-dark-blue;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: $main-dark-blue;
  }
  .ant-radio-checked::after {
    border-color: $main-dark-blue;
  }

  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: $main-dark-blue;
  }
  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0 0 0 3px rgba($main-dark-blue, 0.15);
  }
}
