@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.accountLeaveRequestItem {
  @include line-link();
  display: grid;
  grid-template-columns: auto 200px 1fr auto auto;

  @include expanded-sidebar() {
    grid-template-columns: auto 150px 1fr auto auto;
  }

  @media (max-width: $lg) {
    position: relative;
    grid-template-columns: 100%;
    gap: 10px;
  }

  &__icon {
    align-self: center;
    font-size: $fsz-36;
    margin-right: 20px;

    @media (max-width: $lg) {
      display: none;
    }
  }

  &__type {
    @include flex-center();
    justify-content: flex-start;
    align-self: center;
    font-weight: $font-weight-bold;

    @media (min-width: $lg + 1) {
      font-size: $fsz-16;
    }
  }

  &__info-icon {
    margin-left: 10px;

    @media (min-width: $lg + 1) {
      font-size: $fsz-16;
    }
  }

  &__tooltip {
    @include tooltip-sm();
    padding-bottom: 6px;

    .ant-tooltip-arrow {
      display: none;
    }
  }

  &__info {
    display: grid;
    grid-template-columns: 300px auto;
    gap: 20px;
    padding: 0 20px;
    border-left: $border-dark-blue;

    @include expanded-sidebar() {
      grid-template-columns: 240px auto;
      padding: 0 15px;
    }

    @media (max-width: $lg) {
      grid-column: 1/2;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 10px;
      padding: 0;
      border-left: 0;
    }

    @media (max-width: $sm) {
      grid-template-columns: 100%;
    }
  }

  &__info-item {
    @include flex-column();

    b {
      @media (min-width: $lg + 1) {
        font-size: $fsz-16;
      }
    }
  }

  &__tag-wrapper {
    align-self: center;
    padding: 0 20px;

    @include expanded-sidebar() {
      padding: 0 10px;
    }

    @media (max-width: $lg) {
      grid-row: 1/2;
      padding: 0;
    }
  }

  &__actions-wrapper {
    align-self: center;

    .fa-ellipsis-vertical {
      transform: translateX(0);
    }

    @media (max-width: $lg) {
      position: absolute;
      top: 12px;
      right: 10px;
    }
  }
}
