@import "src/core/styles/mixins";

.inputWithButton {
  position: relative;

  &--offset-bottom {
    margin-bottom: 15px;
  }

  &__input {
    .input-field {
      padding-right: 50px;
    }

    &--hidden-button {
      .input-field {
        padding-right: 10px;
      }
    }
  }

  &__button.ant-btn-icon-only {
    position: absolute;
    top: 0;
    right: 0;
    background-clip: content-box;
    border: 1px solid transparent;
    &:not(:disabled) {
      background-color: transparent;
    }

    &:disabled,
    &:not(:disabled) {
      @include hover-focus-active() {
        background-clip: content-box;
        border-color: transparent;
      }
    }


    & > svg {
      font-size: $fsz-18;
    }

    @media (max-width: $lg) {
      width: 50px;
      height: 50px;
    }
  }
}
