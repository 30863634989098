@import "src/core/styles/mixins";
@import "src/core/styles/variables";

.pickerWrap {
  @include flex-column();

  &__label {
    @include field-label();
  }

  &__error {
    @include error-message();
  }

  .pickerWrap__field {
    @include form-input();
    cursor: pointer;

    input {
      cursor: pointer;
      font-size: $fsz-16;
      @media (max-width: $lg) {
        font-size: $fsz-14;
      }
    }

    &.ant-picker-disabled {
      @include form-disabled();

      input {
        cursor: not-allowed;
      }
    }
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    color: $main-yellow;
    background-color: $main-dark-blue;
  }

  .ant-picker-suffix {
    color: $main-dark-blue;
  }
}

.ant-picker-content {
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    color: $main-yellow;
    background-color: $main-dark-blue;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: $main-dark-blue;
  }
}

.ant-picker-today-btn {
  color: $main-dark-blue;

  &:hover,
  &:focus {
    color: $main-dark-blue;
    text-decoration: underline;
  }
}

.ant-picker-header-view button,
.ant-picker-header-view button:hover,
.ant-picker-header-view button:focus {
  color: $main-dark-blue;
}

@media (max-width: $lg) {
  .pickerWrap__field {
    input[disabled] {
      color: $color-black;
      cursor: default;
      pointer-events: none;
    }
  }
  .ant-picker-range {
    .ant-picker-input-active {
      &::after {
        content: "";
        position: absolute;
        bottom: -4px;
        left: 0;
        right: 0;
        height: 1px;
        background-color: $color-black;
      }
    }

    input[disabled] {
      color: $color-black;
      cursor: default;
      pointer-events: none;
    }
  }
}
.ant-picker-focused {
  box-shadow: 0 0 0 2px rgba($main-dark-blue, 0.2);
}

.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: $main-dark-blue;
  color: $main-yellow;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background-color: rgba($main-dark-blue, 0.1);
}

.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before,
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
  background-color: rgba($main-dark-blue, 0.2);
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after {
  border-top-color: rgba($main-dark-blue, 0.3);
  border-bottom-color: rgba($main-dark-blue, 0.3);
}

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after{
  border-right-color: rgba($main-dark-blue, 0.3);
}

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  border-left-color: rgba($main-dark-blue, 0.3);
}

