@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.employeesWorkingHours {
  &__employees {
    .ant-select-selection-overflow-item {
      top: auto !important;
    }

    .ant-select-selector {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }

  &__period {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    column-gap: 14px;
    align-items: center;

    @media (max-width: $sm) {
      column-gap: 8px;
    }
  }

  &__period-label {
    @include field-label();
    grid-column: 1/-1;
  }

  &__checkbox {
    @include table-checkbox(sm);
  }

  &__list {
    @include scroll();
    @include list-unstyled();
    height: 172px;
    overflow: auto;
    border: $border-dark-blue;
    padding: 8px;
    border-radius: 10px;

    &--loading {
      position: relative;
      padding: 0;
    }

    &--disabled {
      @include form-disabled();
    }
  }

  .rmdp-container {
    width: 100%;

    input {
      @include form-input(48px);
    }
  }

  .rmdp-week-day {
    color: rgba($color-black, 0.85);

    @media (max-width: $sm) {
      font-size: $fsz-11;
    }
  }

  .rmdp-day span {
    font-size: $fsz-12;

    @media (max-width: $sm) {
      font-size: $fsz-10;
    }
  }

  .rmdp-ym .rmdp-day span,
  .rmdp-day span {
    border-radius: 0;
  }

  .rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: $main-dark-blue;
    color: $main-yellow;
    box-shadow: none;
  }

  .rmdp-day:not(.rmdp-disabled,.rmdp-day-hidden):not(.rmdp-selected) span:hover {
    background-color: #F5F5F5;
    color: $main-dark-blue;
  }

  .rmdp-day.rmdp-today span {
    background-color: $color-white;
    color: $main-dark-blue;
    box-shadow: 0 0 0 1px $main-dark-blue;
  }


  .rmdp-panel-body li {
    background-color: $main-dark-blue;

    & > div > button {
      color: $main-yellow;
    }

    & > button {
      background-color: $main-yellow;
      color: $main-dark-blue;
    }
  }

  .rmdp-arrow {
    width: 7px;
    height: 7px;
    margin-top: 6px;
    border-color: $main-dark-blue;
  }

  .rmdp-arrow-container:hover {
    background-color: $main-dark-blue;

    .rmdp-arrow {
      border-color: $main-yellow;
    }
  }

  .rmdp-panel-header + div {
    @include scroll();
  }
}

.selectEmployeeOptions {
  grid-column: 1/-1;
  margin: -2px 0;

  & > button {
    margin-top: 2px;
    margin-bottom: 2px;
  }
}

.hiddenOptionButton {
  display: none;
}


