@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.partViewPageForm {
  @include details-activity();
}

.categorySection {
  @include flex-column();

  &__label {
    @include field-label();
  }

  &__value {
    @include form-input();
    @include flex-start();

    &--disabled {
      @include form-disabled();
    }
  }

  &__error {
    @include error-message();
  }
}

.partMainInfo {
  display: grid;
  gap: 15px;
  margin-bottom: 15px;

  @media (min-width: $md + 1) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin-bottom: 20px;
  }

  &__img-wrapper {
    @include flex-center();
    position: relative;
    &:hover {
      .partMainInfo__change-btn,
      .partMainInfo__remove-btn {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &__remove-btn.ant-btn-icon-only {
    width: 32px;
    height: 32px;

    & > svg {
      font-size: $fsz-16;
    }
  }

  &__remove-btn,
  &__change-btn {
    position: absolute;
    bottom: 10px;
    opacity: 0;
    visibility: hidden;
  }

  &__remove-btn.ant-btn-icon-only {
    right: 10px;

    & > svg {
      font-size: $fsz-16;
    }
  }

  &__change-btn {
    position: absolute;
    right: 50px;
    transition: $transition;

    .ant-btn-icon-only {
      width: 32px;
      height: 32px;
      border-radius: 10px !important;

      & > svg {
        font-size: $fsz-16;
      }
    }
  }

  .customDragger {
    width: 100%;
    height: 100%;

    .ant-upload.ant-upload-drag {
      background-color: $color-gray;
      margin-bottom: 0;
    }
  }

  .ant-image {
    width: 100%;
    height: 100%;
    max-height: 258px;
  }

  .ant-image-mask {
    border-radius: 10px;
  }

  img {
    width: 100%;
    height: 100%;
    max-height: 258px;
    object-fit: contain;
    border-radius: 10px;
  }
}

.quantitySection {
  &__content {
    padding: 15px;
    border-radius: 10px;
    background-color: $color-gray;
    &:not(:last-of-type) {
      margin-bottom: 10px;

      @media (max-width: $md) {
        margin-bottom: 15px;
      }
    }
  }

  &__title {
    @include flex-between();
    flex-wrap: wrap;
    margin-bottom: 0;
    font-size: $fsz-18;
    font-weight: $font-weight-bold;
    color: $main-dark-blue;

    &--bordered {
      margin-bottom: 6px;
      padding-bottom: 6px;
      border-bottom: 1px solid #3A4557;
    }

    span {
      &:first-of-type {
        padding-right: 20px;
      }

      &:last-of-type {
        min-width: 80px;
      }
    }
  }

  &__list {
    @include list-unstyled();
    span {
      &:first-of-type {
        flex-grow: 1;
        text-align: right;
        padding-right: 20px;
      }

      &:last-of-type {
        min-width: 80px;
      }
    }
  }

  &__item {
    @include flex-start();
    svg {
      margin-right: 5px;
    }

    &:not(:last-of-type) {
      margin-bottom: 6px;
    }
  }
}
