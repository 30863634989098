@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.forgot-password {
  @include flex-center();
  width: 100%;
  position: relative;

  &__wrapper {
    @include flex-column();
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: 360px;
    max-width: 500px;
    margin: 40px 10px;
    padding: 32px 60px;
    border: $border-main-gray;
    border-radius: 10px;

    @media (max-width: $lg) {
      height: auto;
      padding: 20px 45px;
    }
  }

  &__back.ant-btn-icon-only {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border: 0;
    @media (max-width: $lg) {
      margin-right: 5px;
    }
    & > svg {
      font-size: $fsz-20;
    }
  }

  &__title {
    font-size: $fsz-18;
    font-weight: $font-weight-bold;
    margin-bottom: 0;
    @media (max-width: $lg) {
      font-size: $fsz-16;
    }
  }

  &__header {
    @include flex-center();
    justify-content: flex-start;
    margin-left: -40px;
    margin-bottom: 15px;
    @media (max-width: $lg) {
      margin-left: -35px;
    }
  }

  &__body {
    width: 100%;
  }

  &__text {
    margin-bottom: 15px;
    @media (max-width: $lg) {
      font-size: $fsz-14;
    }
  }

  &__info-text {
    display: grid;
    gap: 10px;
    margin-bottom: auto;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    @media (max-width: $lg) {
      margin-bottom: 15px;
      font-size: $fsz-14;
    }
  }

  &__form {
    display: grid;
    gap: 25px;
  }

  &__field {
    position: relative;

    .passwordInput-error,
    .input-error {
      position: absolute;
      top: 99%;
    }

    @media (max-width: $lg) {
      .ant-input {
        min-height: 40px;
        height: 40px;
      }
    }
  }
}
