@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    src: local("Montserrat"), 
        url('../../assets/fonts/MontserratRegular.woff2') format('woff2'), 
        url('../../assets/fonts/MontserratRegular.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 400;
    src: local("Montserrat"), 
        url('../../assets/fonts/MontserratItalic.woff2') format('woff2'), 
        url('../../assets/fonts/MontserratItalic.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    src: local("Montserrat"), 
        url('../../assets/fonts/MontserratBold.woff2') format('woff2'), 
        url('../../assets/fonts/MontserratBold.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 700;
    src: local("Montserrat"), 
        url('../../assets/fonts/MontserratBoldItalic.woff2') format('woff2'), 
        url('../../assets/fonts/MontserratBoldItalic.woff') format('woff');
    font-display: swap;
}
