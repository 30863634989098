@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.vehicleItem {
  @include line-link();
  display: grid;
  grid-template-columns: 4fr 5fr 170px;

  @media (max-width: $lg) {
    grid-template-columns: 1fr auto;
    gap: 5px 10px;
  }

  &__main {
    display: grid;
    grid-template-columns: 45px 1fr;
    column-gap: 15px;
    align-items: center;
    padding-right: 20px;
    border-right: $border-dark-blue;

    @media (max-width: $lg) {
      grid-template-columns: 35px 1fr;
      column-gap: 10px;
      padding-right: 0;
      border-right: 0;
    }
  }

  &__icon-wrapper {
    grid-row: 1/3;
    justify-self: center;
  }

  &__address-tooltip {
    @include tooltip-sm();
  }

  &__icon {
    font-size: $fsz-30;

    @media (max-width: $lg) {
      font-size: $fsz-22;
    }
  }

  &__make {
    @include vertical-text-overflow();
    font-weight: $font-weight-bold;
    text-transform: uppercase;
  }

  &__desc {
    grid-column: 2/3;
    font-size: $fsz-16;

    @media (min-width: $lg + 1) {
      @include text-overflow($max-width: 100%);
    }
    @media (max-width: $lg) {
      @include vertical-text-overflow();
      font-size: $fsz-14;
    }
  }

  &__overlay {
    @include tooltip-sm();
  }

  &__info {
    @include list-unstyled();
    flex-grow: 1;
    display: grid;
    grid-template-columns: 2fr 3fr 3fr;

    @media (max-width: $lg) {
      grid-column: 1/-1;
    }

    @media (max-width: $md) {
      grid-template-columns: 100%;
      row-gap: 3px;
    }
  }

  &__info-item {
    @include flex-column();
    justify-content: center;
    overflow: hidden;
    padding: 0 20px;

    @media (max-width: $lg) {
      &:first-of-type {
        padding-left: 0;
      }
      &:last-of-type {
        padding-right: 0;
      }
    }
    @media (max-width: $md) {
      padding: 0;
    }
  }

  &__label {
    white-space: nowrap;
  }

  &__value {
    @include text-overflow($max-width: 100%);
    font-size: $fsz-16;
    font-weight: $font-weight-bold;

    @media (max-width: $lg) {
      font-size: $fsz-14;
    }
  }

  &__tag-wrapper {
    max-width: 100%;
    align-self: center;
    justify-self: end;

    @media (max-width: $lg) {
      grid-row: 1/2;
      grid-column: 2/3;
      max-width: 170px;
    }
  }

  &__tag {
    @include text-overflow($width: calc(100% + 1px), $max-width: calc(100% + 1px));
    display: block;
  }

  &__tag-overlay {
    @include tooltip-sm();
    .ant-tooltip-inner {
      max-width: 220px;
    }
  }
}
