@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.customerLine {
  @include flex-start();
  @include line-link();

  @media (max-width: $lg) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    padding: 10px;
    font-size: $fsz-14;
  }

  &__title {
    grid-column: span 3;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto;
    align-items: center;
    column-gap: 10px;
    font-size: $fsz-16;
    @media (min-width: $lg + 1) {
      flex-grow: 1;
      column-gap: 20px;
    }
  }

  &__icon {
    width: 30px;
    height: 30px;
    grid-row: span 2;

    @media (max-width: $lg) {
      width: 24px;
      height: 24px;
    }
  }

  &__id {
    color: $accent-gray;
  }

  &__name {
    font-weight: $font-weight-bold;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__tag {
    width: 60px;
    margin: 0 20px;
    @media (max-width: $lg) {
      align-self: start;
      justify-self: end;
      margin: 0;
    }
  }

  &__info-list {
    @include list-unstyled();
    font-size: $fsz-14;
    @media (max-width: $lg) {
      grid-column: 1/-1;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-gap: 6px;
      font-size: $fsz-12;
    }
    @media (min-width: $lg + 0.02) {
      display: flex;
      padding-left: 20px;
      border-left: $border-dark-blue;
    }
  }

  &__info-item {
    @media (min-width: $lg + 1) {
      @include flex-column();
      align-items: center;
    }
  }

  &__info-label {
    @media (max-width: $lg) {
      margin-right: 8px;
    }
    @media (min-width: $lg + 1) {
      min-width: 90px;
      padding: 0 6px;
      text-align: center;
    }
  }

  &__info-value {
    font-weight: $font-weight-bold;
  }
}
