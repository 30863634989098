.salesOffsiteWorkingTh {
  &--number {
    min-width: 70px;
  }
  &--entityType {
    min-width: 145px;
  }
  &--entityNumber {
    min-width: 185px;
    width: 20%;
  }
  &--customers {
    min-width: 170px;
    width: 20%;
  }
  &--address {
    min-width: 175px;
    width: 40%;
  }
  &--cityTown {
    min-width: 165px;
    width: 20%;
  }
  &--postalCode {
    min-width: 175px;
  }
  &--distanceFromOffice {
    min-width: 250px;
  }
  &--entityStatus {
    min-width: 160px;
  }
  &--creationDate {
    min-width: 165px;
  }
}
