@import 'src/core/styles/variables';

.invoicingViewPageDetailsHead {
  @media (max-width: $lg) {
    height: 180px !important;
  }

  .head__inner-info-item {
    &:nth-child(3) {
      grid-column: 3/5;
    }

    @media (max-width: $lg) {
      &:nth-child(2) {
        grid-column: 2/6;
      }
      &:nth-child(3) {
        grid-column: 1/4;
      }
      &:nth-child(4) {
        grid-column: 4/7;
      }
      &:nth-child(5) {
        grid-column: 1/4;
      }
      &:nth-child(6) {
        grid-column: 4/7;
      }
    }
  }
}
