@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.partViewPartsKitsList {
  margin-top: 20px;
  @media (max-width: $lg) {
    margin-top: 15px;
  }
  &__empty {
    @media (max-width: $lg) {
      padding: 10px 0;
    }
    svg {
      height: 70px;
      @media (max-width: $lg) {
        height: 50px;
      }
    }
  }
}

.partViewPartsKitsItem {
  @include line-link();
  width: 100%;
  display: grid;
  align-items: stretch;
  grid-template-columns: 1fr auto 200px;
  text-align: left;
  @media (max-width: $md) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, auto);
    row-gap: 10px;
  }

  &__title {
    display: grid;
    grid-template-columns: auto 1fr;

    @media (max-width: $md) {
      grid-column: 1/4;
    }
  }

  &__icon {
    grid-column: 1/2;
    grid-row: 1/3;
    align-self: center;
    width: 30px;
    height: 30px;
    margin-right: 20px;
    @media (max-width: $md) {
      width: 24px;
      height: 24px;
    }
  }

  &__id {
    grid-column: 2/3;
    color: $accent-gray;
  }

  &__name {
    grid-column: 2/3;
    @include text-overflow($max-width: 100%);
    font-weight: $font-weight-bold;
  }

  &__status-wrapper {
    @include flex-center();
    padding: 0 20px;

    @media (max-width: $md) {
      justify-content: flex-end;
      padding: 0;
    }
  }

  &__status-icon {
    width: 25px;
    height: 25px;
    @media (max-width: $md) {
      width: 20px;
      height: 20px;
    }
  }

  &__category {
    @include flex-center();
    text-align: center;
    padding-left: 20px;
    border-left: $border-dark-blue;

    @media (max-width: $md) {
      border-left: 0;
      padding-left: 0;
      grid-column: 1/-1;
      justify-content: flex-start;
    }
  }
}
