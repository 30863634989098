@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.vehiclesFilters {
  &__status-selectable {
    margin-left: auto !important;
    margin-right: 10px !important;
    width: 110px;
  }

  &__status-overlay {
    .ant-dropdown-menu {
      min-width: 110px;
    }
  }

  &__filters {
    &.keywordFiltersSection--full-width {
      width: 100%;
      margin-top: 10px;
    }

    @media (max-width: $lg) {
      &.mobile-hidden {
        display: none !important;
      }
    }
  }
}
