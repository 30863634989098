@import 'src/core/styles/variables';

.map-wrapper {
  position: relative;
  width: 100%;
  padding-top: 120%;

  @media (min-width: $md + 1) {
    padding-top: 100%;
  }

  @media (min-width: $lg + 1) {
    padding-top: 50%;
  }
}

.map-content {
  position: absolute;
  inset: 0;
}

.map-notification {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: $color-white;
  padding: 10px;
  border-radius: 3px;
}
