@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.addComponent {
  @include flex-column();
  &__chips {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-bottom: 20px;
    @media (max-width: $lg) {
      gap: 10px 20px;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__content {
    @include flex-column();
    &-filters {
      margin-bottom: 20px;
      &__head {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        margin-bottom: 20px;
        gap: 20px;
        @media (max-width: $lg) {
          grid-template-columns: repeat(2, minmax(0, 1fr));
          gap: 10px 20px;
        }
        @media (max-width: $md) {
          grid-template-columns: 100%;
        }
      }
      &__search {
        @include placeholder-italic(false);
        margin: 20px 0;
      }
      &__pages {}
    }
  }
  .customComponentForm {
    &__input-wrapper {
      margin-bottom: 20px;
      @media (max-width: $lg) {
        margin-bottom: 10px;
      }
    }
    &__input-section {
      display: grid;
      gap: 20px;
      margin-bottom: 20px;
      &--4 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
      &--2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
      @media (max-width: $lg) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 10px 20px;
        margin-bottom: 10px;
      }
      @media (max-width: $md) {
        grid-template-columns: 100%;
      }
    }
    &__disabled-weight {
      .ant-input-suffix {
        position: absolute;
        right: 32px;
        top: 13px;
        font-size: $fsz-14;
        pointer-events: none;
      }
    }
  }
}

.addWithoutPriceWrap {
  &__text {
    &-list {
      padding-left: 0 !important;
      font-weight: $font-weight-bold;
    }
  }
}

.addComponentCoreNonCoreTh {
  &--number {
    min-width: 65px;
  }
  &--partCode {
    width: 25%;
    min-width: 160px;
  }
  &--manufacturerCode {
    width: 25%;
    min-width: 240px;
  }
  &--name {
    width: 25%;
    min-width: 180px;
  }
  &--description {
    width: 25%;
    min-width: 180px;
  }
}
