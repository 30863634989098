.affectedEntitiesTh {
    &--entityType {
        width: 16%;
        min-width: 140px;
    }
    &--entityNumber {
        width: 22%;
        min-width: 150px;
    }
    &--createdAt {
        width: 15%;
        min-width: 120px;
    }
    &--entityStatus {
        width: 15%;
        min-width: 130px;
    }
    &--businessArea {
        width: 20%;
        min-width: 130px;
    }
    &--partLinesCount {
        width: 12%;
        min-width: 100px;
    }
}
