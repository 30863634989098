.salesOfficeEmployeeReportTh {
  &--number {
    min-width: 70px;
  }
  &--orderNumber {
    width: 12.5%;
    min-width: 165px;
  }
  &--customer {
    width: 12.5%;
    min-width: 150px;
  }
  &--salesNet {
    width: 12.5%;
    min-width: 155px;
  }
  &--daysInNewStatus {
    width: 12.5%;
    min-width: 240px;
  }
  &--initialAssignee {
    width: 12.5%;
    min-width: 180px;
  }
  &--orderStatus {
    width: 12.5%;
    min-width: 160px;
  }
  &--creationDate {
    width: 12.5%;
    min-width: 165px;
  }
  &--cancellationDate {
    width: 12.5%;
    min-width: 195px;
  }
}
