@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.createAccountLeaveRequest {
  &__form-content {
    display: grid;
    grid-template-columns: 100%;
    gap: 10px;
  }

  &__date {
    display: grid;
    gap: 10px 20px;
    grid-template-columns: 1fr 280px;

    @media (max-width: $sm) {
      grid-template-columns: 100%;
      row-gap: 5px;
    }

    .customRadioGroup {
      align-self: end;

      .ant-radio-button-wrapper {
        margin-right: 0;
        min-width: auto;

        @media (min-width: $sm + 1) {
          height: 50px;
          padding: 0 10px;
          line-height: 48px;
        }
      }

      @media (min-width: $sm + 1) {
        &.offset-error {
          position: relative;
          bottom: 17px;
        }
      }
    }

    .customRadioGroup-group {
      display: grid;
      column-gap: 5px;
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
}
