@import "src/core/styles/mixins";
@import "src/core/styles/variables";

.transferSection {
  display: grid;
  grid-template-columns: minmax(0, 1fr) 50px minmax(0, 1fr);
  gap: 30px;
  margin-bottom: 20px;
  @media (max-width: $lg) {
    gap: 20px;
  }
  @media (max-width: $md) {
    grid-template-columns: 100%;
    grid-template-rows: 1fr 40px 1fr;
    gap: 15px;
  }

  .checkbox {
    @include table-checkbox();
    width: 100%;

    .ant-checkbox + span {
      transform: translateY(-5px);
      display: flex;
      width: calc(100% - 25px);
    }
  }

  &__column {
    @include flex-column();

    .transferItems {
      position: relative;
      height: 440px;
      @include flex-column();
      flex-grow: 1;
      border-radius: 10px;
      border: $border-dark-gray;

      &__head {
        @include flex-between();
        flex-wrap: wrap-reverse;
        min-height: 60px;
        gap: 10px;
        padding: 10px 15px;
        border-bottom: $border-main-gray;

        @media (max-width: $lg) {
          padding: 10px;
        }

        .select .ant-select-selector {
          min-height: 40px;
          height: 40px;
        }
      }

      &__head-filter {
        width: 100%;
      }

      &__list {
        @include flex-column();
        @include list-unstyled();
        @include scroll();
        flex: 1;
        padding: 10px 0;

        &--empty {
          align-content: center;
        }

        &--loading {
          overflow: hidden;
        }

        .spinner {
          top: 0;
          left: 0;
          border-radius: 10px;
        }

        .rc-virtual-list-holder-inner {
          padding: 0 15px;

          @media (max-width: $lg) {
            padding: 0 10px;
          }
        }
      }
    }
  }

  &__search {
    margin-bottom: 20px;
    @media (max-width: $lg) {
      margin-bottom: 15px;
    }
  }

  &__btns {
    display: grid;
    align-content: center;
    justify-content: center;
    gap: 15px;

    @media (max-width: $md) {
      grid-auto-flow: column;
    }

    &-item {
      @media (max-width: $md) {
        transform: rotate(90deg);
      }
    }
  }

  .infinite-scroll-component {
    overflow: visible !important;
  }
}

.emptyTransferList {
  @include flex-column();
  align-items: center;
  justify-content: center;
  height: 100%;
  color: $main-dark-blue;
  &__icon {
    margin-bottom: 10px;
    height: 38px;
    @media (max-width: $lg) {
      height: 32px;
    }
  }
  &__text {
    font-size: $fsz-18;
    @media (max-width: $lg) {
      font-size: $fsz-16;
    }
  }
}
