@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.scrolling-tabs {
  &.ant-tabs-top > .ant-tabs-nav {
    background-color: $color-white;
  }
  &--empty {
    &.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
      display: none;
    }
  }

  &.ant-tabs-top > .ant-tabs-nav::before {
    display: none;
  }
  .ant-tabs-tab {
    padding: 0;
  }
  &.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
    display: none;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 20px;
    @media (max-width: $lg) {
      margin-left: 10px;
    }
  }

  &__tab-button {
    max-width: 220px;
    display: flex;
    padding: 0 15px;
    font-weight: $font-weight-bold;
    line-height: 48px;
    font-size: 16px;
    border-radius: 10px;
    background-color: $color-white;
    border: 1px solid $dark-gray;
    color: $main-dark-blue;
    transition: $transition;

    &:hover,
    &:focus {
      background-color: $main-dark-blue;
      color: $color-white;
    }

    @media (max-width: $lg) {
      font-size: $fsz-14;
      line-height: 38px;
      padding: 0 10px;
    }

    .ant-tabs-tab.ant-tabs-tab-active & {
      background-color: $main-dark-blue;
      color: $main-yellow;
      border-color: $main-dark-blue;
    }
    .ant-tabs-dropdown-menu & {
      border: 0;
    }
  }

  &__tab-label {
    @include text-overflow($max-width: 100%);
    width: auto;
  }

  &__count-value {
    flex-shrink: 0;
    margin-left: 6px;
  }

  &__popup {
    .scrolling-tabs__tab-button {
      background-color: transparent;

      &:hover,
      &:focus {
        color: $main-dark-blue;
      }
    }
  }
}
