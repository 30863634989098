@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.privacyPolicyButton {
  height: auto;
  font-size: $fsz-14;
  font-weight: $font-weight-regular;
  color: currentColor;

  @include hover-focus-active() {
    color: currentColor;
    & > span {
      color: currentColor;
      text-decoration: underline;
    }
  }
}
