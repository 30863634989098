@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.selectable {
  width: 190px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  border: $border-dark-gray;
  border-radius: 10px;
  font-size: $fsz-16;
  text-align: left;
  background-color: rgba($main-dark-blue, 0.15);
  @media (max-width: $lg) {
    height: 40px;
    font-size: $fsz-14;
  }

  &--sm {
    width: 130px;
  }
  &--lg {
    width: 100%;
  }


  &.ant-btn {
    &:hover,
    &:focus,
    &:active {
      color: $main-dark-blue;
      border-color: $dark-gray;
      background: rgba($main-dark-blue, 0.15);
    }
  }

  & > .anticon.anticon-down {
    order: 1;
    flex-shrink: 0;
    margin-left: auto;
    font-size: $fsz-14;
  }

  &.ant-btn > .anticon + span {
    margin-left: 0;
    margin-right: 10px;
    @include text-overflow($max-width: 100%);
  }

  &__menu {
    border: $border-dark-blue-alpha;
    border-radius: 10px;
    overflow: hidden;

    .ant-dropdown-menu-item-selected {
      background-color: $color-gray;
      font-weight: $font-weight-bold;
    }

    .ant-dropdown-menu-item-disabled {
      color: $dark-gray !important;
      &:hover,
      &:focus,
      &:active {
        background-color: $color-white !important;
      }
    }
  }
}
