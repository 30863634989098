.rectificationReportTh {
  &--number {
    min-width: 70px;
  }
  &--linkedTo {
    width: 14%;
    min-width: 165px;
  }
  &--rectificationNo {
    width: 14%;
    min-width: 165px;
  }
  &--customer {
    width: 15%;
    min-width: 165px;
  }
  &--rectificationType {
    width: 10%;
    min-width: 200px;
  }
  &--faultType {
    width: 12%;
    min-width: 170px;
  }
  &--costNet {
    width: 10%;
    min-width: 155px;
  }
  &--salesNet {
    width: 10%;
    min-width: 155px;
  }
  &--completedDate {
    width: 15%;
    min-width: 185px;
  }
}
