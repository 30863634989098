@import "../../../core/styles/mixins";
@import "../../../core/styles/variables";

.head {
  position: sticky;
  top: $header-height-desktop;
  border-bottom: $border-dark-blue;
  background-color: $color-white;
  padding: 20px;
  padding-left: $sidebar-wide + 20px; // sidebar-wide width + a gap between
  display: flex;
  align-items: center;
  width: 100%;
  z-index: $z-index-subheader;
  @media (max-width: $lg) {
    height: 130px;
    top: $header-height-mobile;
    padding: 16px 10px;

    &.createPage {
      height: auto;
    }
  }

  &.headWide {
    padding-left: $sidebar-collapsed + 20px; // sidebar-collapsed width + a gap between
    @media (max-width: $lg) {
      padding: 16px 10px;
    }
  }

  &__inner {
    width: 100%;
    display: grid;
    grid-template-columns: 50px repeat(6, minmax(0, 1fr)) 50px;
    align-items: center;
    column-gap: 20px;
    @media (max-width: $lg) {
      width: 100%;
      grid-template-columns: 40px repeat(4, minmax(0, 1fr)) 40px;
      row-gap: 10px;
    }

    .dropdownActions {
      grid-column: 8 / 9;
      justify-self: end;
      background: none !important;
      outline: none !important;
      border-radius: 10px !important;
      width: 50px;
      height: 50px !important;
      border: $border-dark-gray;
      transition: $transition;
      @include flex-center();
      padding: 0 !important;
      @media (max-width: $lg) {
        width: 40px;
        height: 40px !important;
        grid-row: 1 / 2;
        grid-column: 6 / 7;
      }

      &:hover,
      &:focus-visible {
       border: $border-dark-blue !important;
      }

      &:hover .dropdownActions-icon,
      &:focus .dropdownActions-icon {
        color: $main-dark-blue;
      }

      &-icon {
        color: $dark-gray;
        height: 22px;
        transform: translateY(2px);
        transition: $transition;
      }
    }
  }

  &__inner-info {
    &-item {
      @include flex-column();

      &:nth-child(2) {
        @media (max-width: $lg) {
          grid-column: 2 / 6;
        }
      }

      &:nth-child(3) {
        grid-column: 3 / 4;
        @media (max-width: $lg) {
          grid-column: 1 / 7;
        }
      }

      &:nth-child(4) {
        grid-column: 5 / 6;
        @media (max-width: $lg) {
          grid-column: 1 / 3;
        }
      }

      &:nth-child(5) {
        grid-column: 6 / 7;
        @media (max-width: $lg) {
          grid-column: 3 / 5;
        }
      }

      &:nth-child(6) {
        grid-column: 7 / 8;
        @media (max-width: $lg) {
          grid-column: 5 / 7;
        }
      }

      &__label {
        @include text-overflow($max-width: 100%);
        font-size: $fsz-14;
        @media (max-width: $lg) {
          font-size: $fsz-12;
        }
      }

      &__value {
        @include text-overflow(100%, 100%);
        font-size: $fsz-16;
        font-weight: $font-weight-bold;
        @media (max-width: $lg) {
          font-size: $fsz-14;
        }
      }
    }

    &-item--narrow {
      &:nth-child(2) {
        grid-column: 2 / 4;
        @media (max-width: $lg) {
          grid-column: 2 / 6;
        }
      }

      &:nth-child(3) {
        grid-column: 5 / 6;
        @media (max-width: $lg) {
          grid-column: 1 / 3;
        }
      }

      &:nth-child(4) {
        grid-column: 6 / 7;
        @media (max-width: $lg) {
          grid-column: 3 / 5;
        }
      }

      &:nth-child(5) {
        grid-column: 7 / 8;
        @media (max-width: $lg) {
          grid-column: 5 / 7;
        }
      }
    }
  }
}
