@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.skeletonPartsKits {
  @include list-unstyled();
  margin-top: 20px;

  &__item {
    height: 66px;
    display: grid;
    grid-template-columns: auto 1fr 200px;
    align-items: center;
    padding: 10px 16px;
    background-color: $color-white;
    border-radius: 10px;
    border: $skeleton-border;
    &:not(:last-of-type) {
      margin-bottom: 20px;
    }
    @media (max-width: $lg) {
      height: 98px;
      grid-template-columns: auto 1fr;
    }
  }

  &__img {
    width: 30px;
    height: 30px;
    margin-right: 20px;
    border-radius: 4px;
    @media (max-width: $lg) {
      width: 24px;
      height: 24px;
    }
  }

  &__text {
    display: grid;
    row-gap: 8px;
  }

  &__title {
    width: 100px;
    height: 16px;
    border-radius: 2px;
    @media (max-width: $lg) {
      width: 80px;
      height: 14px;
    }
  }

  &__description {
    max-width: 300px;
    height: 16px;
    border-radius: 2px;
    @media (max-width: $lg) {
      max-width: 190px;
      height: 14px;
    }
  }

  &__category {
    @include flex-center();
    @media (max-width: $lg) {
      justify-content: flex-start;
      grid-column: 1/ -1;
    }
  }

  &__category-text {
    width: 130px;
    height: 16px;
    border-radius: 2px;
    @media (max-width: $lg) {
      width: 100px;
      height: 14px;
    }
  }
}
