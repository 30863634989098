@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.rectificationPartRequestTable {
  margin-bottom: 24px;
  @media (max-width: $lg) {
    margin-bottom: 15px;
  }
}

.rectificationPartRequestTh {
  &--ordinalNumber {
    min-width: 70px;
  }
  &--categoryId {
    width: 14%;
    min-width: 175px;
  }
  &--productNumber {
    width: 14%;
    min-width: 150px;
  }
  &--productName {
    width: 19%;
    min-width: 200px;
  }
  &--notes { //
    width: 10%;
    min-width: 160px;
  }
  &--quantity {
    width: 10%;
    min-width: 70px;
  }
  &--unitPrice {
    width: 7%;
  }
  &--priceSources {
    width: 7%;
  }
  &--discount { //
    width: 7%;
    min-width: 95px;
  }
  &--netPrice {
    width: 7%;
    min-width: 155px;
  }
  &--tax {
    width: 6%;
    min-width: 100px;
  }
  &--weight {
    width: 6%;
    min-width: 140px;
  }
  &--status {
    width: 10%;
    min-width: 135px;
  }
}
