@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.selectableDropdown {
  position: relative;

  .dropdownButton {
    @include flex-start();
    width: 100%;
    font-size: $fsz-16;
    border: none;
    box-shadow: none;
    outline: none;
    background: transparent;
    text-align: left;
    @media (max-width: $lg) {
      font-size: $fsz-14;
      padding: 0 10px;
    }

    &:hover, &:focus {
      color: $main-dark-blue;
      background-color: $color-white;
      border-radius: 10px;
    }

    &--selected {
      font-weight: $font-weight-bold;
    }

    svg {
      order: 1;
      flex-shrink: 0;

      & + span {
        @include text-overflow($max-width: 100%);
        & > span:first-child {
          @include text-overflow($max-width: 100%);
          margin-right: 4px;
        }
        & > span:last-child {
          flex-shrink: 0;
        }
      }
    }

    .selectableDropdown__selected-value {
      @include flex-start();
      min-width: 0;
      flex-grow: 1;
      span:first-child {
        @include text-overflow($max-width: 100%);
        margin-right: 4px;
      }
    }
  }

  .dropdownSearch {
    margin-bottom: 12px;
    padding: 0 10px;
    .input-field {
      padding: 4px 10px;
      min-height: 30px;
      border-color: rgba($main-dark-blue, 0.25);
      transition: $transition;
    }
  }

  .dropdownList {
    position: absolute;
    top: 44px;
    z-index: 40;
    background-color: $color-white;
    box-shadow: 0 3px 9px 1px rgba(0, 0, 0, 0.11);
    padding: 10px 0;
    @media (max-width: $lg) {
      top: calc(100% + 2px);
    }

    &__content-wrap {
      position: relative;
    }

    &__options {
      @include flex-column();
      @include scroll();
      max-height: 300px;
      overflow: auto;
      padding: 0 10px;

      @media (max-width: $md) {
        max-height: 150px;
      }

      .checkbox {
        @include table-checkbox();
        flex-shrink: 0;
        margin-left: 0;

        .ant-checkbox-inner {
          width: 20px;
          height: 20px;
          &::after {
            width: 7px !important;
            height: 10px !important;
            border-width: 2px !important;
          }
        }
        .checkbox-label {
          @include text-overflow($max-width: 100%);
        }
      }

      .ant-checkbox + span {
        width: calc(100% - 28px);
        display: flex;
        padding-left: 0;
        padding-right: 0;
        margin-left: 8px;
      }
    }

    &__noResult {
      @include flex-column();
      align-items: center;
      row-gap: 6px;
      line-height: 1;
      text-align: center;
      color: $main-gray;
    }
    [class*=ant-divider-horizontal] {
      width: auto;
      min-width: auto;
      margin: 6px 10px;
    }
    &__clear {
      display: flex;
      justify-content: flex-end;
      padding: 0 10px;
    }
    &__footer {
      @include flex-between();
      padding: 0 10px;
    }
    &:last-child {
      right: 0;
      left: 0;
    }

    &--range-picker {
      padding-right: 10px;
      padding-left: 10px;

      .ant-picker-range {
        width: 100%;
      }
    }

    &--tree {
      @include table-checkbox(sm);

      .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
        left: 50%;
      }

      .ant-tree-checkbox-inner::after {
        left: 25%;
      }

      .ant-tree-checkbox-checked::after {
        border: 0;
      }
      .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
        background-color: $main-dark-blue;
      }
      .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
        background-color: $main-dark-blue;
      }
      .ant-tree-title {
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
      }
    }
  }
}
