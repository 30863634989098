.jobQualityChecksTh {
  &--ordinalNumber {
    min-width: 70px;
    width: 10%;
  }
  &--checkResult {
    min-width: 130px;
    width: 40%;
  }
  &--checkDate {
    min-width: 130px;
    width: 50%;
  }
}
