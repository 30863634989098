@import 'src/core/styles/variables';
@import "src/core/styles/mixins";

.reportsContent {
  .actions-and-paging {
    @media (min-width: $lg + 1) {
      min-height: 60px;
    }
  }
}

.reportActionsAndPaging {
  @media (max-width: $lg) {
    flex-direction: row;
    align-items: center;

    .switch {
      margin-bottom: 0;
    }

    .segmentedCustom {
      height: 35px !important;
      border-radius: 5px !important;
      .ant-segmented-group {
        .ant-segmented-item {
          border-radius: 5px;
        }

        .ant-segmented-item-label {
          height: 35px;
          padding: 0 12px;
          line-height: 35px;
          border-radius: 5px;
        }
      }
    }
  }
}

.customBarTooltip {
  @include flex-column();
  padding: 8px;
}

.customBarTooltip__entity {
  font-weight: $font-weight-bold;
}

.reportSegmentedWrapper {
  @include flex-center();
  flex-wrap: wrap;
  justify-content: flex-start;
  span {
    margin-right: 8px;
  }
}
