@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.contactEventModal {
  &__field {
    @include flex-column();
  }
  &__label {
    @include field-label();
  }
  &__value {
    @include form-input();
    @include text-overflow($max-width: 100%);
    &--disabled {
      @include form-disabled();
    }
  }
  &__job-location {
    @include text-overflow($max-width: 100%);
  }
  &__tooltip {
    @include tooltip-sm();
  }
}
