@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.customersSkeletonItem {
    display: grid;
    grid-template-columns: 1fr auto auto;

    @media (max-width: $lg) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
        padding: 10px;
    }

    @include skeleton-image-and-text();

    &__info {
        @media (max-width: $lg) {
            grid-column: 1/4;
            padding: 3px 0;
        }
    }

    &__tag {
        height: 26px;
        width: 60px;
        border-radius: 6px;

        @media (min-width: $lg + 1) {
            align-self: center;
            margin-right: 20px;
        }

        @media (max-width: $lg) {
            justify-self: end;
        }
    }

    &__list {
        @media (min-width: $lg + 1) {
            @include flex-center();
            padding-left: 20px;
            border-left: $skeleton-border;
        }

        @media (max-width: $lg) {
            grid-column: 1/-1;
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            column-gap: 6px;
            padding: 3px 0;
        }
    }

    &__list-item {
        @media (min-width: $lg + 1) {
            width: 90px;
        }

        @media (max-width: $lg) {
            margin-top: 10px;
        }

        &::before,
        &::after {
            content: "";
            background-color: $skeleton-color;
            height: 14px;
            display: flex;

            @media (min-width: $lg + 1) {
                display: block;
                margin: 0 auto;
            }
        }

        &::before {
            width: 55px;

            @media (min-width: $lg + 1) {
                margin-bottom: 5px;
            }
        }

        &::after {
            width: 25px;

            @media (max-width: $lg) {
                display: none;
            }
        }

        &:nth-child(3) {
            width: 107px;

            &::before {
                width: 75px;
            }
        }
    }
}
