@import 'src/core/styles/variables';

.accountFilters {
  @media (max-width: $lg) {
    flex-direction: row;
    align-items: center;
    gap: initial;
    width: auto;
  }
}
