@import "src/core/styles/mixins";
@import "src/core/styles/variables";

.stockFiltersMobile {
  @include flex-between();
  flex-wrap: wrap;

  @media (min-width: $lg + 1) and (max-width: 1515px) {
    position: sticky;
    top: 80px;
    padding: 15px 10px 10px 10px;
    margin: 0 -10px 5px -10px;
    background-color: $color-white;
    z-index: 3;
  }
}

.stockFiltersTabs {
  @media (min-width: $lg) and (max-width: 1515px) {
    padding-top: 0;
  }
  @media (max-width: $lg) {
    margin-bottom: 15px;
  }
  &__filters {
    .keywordFiltersSection__filters {
      @media (min-width: $lg + 1) {
        grid-template-columns: repeat(3, 200px);
      }
    }
  }
  &:empty {
    padding: 0;
    margin: 0 !important;
  }

  &.partsKitView {
    @media (min-width: $lg + 1) and (max-width: 1515px) {
      position: static;
      z-index: 0;
    }
  }
}

.purchaseOrderFilters {
  &__segmented {
    @include expanded-sidebar() {
      .ant-segmented-item-label {
        padding: 14px 10px;
      }
    }
  }
  @media (max-width: $lg) {
    gap: 0;
    .keywordFiltersSection {
      margin-top: 15px;
    }
  }
}
.purchaseOrderItem {
  @include line-link();
  display: grid;
  grid-template-columns: 180px 1fr 1fr 230px 120px;
  @media (max-width: $lg) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 6px 20px
  }
  @media (max-width: $md) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 6px 5px
  }

  &__title {
    @include flex-start();
    padding-right: 16px;
    @media (max-width: $lg) {
      padding-right: 0;
    }
    @include expanded-sidebar() {
      padding-right: 10px;
    }
  }

  &__icon {
    width: 30px;
    height: 30px;
    margin-right: 15px;

    @media (max-width: $lg) {
      width: 25px;
      height: 25px;
      margin-right: 10px;
    }
  }

  &__number {
    font-size: $fsz-20;
    font-weight: $font-weight-bold;

    @media (max-width: $lg) {
      font-size: $fsz-18;
    }
  }

  &__data {
    @include flex-column();
    @include expanded-sidebar() {
      padding-right: 10px;
      padding-left: 10px;
    }
    row-gap: 2px;
    padding: 0 20px;
    overflow: hidden;
    @media (max-width: $lg) {
      grid-column: 1/2;
      padding: 0;
    }
    @media (max-width: $md) {
      grid-column: 1/-1;
      padding: 0;
    }

    &--supplier {
      border-right: $border-dark-blue;
      border-left: $border-dark-blue;
      @media (max-width: $lg) {
        border: 0;
      }

      svg {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        @media (max-width: $lg) {
          width: 15px;
          height: 15px;
          margin-right: 5px;
        }
      }
    }
  }

  &__name-wrapper {
    @include flex-start();
  }

  &__name {
    @include text-overflow($max-width: 100%);
    font-weight: $font-weight-bold;
    font-size: $fsz-16;
    @media (max-width: $lg) {
      font-size: $fsz-14;
    }
  }

  &__tag-wrapper {
    @include flex-end();
    @include expanded-sidebar() {
      padding-right: 10px;
      padding-left: 10px;
    }
    padding: 0 20px;

    @media (max-width: $lg) {
      grid-row: 1/2;
      grid-column: 2/3;
      padding: 0;
    }
  }

  &__tag {
    @media (max-width: $lg) {
      @include text-overflow($width: auto, $max-width: 100%);
      align-self: start;
      justify-self: end;

    }
  }

  &__count {
    @include flex-column();
    justify-content: center;
    padding-left: 16px;
    text-align: center;
    border-left: $border-dark-blue;
    @media (max-width: $lg) {
      grid-column: 2/3;
      grid-row: 2/3;
      justify-content: flex-start;
      padding-left: 0;
      border-left: 0;
      text-align: left;
    }
    @media (max-width: $md) {
      grid-column: 1/-1;
      grid-row: 4/5;
      padding: 0;
    }
  }

  &__count-value {
    font-weight: $font-weight-bold;
  }
}
