.soldProductsByCustomerTh {
  &--number {
    min-width: 65px;
  }
  &--customer {
    width: 17%;
    min-width: 130px;
  }
  &--productNumber {
    width: 20%;
    min-width: 180px;
  }
  &--productName {
    width: 24%;
    min-width: 210px;
  }
  &--quantity {
    width: 13%;
    min-width: 160px;
  }
  &--costNet {
    width: 13%;
    min-width: 150px;
  }
  &--salesNet {
    width: 13%;
    min-width: 155px;
  }
}
