@import "../core/styles/mixins";
@import "../core/styles/variables";

.homepage {
  @include flex-column();
  flex: 1;

  &__user {
    margin-bottom: 12px;
    @media (max-width: $lg) {
      margin-bottom: 8px;
      width: 74px;
      height: 74px;
    }
  }

  &__logo {
    max-width: 220px;
    @media (max-width: $lg) {
      max-width: 250px;
    }
  }

  &__text {
    @include flex-column();
    flex: 1;
    align-items: center;
    justify-content: center;
    text-align: center;

    &-line {
      font-size: $fsz-22;
      @media (max-width: $lg) {
        margin-bottom: 4px;
        font-size: $fsz-18;
      }
    }

    &-line-name {
      margin-bottom: 18px;
      font-size: $fsz-36;
      @media (max-width: $lg) {
        margin-bottom: 12px;
        font-size: $fsz-26;
      }
    }

    &-name {
      font-weight: $font-weight-bold;
    }
  }
}
