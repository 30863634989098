@import "src/core/styles/mixins";

.editReadyCollectedWrap {
  &__inputs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    margin-bottom: 24px;

    @media (max-width: $md) {
      gap: 8px;
      margin-bottom: 10px;
    }
  }

  &__tabs {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    margin-bottom: 24px;

    @media (max-width: $md) {
      grid-template-columns: 100%;
      gap: 10px;
      margin-bottom: 10px;
    }
  }

  &__pagination {
    margin-bottom: 10px;
  }

  &__table {
    margin-bottom: 20px;
  }
}

@mixin col-sizes($allocated, $ready, $collected) {
  .allocated & {
    width: $allocated;
  }
  .ready & {
    width: $ready;
  }
  .collected & {
    width: $collected;
  }
}

.editReadyCollectedTh {
  &--number {
    min-width: 65px;
  }
  &--priceSource {
    @include col-sizes(20%, 15%, 15%);
    min-width: 90px;
  }
  &--quantity {
    @include col-sizes(20%, 15%, 15%);
    min-width: 100px;
  }
  &--locationComment {
    @include col-sizes(0, 20%, 20%);
    min-width: 150px;
  }
  &--costPrice {
    @include col-sizes(20%, 15%, 15%);
    min-width: 110px;
  }
  &--owner {
    @include col-sizes(0, 0, 20%);
    min-width: 130px;
  }
  &--collectionDate {
    @include col-sizes(0, 0, 15%);
    min-width: 180px;
  }
  &--addedDate {
    @include col-sizes(20%, 15%, 0);
    min-width: 150px;
  }
  &--purchaseOrderNumber {
    @include col-sizes(20%, 15%, 0);
    min-width: 205px;
  }
}
