@import "src/core/styles/mixins";
@import "src/core/styles/variables";

.invoicingOrderLinesTh {
  &--orderingNumber {
    min-width: 65px;
  }
  &--partNumber {
    width: 15%;
    min-width: 216px;
  }
  &--partName {
    width: 20%;
    min-width: 220px;
  }
  &--notes {
    min-width: 180px;
  }
  &--receivedQuantity {
    width: 13%;
    min-width: 130px;
  }
  &--netReceived {
    width: 13%;
    min-width: 125px;
  }
  &--net {
    width: 13%;
    min-width: 155px;
  }
  &--invoicedPending {
    width: 13%;
    min-width: 180px;
  }
  &--toBeInvoicedPrice {
    width: 13%;
    min-width: 200px;
  }
  &--nominalCodes {
    min-width: 210px;
  }
}

.invoicingOrderLinesTd--nominal-codes {
  .tag {
    margin-right: 4px;
  }
}
