@import 'src/core/styles/mixins';
@import 'src/core/styles/variables';

.employeeLeaveRequestLine {
  @include line-link();
  display: grid;
  align-items: center;
  grid-template-columns: 125px minmax(215px, 1fr) minmax(0, 1fr) auto;
  padding: 6px 10px;

  @media (max-width: $md) {
    grid-template-columns: 125px 1fr auto;
  }

  &__type {
    @include flex-start();
    font-weight: $font-weight-bold;
  }

  &__icon {
    margin-left: 5px;
    transform: translateY(1px);
  }

  &__info {
    @include flex-column();
    border-left: $border-dark-blue;
    padding: 0 10px;

    @media (max-width: $md) {
      padding-left: 0;
      margin-top: 5px;
      grid-column: 1/-1;
      order: 1;
      border-left: 0;
    }
  }

  &__tag-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  &__actions-wrapper {
    margin-left: 10px;
  }
}
