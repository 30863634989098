@import "src/core/styles/variables";
@import "src/core/styles/mixins";
.jobViewPageHead {
  @include head-info-full();

  .head__inner-info-item {
    @media (max-width: $lg) {
      &:nth-child(3) {
        grid-column: 1/5;
      }
      &:nth-child(4) {
        grid-column: 5/7;
      }
    }
  }
}

.jobDetailsAndVehicles {
  @include flex-column();
  .info-grid {
    &__column {
      padding-bottom: 20px;
      &:not(:last-child) {
        margin-bottom: 20px;
        @media (max-width: $lg) {
          margin-bottom: 15px;
        }
      }
    }
  }
}

.jobDetailsSection {
  flex: 1;
}
