.table-with-editable-cells {
    .table-inputNumber {
    .inputNumber-field {
      @include form-input($minheight: 40px);
      padding: 7px;
      padding-left: 0;
      height: 40px;
      font-size: $fsz-14;
    }
  }

  .table-input {
    .input-field {
      min-height: 40px;
      font-size: $fsz-14;
    }
  }

  .regularInputNumber__numFieldWrap-field {
    height: 38px;
    min-height: 38px;
  }

  .select {
    .ant-select-selector {
      min-height: 40px;
      min-width: 90px !important;
      font-size: $fsz-14;

      &::before {
        display: none;
      }

      .ant-select-selection-item {
        padding-right: 0;

        &::before {
          display: none;
        }
      }
    }

    .ant-select-arrow {
      right: 12px;
    }
  }

  .editSection {
    display: block;
    position: relative;
    transition: $transition;

    &.editSection--active,
    &.editSection--partskit-quantity {
      cursor: pointer;

      &::before {
        content: '';
        opacity: 0;
        position: absolute;
        top: -7px;
        bottom: -7px;
        left: -5px;
        right: -5px;
        border-radius: 10px;
        border: $border-dark-gray;
        pointer-events: none;
      }

      &:hover::before {
        opacity: 1;
      }
    }

    &.editSection--partskit-quantity {
      &:hover {
        text-decoration: none;
      }
    }

    &.editSection--partskit-unit {
      cursor: auto;

      &:hover {
        text-decoration: none;
      }
    }
    &.editSection--partskit-unit-crossed {
      text-decoration: line-through;
      color: $dark-gray;
    }

    &.edit-Section--nowrap {
      white-space: nowrap;
    }

    &.editSection--disabled {
      cursor: not-allowed;
      color: $dark-gray;
    }
    &.editSection--bold {
      font-weight: $font-weight-bold;
    }
  }
}
