@import '../variables';
@import '../mixins';

.filters {
  @include flex-between();
  border-radius: 10px;
  padding: 9px 0;
  background-color: $color-gray;
  @media (max-width: $lg) {
    align-items: start;
    flex-direction: column;
    width: 100%;
    padding: 10px;
  }

  &__search {
    width: 100%;
    min-width: 160px;
    margin-right: 25px;

    &:first-child {
      padding-left: 10px;
    }

    @media (max-width: $lg) {
      margin-right: 0;
      margin-bottom: 8px;
      padding: 0 6px;
      border-radius: 8px;
      border: $border-dark-blue-alpha;
    }

    .input-field {
      min-height: 30px !important;
      padding: 0 0 0 5px !important;
      background-color: transparent !important;
      border: none !important;
      &:hover::placeholder,
      &:focus::placeholder {
        color: $dark-gray;
      }

      &::placeholder {
        font-size: $fsz-16;
        font-style: italic;
        color: $main-dark-blue;
        transition: $transition;
        @media (max-width: $lg) {
          font-size: $fsz-14;
        }
      }
      @media (max-width: $lg) {
        line-height: 32px;
      }
    }

    &:focus {
      box-shadow: none !important;
    }
  }

  .selectableDropdown {
    flex-grow: 1;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 0;
    border-left: 1px solid rgba($main-dark-blue, 0.5);
    @media (max-width: $lg) {
      width: 100%;
      border: none;
      padding: 0;
    }

    &.withoutSearchDropdown {
      &:first-child {
        border-left: none !important;
      }
    }

    .dropdownButton {
      justify-content: space-between;
      padding-left: 15px !important;
      padding-right: 15px !important;
      border-right: none !important;
      font-size: $fsz-16 !important;
      text-align: left;
      transition: $transition;
      @media (max-width: $lg) {
        width: 100%;
        max-width: 1180px;
        font-size: $fsz-14 !important;
        justify-content: space-between !important;
        padding-left: 6px !important;
        padding-right: 6px !important;
      }

      span {
        @include text-overflow($max-width: 300px);
      }
    }

    .dropdownButton--selected {
      font-weight: $font-weight-bold;
    }

    .dropdownButton ~ .ant-checkbox-checked {
      font-weight: $font-weight-bold !important;
    }

    .svg-inline--fa.fa-chevron-down {
      margin-left: 20px !important;
    }

    .dropdownListVisible {
      @media (max-width: $lg) {
        top: 100%;
      }

      @media (max-width: $md) {
        left: 0;
      }

      .input-field {
        padding: 4px 10px;
        min-height: 30px;
        border-color: rgba($main-dark-blue, 0.25);
        transition: $transition;
      }

      .dropdownSearch:hover .input-field,
      .input-field:focus {
        border-color: $main-dark-blue;
      }

      .ant-checkbox-wrapper {
        transition: $transition;

        .checkbox-label {
          @include text-overflow($max-width: 100%);
          display: block;
        }
      }

      .dropdownList__options {
        .ant-divider.ant-divider-horizontal {
          color: $main-dark-blue !important;
        }
      }

      .dropdownList__clear-button__icon {
        margin-right: 6px;
        transition: $transition;
      }

      .dropdownList__clear-button:hover .dropdownList__clear-button__icon,
      .dropdownList__clear-button:focus .dropdownList__clear-button__icon {
        color: $main-dark-blue;
      }
    }

    .dropdownList [class*=ant-divider-horizontal] {
      border-color: $main-dark-blue;
      margin-bottom: 0;
    }
  }

  .selectableDropdown--selected ~ .filters__clear {
    @media (max-width: $lg) {
      display: block;
    }
  }

  &__clear {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    background: none !important;
    margin-right: 16px;
    color: $accent-gray !important;
    @media (max-width: $lg) {
      display: none;
      align-self: center;
      width: 100%;
      max-width: 140px;
      margin-top: 10px;
      margin-left: 0;
      border-radius: 8px;
      border: $border-dark-gray !important;
      color: $primary-dark-blue !important;
    }

    & .clearAll-icon {
      font-size: $fsz-16 !important;
      transform: translateY(2px);
      @media (max-width: $lg) {
        margin-right: 6px;
        transform: none;
        font-size: $fsz-14 !important;
        font-weight: $font-weight-bold !important;
      }
    }

    &:hover,
    &:focus {
      color: $primary-dark-blue !important;
    }
  }
}
