@import './variables';
@import './mixins';

.mobile-sticky-filters {
  @media (max-width: $lg) {
    position: sticky;
    top: 200px;
    padding: 15px 10px 10px 10px;
    margin: 0 -10px 5px -10px;
    background-color: $color-white;
    z-index: $z-index-sticky-filters;

    &--top-sm {
      top: 70px;
    }

    &--top-md {
      top: 143px;
    }

    &--top-lg {
      top: 250px;
    }
  }
}

.desktop-sticky-filters {
  @media (min-width: $lg + 1) {
    position: sticky;
    top: 171px;
    padding: 20px 0 15px 0;
    margin-bottom: 5px;
    background-color: $color-white;
    z-index: $z-index-sticky-filters;

    &--top-sm {
      top: 80px;
    }
  }
}

.details-form {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px 32px;

  & + &:not(.no-offset-top) {
    margin-top: 20px;
  }

  .info-grid__title {
    grid-column: 1/-1;
  }

  @media (max-width: $lg) {
    grid-template-columns: 100%;
    gap: 12px;
  }

  &__field {
    &--lg {
      grid-column: 1/-1;
    }

    &--justify-start {
      justify-self: start;
    }

    &--align-bottom {
      align-self: end;
    }
  }

  &__actions {
    grid-column: 1/-1;
    margin-top: 10px;
  }
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;

  @media (max-width: $lg) {
    grid-template-columns: 100%;
    gap: 10px;
  }

  &__column {
    @include details-activity();
  }

  &__title-wrapper {
    @include flex-between();
    @include details-title();
  }

  &__title-inner {
    font-size: 24px;
    font-weight: $font-weight-bold;
    margin-bottom: 0;
    color: $main-dark-blue;
    @media (max-width: $lg) {
      font-size: $fsz-20;
    }
  }

  &__title {
    @include details-title();
  }
}

.sections-and-filters {
  @include flex-between();
  flex-wrap: wrap;
  gap: 20px;
  @media (max-width:$lg) {
    flex-direction: column;
    align-items: start;
    width: 100%;
    gap: 15px;
  }

  &--offset {
    margin-bottom: 20px;
    @media (max-width: $lg) {
      margin-bottom: 15px;
    }
  }

  &--mobile-offset {
    @media (max-width: $lg) {
      margin-bottom: 15px;
    }
  }
}


.create-form {
  max-width: 772px;
  margin-top: 20px;
  padding: 20px;
  padding-bottom: 40px;
  border-radius: 10px;
  background-color: $color-gray;
  @media (max-width: $lg) {
    padding: 10px;
    padding-bottom: 20px;
  }

  &--no-offset-top {
    margin-top: 0;
  }
}

.actions-and-paging {
  @include flex-between();
  flex-wrap: wrap;
  margin-bottom: 10px;

  &:empty {
    margin-bottom: 0;
  }

  @media (max-width: $lg) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 5px;
  }

  &__actions {
    @include flex-start();
    flex-wrap: wrap;
    margin-right: 10px;
    & > * {
      margin-right: 20px;
      margin-bottom: 10px;
      @media (max-width: $lg) {
        margin-right: 10px;
      }
      &:last-child {
        margin-right: 0;
      }
    }

    @media (max-width: $lg) {
      margin-bottom: 5px;
    }
  }

  &__paging {
    margin-left: auto;
    margin-bottom: 10px;
  }
}

.location-field {
  @include flex-column();

  &__label {
    @include field-label();
  }

  &__input {
    @include form-input();
    display: flex;
    padding: 11px;

    &--disabled {
      @include form-disabled();
    }
  }
  &__error {
    @include error-message();
  }

  &__icon {
    transform: translateY(3px);
    margin-right: 8px;
  }

  &__value {
    margin-bottom: 0;
  }
}

.lines-list {
  @include list-unstyled();

  &__item {
    &:not(:last-of-type) {
      margin-bottom: 20px;

      @media (max-width: $lg) {
        margin-bottom: 15px;
      }
    }
  }
}

@media (max-width: $md) {
  .ant-picker-range-wrapper,
  .ant-picker-date-range-wrapper {
    .ant-picker-panels {
      flex-direction: column;
    }
    .ant-picker-range-arrow {
      display: none;
    }
  }
}

.truncate-table-value {
  @include vertical-text-overflow();
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

.truncate-table-tooltip {
  @include tooltip-sm();
}

.trHidden {
  display: none;
}

.table-clickable-content {
  @include link();
  white-space: nowrap;
  width: fit-content;
}

.nowrap {
  white-space: nowrap;
}

.fit-content {
  display: block;
  width: fit-content;
}

.event-modal-radio {
  margin-bottom: 12px;
}

.skeleton-box {
  position: relative;
  overflow: hidden;
  background-color: $skeleton-color;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    animation: shimmer 2s infinite ease-out;
    background-image: linear-gradient(
                    90deg,
                    rgba($color-white, 0) 0,
                    rgba($color-white, 0.9) 50%,
                    rgba($color-white, 0) 100%
    );
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

.skeleton-line {
  background-color: $skeleton-color;

  &--dark {
    background-color: darken($skeleton-color, 7%);
  }
}

.skeleton-shimmer {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    animation: shimmer-new 3s infinite linear;
    background-image: linear-gradient(
                    90deg,
                    rgba($color-white, 0) 0,
                    rgba($color-white, 0.9) 50%,
                    rgba($color-white, 0) 100%);
    background-position-x: -100%;
    background-size: 50% 100%;
    background-repeat: no-repeat;
  }
}

@keyframes shimmer-new {
  100% {
    background-position-x: 200%;
  }
}

.skeleton-item {
  &:not(:last-child) {
    margin-bottom: 20px;

    @media (max-width: $lg) {
      margin-bottom: 15px;
    }
  }
  padding: 10px 16px;
  background-color: $color-white;
  border: $skeleton-border;
  border-radius: 10px;
}

.mt-2 {
  margin-top: 16px;
}

.inline {
  display: inline;
}

.fw-bold {
  font-weight: $font-weight-bold;
}

.tooltip-sm {
  @include tooltip-sm();
}

.no-tooltip-arrow {
  .ant-tooltip-arrow {
    display: none;
  }
}

.td-content-with-tooltip {
  display: flex;
  align-items: center;

  & > *:last-child:not(:first-child) {
    margin-left: 4px;
  }
}

.suitable-vehicles-list {
  @include list-unstyled();
  @include scroll();
  @include flex-column();
  overflow: auto;
  min-height: 120px;
  max-height: 200px;
  padding: 15px;
  background-color: $color-white;
  border-radius: 10px;
  border: $border-dark-gray;
  @media (max-width: $lg) {
    padding: 10px;
    font-size: $fsz-14;
  }
}
