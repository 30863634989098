@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.invoicingDashboardItem {
  @include line-link();
  display: grid;
  grid-template-columns: auto 520px 3fr 2fr 160px;

  @media (max-width: $xl) {
    grid-template-columns: auto auto 5fr 3fr 160px;
  }

  @media (max-width: $lg) {
    display: flex;
    flex-wrap: wrap;
  }

  &__icon {
    align-self: center;
    margin-right: 15px;

    svg {
      font-size: $fsz-28;

      @media (max-width: $lg) {
        font-size: $fsz-24;
      }
    }

    @media (max-width: $lg) {
      order: -2;
    }
  }

  &__label {
    @include text-overflow($max-width: 100%);

    @media (max-width: $sm) {
      font-size: $fsz-13;
    }
  }

  &__value {
    @include text-overflow($max-width: 100%);
    font-size: $fsz-16;
    font-weight: $font-weight-bold;

    @media (max-width: 1400px) {
      font-size: $fsz-14;
    }

    @media (max-width: $sm) {
      font-size: $fsz-13;
    }
  }

  &__info,
  &__price,
  &__entities {
    padding: 0 15px;
    border-left: $border-dark-blue;

    @media (max-width: $lg) {
      padding: 0;
      border-left: 0;
    }
  }

  li {
    display: flex;
    flex-direction: column;
    min-width: 0;
    padding: 0 8px;

    @media (max-width: $sm) {
      padding: 0 5px;
    }

    &:first-of-type {
      padding-left: 0;
    }
    &:last-of-type {
      padding-right: 0;
    }
  }

  &__info {
    display: grid;
    grid-template-columns: 2fr 3fr;
    border-left: 0;

    @media (max-width: $xl) {
      grid-template-columns: 140px 160px;
    }

    @media (max-width: 1400px) {
      grid-template-columns: 140px 120px;
    }

    @media (max-width: $lg) {
      width: 100%;
      margin-top: 5px;
      grid-template-columns: 1fr 1fr;
    }
  }

  &__price {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));

    @media (max-width: $lg) {
      width: 100%;
      margin: 5px 0;
      padding: 5px 0;
      border-top: $border-accent-gray;
      border-bottom: $border-accent-gray;
    }
  }

  &__entities {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media (max-width: $lg) {
      width: 100%;
    }
  }

  &__tag-wrapper {
    align-self: center;
    justify-self: end;

    @media (min-width: $lg + 1) {
      max-width: 100%;
      display: flex;
    }

    @media (max-width: $lg) {
      order: -1;
      margin-left: auto;
    }
  }
}


.invoicingSalesDashboardItem {
  @include line-link();
  display: grid;
  grid-template-columns: auto 3fr 2fr 1fr 150px;

  @media (max-width: $xl) {
    grid-template-columns: auto 5fr 4fr 2fr 150px;
  }

  @media (max-width: $lg) {
    display: flex;
    flex-wrap: wrap;
  }

  &__icon {
    align-self: center;
    margin-right: 8px;

    svg {
      font-size: $fsz-28;

      @media (max-width: $lg) {
        font-size: $fsz-24;
      }
    }

    @media (max-width: $lg) {
      order: -2;
    }
  }

  &__label {
    @include text-overflow($max-width: 100%);

    @media (max-width: $sm) {
      font-size: $fsz-13;
    }
  }

  &__value {
    @include text-overflow($max-width: 100%);
    font-size: $fsz-16;
    font-weight: $font-weight-bold;

    @media (max-width: $xl) {
      font-size: $fsz-15;
    }

    @media (max-width: 1400px) {
      font-size: $fsz-14;
    }

    @media (max-width: $sm) {
      font-size: $fsz-13;
    }
  }

  &__info,
  &__price,
  &__entities {
    padding: 0 10px;
    border-left: $border-dark-blue;

    @media (max-width: $lg) {
      padding: 0;
      border-left: 0;
    }
  }

  li {
    display: flex;
    flex-direction: column;
    min-width: 0;
    padding: 0 5px;

    &:first-of-type {
      padding-left: 0;
    }
    &:last-of-type {
      padding-right: 0;
    }
  }

  &__info {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    border-left: 0;

    @media (max-width: $lg) {
      width: 100%;
      margin-top: 5px;
    }

    @media (max-width: $md) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      column-gap: 8px;
      padding: 0;

      li {
        padding: 0;
      }

      & > li:first-child {
        grid-column: 1 / -1;
      }
    }
  }

  &__price {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));

    @media (max-width: $lg) {
      width: 100%;
      margin: 5px 0;
      padding: 5px 0;
      border-top: $border-accent-gray;
      border-bottom: $border-accent-gray;
    }
  }

  &__entities {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media (max-width: $lg) {
      width: 100%;
    }
  }

  &__tag-wrapper {
    align-self: center;
    justify-self: end;

    @media (min-width: $lg + 1) {
      max-width: 100%;
      display: flex;
    }

    @media (max-width: $lg) {
      order: -1;
      margin-left: auto;
    }
  }

  .tag {
    padding: 0 5px;
  }
}
