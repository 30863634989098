@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.vehicleSkeletonItem {
    display: grid;
    align-items: center;
    grid-template-columns: 4fr 5fr 170px;

    @media (min-width: $lg + 1) {
        height: 69.14px;
    }

    @media (max-width: $lg) {
        grid-template-columns: 1fr auto;
    }

    &__main {
        @include flex-column();
        justify-content: center;
        position: relative;
        width: 35px;
        height: 35px;
        background-color: $skeleton-color;
        border-radius: 4px;

        &::before,
        &::after {
            content: '';
            display: block;
            background-color: $skeleton-color;
            transform: translateX(62px);

            @media (max-width: $md) {
                transform: translateX(50px);
            }
        }

        &::before {
            height: 18px;
            width: 85px;
            margin-bottom: 5px;
        }

        &::after {
            height: 16px;
            width: 120px;
        }
    }

    &__info-wrapper {
        display: grid;
        grid-template-columns: 2fr 3fr 3fr;
        align-self: stretch;
        align-items: center;

        @media (min-width: $lg + 1) {
            border-left: $skeleton-border;
        }

        @media (max-width: $lg) {
            grid-column: 1/-1;
            margin-top: 19px;
        }

        @media (max-width: $md) {
            grid-template-columns: 100%;
        }
    }

    &__info {
        padding: 0 20px;

        @media (max-width: $lg) {
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
            }
        }

        @media (max-width: $md) {
            padding: 0;

            & + & {
                margin-top: 8px;
            }
        }

        &::before,
        &::after {
            content: '';
            display: block;
            background-color: $skeleton-color;
        }

        &::before {
            height: 14px;
            width: 50px;
            margin-bottom: 5px;

            @media (max-width: $md) {
                margin-bottom: 3px;
            }
        }

        &::after {
            height: 16px;
            width: 80px;
        }

        &:not(:first-child) {
            &::after {
                width: 120px;
            }
        }
    }

    &__tag-wrapper {
        justify-self: end;

        @media (max-width: $lg) {
            grid-row: 1/2;
            grid-column: 2/3;
        }
    }

    &__tag {
        height: 26px;
        width: 100px;
        border-radius: 8px;
    }
}
