@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.vehicleProfilesListTh {
  &--code {
    width: 13%;
    min-width: 140px;
  }
  &--manufacturer {
    width: 11%;
    min-width: 165px;
  }
  &--modelDescription {
    width: 26%;
    min-width: 230px;
  }
  &--type {
    width: 11%;
    min-width: 100px;
  }
  &--group {
    width: 11%;
    min-width: 120px;
  }
  &--createdDate,
  &--modifiedDate {
    width: 14%;
    min-width: 195px;
  }
}

.createVehicleProfileForm {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 15px;

  &__full-row {
    @media (min-width: $lg + 1) {
      grid-column: 1/3;
    }
  }

  @media (max-width: $lg) {
    grid-template-columns: 100%;
  }
}