@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.ant-picker-range, .ant-picker-focused {
  width: 250px;
  border: $border-dark-gray !important;
}

.ant-picker-active-bar {
  background-color: $dark-gray !important;
}
