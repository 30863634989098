@import "src/core/styles/mixins";
@import "src/core/styles/variables";

.createHead {
  font-size: $fsz-24;
  font-weight: $font-weight-bold;
  color: $main-dark-blue;
  grid-column: 2/-1;

  @media (max-width: $lg) {
    font-size: $fsz-20;
  }
}

.enquiryForm {
  @include flex-column();
  margin-top: 10px;

  &__customerLabel {
    @include field-label();
    margin-bottom: 8px;
    font-size: 14px;
    color: $main-dark-blue;
  }

  .select {
    .svg-inline--fa {
      margin-right: 5px;
    }

    .ant-select-selection-item div {
      @include text-overflow($max-width: 285px)
    }
  }
}

.empty {
  display: none !important;
}
