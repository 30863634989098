@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.suitableLine {
  color: $main-yellow;
  font-weight: $font-weight-bold;
}

.priceTags {
  display: flex;
}

.linePriceTag {
  &.tag {
    min-width: 32px;
  }
  & + & {
    margin-left: 4px;
  }

  &--gray {
    background-color: $accent-gray;
  }
}

.quoteLinesTh {
  &--number {
    min-width: 55px;
    width: 55px;
    max-width: 55px;
  }
  &--quoteCategory {
    width: 11%;
    min-width: 155px;
  }
  &--productNumber {
    width: 12%;
    min-width: 175px;
  }
  &--productName {
    width: 15%;
    min-width: 200px;
  }
  &--notes {
    width: 10%;
    min-width: 160px;
  }
  &--netPrice {
    width: 7%;
    min-width: 155px;
  }
  &--tax {
    width: 5%;
    min-width: 100px;
  }
  &--weight {
    width: 5%;
    min-width: 140px;
  }
}

