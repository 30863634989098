@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.partsKitsSkeletonItem {
    display: grid;
    grid-template-columns: 1fr auto auto;

    @media (max-width: $lg) {
        grid-template-columns: 1fr auto;
        padding: 10px;
    }

    @include skeleton-image-and-text();

    &__tag {
        height: 26px;
        width: 80px;
        border-radius: 6px;

        @media (min-width: $lg + 1) {
            align-self: center;
            margin-right: 20px;
        }

        @media (max-width: $lg) {
            justify-self: end;
        }
    }

    &__list {
        display: grid;
        grid-template-columns: 180px 210px 120px;

        @media (max-width: $lg) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
            grid-column: 1/-1;
            margin-top: 10px;
        }
    }

    &__list-item {
        @include flex-center();
        flex-direction: column;
        border-left: $skeleton-border;

        @media (max-width: $lg) {
            padding: 3px 10px 2px;

            &:first-child {
                border-left: 0;
            }
        }

        &::before {
            content: "";
            display: block;
            width: 120px;
            height: 14px;
            background-color: $skeleton-color;

            @media (max-width: $md) {
                width: 60px;
            }
        }

        &:last-child {

            @media (min-width: $lg + 1) {
                padding-left: 16px;
            }

            &::before {
                width: 60px;
            }

            &::after {
                content: "";
                display: block;
                width: 30px;
                height: 14px;
                margin-top: 5px;
                background-color: $skeleton-color;

                @media (max-width: $lg) {
                    display: none;
                }
            }
        }
    }
}
