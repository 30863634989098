@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.pricelistAffectedPartsTh {
    &--productNumber {
        width: 30%;
        min-width: 130px;
    }
    &--productName {
        width: 50%;
        min-width: 150px;
    }
    &--businessArea {
        width: 20%;
        min-width: 130px;
    }
}
