@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.editPartPriceTable {
  .priceFootWrapper {
    @include flex-start();

    .regularInputNumber,
    .pickerWrap {
      align-self: flex-start;
      width: 150px;
      margin-right: 15px;
    }

    .pickerWrap__field {
      @include form-input();
      @media (max-width: $lg) {
        min-height: 40px;
      }
    }

    .regularInputNumber__numFieldWrap-field,
    .pickerWrap__field {
      min-height: 40px;
      height: 40px;
    }

    &__add-btn {
      height: 40px;
      align-self: flex-start;
      margin-top: 22px;
      font-size: $fsz-16;
    }
  }

  .table-footer {
    &::before {
      bottom: 90px;
      @media (max-width: $lg) {
        bottom: 80px;
      }
    }
  }
}

.editPartPriceTh {
  &--number {
    width: 10%;
    min-width: 70px;
  }
  &--costPrice,
  &--sellPrice {
    width: 20%;
    min-width: 130px;
  }
  &--effectiveStartDate,
  &--effectiveEndDate {
    width: 25%;
    min-width: 170px;
  }
}
