@import "src/core/styles/mixins";
@import "src/core/styles/variables";

.componentsWrap {
  height: 100%;
  &__empty {
    @include flex-column();
    align-items: center;
    width: 100%;
    padding-top: 48px;

    &-box {
      height: 80px;
      margin-bottom: 32px;
    }
    &-text {

    }
  }
  &__summary {
    margin-top: 20px;
    @include details-activity();
    padding-bottom: 0;
    &-title {
      @include details-title();
      margin-bottom: 0;
    }
    &-values {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      .summaryValueItem {
        border-right: $border-dark-blue;
        padding: 20px 20px;
        @include flex-between();
        &:last-child {
          border-right: none;
        }
        &-title {
          color: $dark-gray;
        }
        &-value {

        }
      }
    }
  }
}

.hiddenBtn {
  display: none;
}

.editableComponentCell {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.notSuitableForAllVeh {
  color: $main-yellow;
  font-weight: $font-weight-bold;
}

.kitComponentsTh {
  &--number {
    min-width: 55px;
    width: 55px;
    max-width: 55px;
  }
  &--categoryId {
    width: 12%;
    min-width: 180px;
  }
  &--partNumber {
    width: 12%;
    min-width: 180px;
  }
  &--name {
    width: 20%;
    min-width: 300px;
  }
}
