@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.partsKitDuplicate {
  font-size: $fsz-16;
  @media (max-width: $lg) {
    font-size: $fsz-14;
  }

  &__text {
    @include flex-column();
    margin-bottom: 15px;

    & > *:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}
