@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.checkForm {
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .regularInputNumber__numFieldWrap-field:disabled,
  .location-field__input--disabled {
    color: rgba($main-dark-blue, 0.85);
  }
  .textarea-field.ant-input-disabled {
    color: rgba($main-dark-blue, 0.85) !important;
  }
}

.vehicleCheck-section {
  @media (min-width: $lg + 1) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.vehicleEquipment__extended-fields.vehicleCheck-section {
  @media (max-width: $lg) {
    margin-top: 12px;
  }
}

.additionalInfoVehicleCheck__uploader {
  .ant-upload.ant-upload-drag {
    height: auto;
  }
}

.inspectionMarker {
  grid-column: 1/-1;
}

.markersButton {
  grid-column: 1/-1;
  justify-self: start;
}

.customImageMarker {
  @include flex-center();
  background-color: $color-red;
  color: $color-white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: $fsz-14;
  box-shadow: 0 0 2px 2px rgba($main-dark-blue, 0.3);

  @media (max-width: $md) {
    width: 20px;
    height: 20px;
  }
}

.additionalInfoAttachments {
  &__label {
    @include field-label();
  }

  &__list {
    @include list-unstyled();
    margin-bottom: 20px;

    @media (max-width: $lg) {
      margin-bottom: 12px;
    }
  }

  &__item {
    & + & {
      margin-top: 10px;
    }
  }

  &__attach {
    border: $border-accent-gray;
    border-radius: 10px;
    padding: 8px 16px;
    font-size: $fsz-14;
    background-color: $color-white;
    cursor: pointer;
  }

  .empty-content {
    margin-bottom: 20px;
    color: rgba($main-dark-blue, 0.85);

    @media (max-width: $lg) {
      margin-bottom: 12px;
    }
  }
}

.markerContentWrap {
  @include flex-start();
}

.markerComment {
  flex: 1;
  margin-right: 10px;
}

.markerCommentDisabled {
  @include form-input();
  @include form-disabled();
  @include text-overflow($max-width: 100%);
  margin-right: 10px;
  color: rgba($main-dark-blue, 0.85);

  @media (max-width: $lg) {
    line-height: 28px;
  }
}

.markerCommentTooltip {
  @include tooltip-sm();
}
