@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.invoicingPurchasesFilters {
  .keywordFiltersSection__filters {
    @media (min-width: $lg + 1) {
      grid-template-columns: repeat(var(--filters-count, 3), 230px);
    }
  }
}
