@import "./src/core/styles/mixins";
@import "./src/core/styles/variables";

.textarea {
  @include flex-column();
  &-label {
    @include field-label();
  }
  &-field {
    cursor: pointer;
    width: 100%;
    padding: 10px !important;
    font-size: $fsz-16 !important;
    color: $main-dark-blue !important;
    background-color: $color-white;
    border: $border-dark-gray !important;
    border-radius: 10px !important;
    box-shadow: none !important;
    transition: $transition;

    &:hover {
      border: $border-dark-blue !important;
    }

    &:focus, &:focus-visible {
      border: $border-dark-blue !important;
      background-color: $color-gray;
      outline: 0;
    }

    @media (max-width: $lg) {
      font-size: $fsz-14 !important;
    }

    &.ant-input-disabled {
      @include form-disabled();
      color: $accent-gray !important;
    }
  }
  &-error {
    @include error-message();
  }
}
