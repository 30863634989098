@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.vehicleAddEventModal {
  &__fields {
    &:last-child {
      margin-top: 20px;
      @media (max-width: $lg) {
        margin-top: 12px;
      }
    }
  }

  &__field {
    @include flex-column();
  }

  &__label {
    @include field-label();
  }

  &__value {
    @include text-overflow($max-width: 100%);
    @include form-input();
    padding: 0 10px;
    line-height: 48px;
  }

  &__tooltip {
    @include tooltip-sm();
  }
}
