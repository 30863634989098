@import 'src/core/styles/mixins';

.createPurchaseOrderModesModal {
  @include modal-choice-buttons();
}

.createPurchaseOrderTh {
  &--supplierName{
    width: 20%;
    min-width: 150px;
  }
  &--partNumber {
    width: 20%;
    min-width: 145px;
  }
  &--quantity {
    width: 20%;
    min-width: 125px;
  }
  &--partRequestNumbers {
    width: 20%;
    min-width: 160px;
  }
  &--linkedNumbers {
    width: 20%;
    min-width: 135px;
  }
}
