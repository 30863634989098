@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.pagination {
    @include flex-end();
    width: 100%;
    white-space: nowrap;

    &--offset-bottom {
        margin-bottom: 15px;
    }

    &--narrow {
        @media (min-width: $lg + 1px) {
            width: calc(50% + var(--extra-width, 0px));
        }
    }

    &--empty {
        .ant-pagination-item {
            display: none;
        }
    }

    &--no-size-changer {
        .ant-pagination .ant-pagination-total-text:not(:empty) {
            margin-right: 0;
        }
    }

    &:empty {
        margin: 0;
    }

    li {
        flex-shrink: 0;
    }
}

.ant-pagination {
    @include flex-end();
    width: 100%;

    @media (max-width: $lg) {
        flex-wrap: wrap-reverse;
    }

    @media (max-width: $sm) {
        justify-content: center;
    }

    .ant-pagination-prev,
    .ant-pagination-next {
        display: none;
    }

    .ant-pagination-item {
        height: 24px !important;
        padding: 0 4px;
        margin-right: 0;
        font-size: $fsz-16;
        font-family: $montserrat-font;
        color: $main-dark-blue;
        line-height: 1.5;
        border: none;
        border-radius: 0;
        background-color: transparent;
        @media (max-width: $lg) {
            font-size: $fsz-14;
            line-height: 22px;
        }
        @media (max-width: $sm) {
            margin-top: 6px;
        }

        & + .ant-pagination-item {
            border-left: $border-dark-gray !important;
        }

        &-active {
            border: none;
            background-color: rgba($color-white, 0);
            text-decoration: underline;
        }

        &-active a,
        &:hover a,
        &:focus a {
            color: $main-dark-blue !important;
            text-decoration: underline;
        }
    }

    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
        .ant-pagination-item-container .ant-pagination-item-link-icon {
            color: $main-dark-blue;
        }
        @media (max-width: $sm) {
            margin-top: 6px;
            .ant-pagination-item-link-icon {
                transform: translateY(-2px);
            }
        }
    }

    .ant-pagination-total-text:not(:empty) {
        order: 1;
        margin-left: auto;
        margin-right: 20px;
        font-size: $fsz-16;
        color: $main-dark-blue;
        height: auto;
        line-height: 1.5;
        @media (max-width: $lg) {
          font-size: $fsz-14;
          white-space: nowrap;
          @media (max-width: $sm) {
            margin-right: 0;
          }
        }
        @media (max-width: $sm) {
            width: 100%;
            text-align: right;
        }
    }

    .ant-pagination-options {
        @include form-select();
        order: 2;
        margin-left: 0;
        @media (max-width: $md) {
            display: none;
        }
    }

    .ant-pagination-item-after-jump-prev,
    .ant-pagination-item-before-jump-next {
        @media (max-width: $lg) {
            display: inline-block;
        }
    }
}
