@use "sass:math";
@import "src/core/styles/mixins";
@import "src/core/styles/variables";

$gray-stripes: repeating-linear-gradient(
                -45deg,
                $main-light-gray-100,
                $main-light-gray-100 15px,
                $color-white 15px,
                $color-white 30px
);

$slot-count: 24;
$slot-width: 5px;
$slot-gap: 4px;

$gap-swap: math.div($slot-gap, 2) * -1;

$name-col-width: 300px;
$name-col-width-mobile: 160px;
$date-col-width: ($slot-count * $slot-width) + ($slot-count * $slot-gap) - $slot-gap;

.calendarWrap {
  @include scroll();
  overflow: auto;
  flex: 1;
  margin: 0 -20px -20px 0;
  padding-right: 15px;

  @media (max-width: $lg) {
    margin: 0 -10px -10px 0;
    padding-right: 5px;
  }
}

.date-separator {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: $accent-gray;
  z-index: -1;

  @for $i from 1 through 14 {
    &--#{$i} {
      left: ($name-col-width + $slot-gap) + ($date-col-width * $i) + ((math.div($slot-gap, 2) * $i) * 2) + $gap-swap;
      @media (max-width: $md) {
        left: ($name-col-width-mobile + $slot-gap) + ($date-col-width * $i) + ((math.div($slot-gap, 2) * $i) * 2) + $gap-swap;
      }
    }
  }
}

.calendarWrapper {
  position: relative;
  display: grid;
  grid-template-columns: $name-col-width repeat(var(--days-count, 5), $date-col-width);
  column-gap: $slot-gap;
  width: calc(#{$name-col-width} + calc(var(--days-count) * #{$date-col-width}) + (var(--days-count) * #{$slot-gap}));
  margin-bottom: 8px;

  @media (max-width: $md) {
    grid-template-columns: $name-col-width-mobile repeat(var(--days-count, 5), $date-col-width);
    width: calc(#{$name-col-width-mobile} + calc(var(--days-count) * #{$date-col-width}) + (var(--days-count) * #{$slot-gap}));
  }

  &--with-pagination {
    .date-separator {
      bottom: 54px;

      @media (max-width: $md) {
        bottom: 44px;
      }
    }
  }
}

.date {
  height: 50px;
  position: sticky;
  top: 0;
  z-index: 3;
  @include flex-column();
  align-items: center;
  background-color: $color-white;
  @media (max-width: $md) {
    font-size: $fsz-14;
  }

  &:not(:nth-child(1)):not(:nth-child(2)) {
    border-left: 1px solid $accent-gray;
  }

  &:not(.sticky-left) {
    margin: 0 -2px;
    padding: 0 2px;
  }

  &.sticky-left {
    top: 0;
    z-index: 5;
  }

  &__weekday {
    color: $dark-gray;
  }
}

.sticky-left {
  position: sticky;
  left: 0;
  background-color: $color-white;
  z-index: 4;
  border-right: 1px solid $accent-gray;
}

.calendarJobInfo {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto auto;
  padding: 12px 0;
  border-bottom: $border-main-gray;
  background-color: $color-white;
  @media (max-width: $md) {
    grid-template-columns: auto 1fr auto;
  }

  .listIcon {
    font-size: $fsz-18;
    margin-right: 10px;
    color: $main-dark-blue;

    @media (max-width: $md) {
      grid-row: 1/3;
    }

    &.disabledMove {
      cursor: not-allowed;
      color: $main-gray;
    }
  }

  &__content {
    @include flex-column();
    min-width: 0;
    padding-right: 15px;
  }

  &__number,
  &__name {
    @include text-overflow($max-width: 100%);
  }

  &__number {
    font-weight: $font-weight-bold;
    color: $dark-gray;
  }

  &__time-wrapper {
    width: 360px;
    display: grid;
    justify-content: center;
    gap: 5px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    text-align: center;
    border-left: 1px solid $main-dark-blue;
    margin-left: 15px;
    padding-left: 10px;

    @media (max-width: $md) {
      margin-left: 0;
      padding-left: 0;
      border-left: 0;
      grid-column: 2/-1;
      width: 100%;
    }
  }

  &__time {
    @include flex-column();
      font-size: $fsz-13;
  }
}

.calendarLine {
  height: 85px;
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: $name-col-width repeat(var(--days-count), $date-col-width);
  column-gap: $slot-gap;

  @media (max-width: $md) {
    grid-template-columns: $name-col-width-mobile repeat(var(--days-count), $date-col-width);
  }

  &__employee {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr auto;
    column-gap: 8px;
    padding-right: 5px;

    .dropdownTriggerBtn .fa-ellipsis-vertical {
      transform: translateX(0);
    }
  }

  &__employee-info {
    @include flex-column();
    min-width: 0;
  }

  &__employee-name,
  &__employee-role {
    @include text-overflow($max-width: 100%);
  }

  &__employee-name {
    font-weight: $font-weight-bold;
    @media (max-width: $md) {
      font-size: $fsz-14;
    }
  }

  &__employee-role {
    font-size: $fsz-14;
    color: $dark-gray;
  }

  &__content {
    grid-column: 2/-1;
    display: grid;
    grid-template-columns: repeat(auto-fill, $slot-width);
    column-gap: $slot-gap;
    padding: 10px 0;

    & > .weekend,
    & > .stripe {
      &:first-child {
        margin-left: $slot-gap * -1;
      }
    }
  }

  &--last-truncated {
    & > .job:last-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      & > .dayContent:last-child {
        margin-right: -1px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}

.job {
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fill, $slot-width);
  column-gap: $slot-gap;
  box-shadow: inset 0 0 0 1px $accent-gray;
  background: $color-white;
  border-radius: 6px;
  cursor: pointer;

  &.ant-tooltip-open {
    box-shadow: inset 0 0 0 2px $main-yellow;

    .jobName {
      font-weight: $font-weight-bold;
    }

    .job__info + .dayContent {
      margin-left: 2px;
    }
    .dayContent {
      margin-top: 2px;
      margin-bottom: 2px;

      &:last-child {
        margin-right: 2px;
      }
    }
  }

  &__info {
    position: absolute;
    top: 7px;
    right: $slot-width + $slot-gap;
    bottom: 7px;
    left: $slot-width + $slot-gap;
    display: grid;
    grid-template-columns: auto;
    align-content: space-between;
    justify-content: start;
    justify-items: start;
    column-gap: $slot-width + $slot-gap;

    .jobName {
      grid-column: 2/-1;
      display: inline-block;
      @include text-overflow($max-width: 100%);
      @media (max-width: $md) {
        font-size: $fsz-14;
      }
    }

    .tag {
      grid-column: 2/-1;
      @include text-overflow($max-width: 100%);
      width: auto;
      padding: 0 5px;
    }
  }

  .jobCountsPoint {
    @include flex-center();
    position: absolute;
    top: -8px;
    right: -8px;
    min-width: 20px;
    height: 20px;
    padding: 4px;
    background-color: $main-yellow;
    color: $main-dark-blue;
    font-size: $fsz-14;
    font-weight: $font-weight-bold;
    border-radius: 10px;
    z-index: 1;
  }

  &__timer {
    grid-row: 1/3;
    width: $slot-width * 2 + $slot-gap;
    border: $border-accent-gray;
    border-radius: 6px;
    background-color: $color-white;
  }

  .noProgress {
    background-color: $transparent;
  }

  .overspent {
    background-color: $color-red;
  }
  .done {
    background-color: $main-yellow;
  }
  .someProgress {
    background: linear-gradient(
                    to top,
                    $main-yellow 0%,
                    $main-yellow var(--job-progress),
                    $transparent var(--job-progress),
                    $transparent 100%
    );
  }
  .disabledProgress {
    background: linear-gradient(
                    to top,
                    $accent-gray 0%,
                    $accent-gray 50%,
                    $transparent 50%,
                    $transparent 100%
    );
  }
}


.dayContent {
  margin-top: 1px;
  margin-bottom: 1px;
  background-color: $color-white;

  &.weekend,
  &.stripe,
  &.postponed {
    margin-left: $gap-swap;
  }

  .job__info + & {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    margin-left: 1px;
  }
  &:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    margin-right: 1px;
  }
}

.weekend,
.stripe,
.postponed {
  margin-right: $gap-swap;
  margin-left: -1px;
}
.weekend {
  grid-column: span $slot-count;
  background-color: $main-light-gray;
}
.stripe {
  background: $gray-stripes;
}
.postponed {
  background-color: darken($color-gray, 7%);
}

.jobTooltip {
  position: fixed;
  bottom: 20px;
  top: auto !important;
  right: 20px;
  left: auto !important;
  max-width: 100%;
  padding: 0 !important;

  @media (max-width: $lg) {
    right: 10px !important;
    bottom: 10px !important;
  }

  &__buttons-wrap {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  &__button {
    &.customButton.ant-btn-icon-only {
      width: 32px;
    }

    &:not(:disabled) {
      color: $accent-blue;

      @include hover-focus-active() {
        color: $main-dark-blue;
        background-color: transparent;
      }
    }

    &:last-child {
      margin-left: 15px;
    }
  }

  .ant-tooltip-inner {
    @include scroll();
    width: 300px;
    height: 280px;
    overflow: auto;
    font-size: $fsz-15;
    padding: 10px !important;
    border: 1px solid $main-gray;
    color: $main-dark-blue !important;
    background-color: $main-light-gray !important;
  }

  .ant-tooltip-arrow {
    display: none;
  }
}

.jobTipInfo {
  &__title {
    display: block;
    margin-top: 2px;
    margin-bottom: 15px;
    font-size: $fsz-16;
    font-weight: $font-weight-bold;
  }

  &__list {
    @include list-unstyled();
  }

  &__item {
    & + & {
      margin-top: 6px;
    }
  }

  &__label {
    flex-shrink: 0;
    margin-right: 3px;
  }

  &__value {
    min-width: 0;
    font-weight: $font-weight-bold;

    &--highlighted {
      color: $color-red;
    }
  }
}

.jobCalendarPaginationCol {
  @include flex-center();
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 4;
  padding: 7px 5px;
  background-color: $color-white;
  border-right: 1px solid $accent-gray;

  span {
    margin: 0 10px;
    @media (max-width: $sm) {
      margin: 0 5px;
    }
  }

  .customButton.ant-btn-icon-only {
    width: 40px;
    height: 40px;

    @media (max-width: $md) {
      width: 30px;
      height: 30px;
    }

    & > svg {
      font-size: $fsz-20;
      @media (max-width: $md) {
        font-size: $fsz-16;
      }
    }
  }
}
