@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.skillsModal {
  max-height: 350px;
  margin-top: 15px;
  border: $border-dark-gray;
  border-bottom: 0;
  border-radius: 8px 8px 0 0;
  @media (max-width: $lg) {
    margin-top: 10px;
  }

  .table-new {
    border: 0;
    border-radius: 0;
    &::after {
      display: none;
    }
  }

  &__level-edit {
    width: 170px;
    @media (max-width: $lg) {
      width: 150px;
    }
  }

  &__footer {
    position: relative;
    display: flex;
    margin-bottom: 15px;
    padding: 10px 15px;
    border: $border-dark-gray;
    border-top: 0;
    border-radius: 0 0 8px 8px;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 15px;
      right: 15px;
      height: 2px;
      background-color: $dark-gray;
    }
    @media (max-width: $lg) {
      margin: 0 -10px;
      margin-bottom: 10px;
    }
    @media (max-width: $md) {
      display: grid;
      grid-template-columns: minmax(0,2fr) minmax(0, 1fr);
      gap: 15px;
    }
    @media (max-width: $sm) {
      grid-template-columns: 100%;
      gap: 10px;
    }
  }

  &__level-select {
    width: 170px;
    margin-right: 15px;

    @media (max-width: $md) {
      width: 100%;
      margin-right: 0;
    }
  }

  &__skill-select {
    width: 50%;
    margin-right: 15px;
    @media (max-width: $md) {
      width: 100%;
      margin-right: 0;
    }
  }

  &__add-skill-btn {
    @media (max-width: $lg) {
      height: 50px;
    }
    @media (max-width: $md) {
      grid-column: 1/-1;
      justify-self: center;
      height: 40px;
    }
  }

  .table-new-th {
    background-color: $color-white;
    position: sticky;
    top: 0;
    z-index: 2;

    &:first-child {
      &::after {
        left: 15px;
      }
    }

    &:last-child {
      &::after {
        right: 15px;
      }
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
      height: 2px;
      background-color: black;
    }
  }
}

.skillsModalTh {
  &--number {
    min-width: 50px;
  }
  &--categoryName {
    width: 30%;
    min-width: 160px;
  }
  &--name {
    width: 40%;
    min-width: 180px;
  }
  &--level {
    width: 30%;
    min-width: 180px;
  }
}

.skillSectionTh {
  &--categoryName {
    width: 65%;
    min-width: 300px;
  }
  &--level {
    width: 35%;
    min-width: 150px;
  }
}

.employeeSkillListTable {
  min-height: auto;
  font-size: $fsz-14;

  .childSkillTd {
    &:first-child {
      padding-left: 60px;
    }
  }
}
