@import 'src/core/styles/mixins';

.partViewPageTabs {
  @include segmented-sm();
}

.partViewAttachments {
  .tableWrap {
    @media (min-width: $lg + 1) {
      padding-right: 4px;
    }
  }
}
