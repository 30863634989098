@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.pricelistSkeletonItem {
    @include flex-start();
    min-height: 66px;

    @media (max-width: $lg) {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        row-gap: 15px;
        padding: 10px;
    }

    &__info {
        flex: 1;
        @include flex-start();

        @media (max-width: $lg) {
            grid-column: 1/4;
        }

        &::before,
        &::after {
            content: "";
            display: block;
            background-color: $skeleton-color;
        }

        &::before {
            width: 30px;
            height: 30px;
            border-radius: 4px;
            margin-right: 20px;

            @media (max-width: $lg) {
                width: 24px;
                height: 24px;
                margin-right: 10px;
            }
        }

        &::after {
            width: 140px;
            height: 18px;

            @media (max-width: $lg) {
                height: 16px;
            }

            @media (max-width: $md) {
                width: 120px;
            }
        }
    }

    &__tag {
        height: 26px;
        width: 90px;
        margin-right: 20px;
        border-radius: 6px;

        @media (max-width: $lg) {
            justify-self: end;
            margin-right: 0;
            width: 80px;
        }
    }

    &__areas {
        align-self: stretch;
        @include flex-center();
        border-right: $skeleton-border;

        @media (min-width: $lg + 1) {
            width: 210px;
            padding: 0 20px;
            border-left: $skeleton-border;
        }

        @media (max-width: $lg) {
            grid-column: 1/3;
        }

        &::before {
            content: "";
            display: block;
            width: 130px;
            background-color: $skeleton-color;
            height: 14px;

            @media (max-width: $md) {
                width: 110px;
            }
        }
    }

    &__count {
        @media (min-width: $lg + 1) {
            width: 120px;
            padding-left: 16px;
        }

        @media (max-width: $lg) {
            grid-column: 3/5;
            @include flex-center();
        }

        &::before,
        &::after {
            content: "";
            display: block;
            height: 14px;
            background-color: $skeleton-color;

            @media (min-width: $lg + 1) {
                margin: 0 auto;
            }
        }

        &::before {
            width: 45px;

            @media (min-width: $lg + 1) {
                margin-bottom: 5px;
            }

            @media (max-width: $lg) {
                width: 60px;
            }
        }

        &::after {
            width: 25px;

            @media (max-width: $lg) {
                display: none;
            }
        }
    }

    &__areas,
    &__count {
        @media (max-width: $lg) {
            padding: 3px 0 2px 0;
        }
    }
}
