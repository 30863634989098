@import "../../../../../core/styles/variables";
@import "../../../../../core/styles/mixins";

.adjustQty {
  &__header {
    @include flex-between();
    margin-bottom: 15px;
  }

  &__addSection {
    display: grid;
    grid-template-columns: repeat(4, 1fr) 1.5fr;
    gap: 15px 20px;
    align-items: end;
    @media (max-width: $lg) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width: $md) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: $sm) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__add-btn {
    @media (max-width: $lg) {
      height: 50px;
    }
  }

  .partQtyTh {
    &--number {
      min-width: 65px;
    }
    &--quantity {
      width: 20%;
      min-width: 95px;
    }
    &--costPrice {
      width: 16.666%;
      min-width: 110px;
    }
    &--sellingPrice {
      width: 16.666%;
      min-width: 125px;
    }
    &--addedDate {
      width: 16.666%;
      min-width: 150px;
    }
    &--purchaseOrderNo {
      width: 16.666%;
      min-width: 235px;
    }
    &--linkedTo {
      width: 16.666%;
      min-width: 180px;
    }
  }

  .ant-divider.ant-divider-horizontal {
    @media (max-width: $lg) {
      margin: 15px 0;
    }
  }
}
