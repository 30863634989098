@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.employments {
  &__addButton {
    margin-bottom: 20px;
  }
}

.employmentLine {
  @include line-link();

  &__job-title {
    @include text-overflow($max-width: 100%);
    font-weight: $font-weight-bold;
  }

  &__tooltip {
    @include tooltip-sm();
  }

  &__tag {
    width: 40px;
    display: inline-block;
    text-align: center;
    padding: 0 10px;
    border: 0;
    border-radius: 8px;
    font-size: $fsz-14;
    font-weight: $font-weight-bold;
    line-height: $fsz-26;
    text-transform: uppercase;
    background-color: $main-dark-blue;
    color: $main-yellow;
    margin: 0 20px 0 10px;
  }

  .dropdownTriggerBtn .fa-ellipsis-vertical {
    transform: translateX(0);
  }

  @media (min-width: 1601px) {
    display: flex;
    align-items: center;

    &__time {
      @include flex-column();
      width: 130px;
      border-right: $border-dark-blue;
    }

    &__info {
      flex: 1;
      min-width: 0;
      display: flex;
      flex-direction: column;
      padding: 0 20px;
      white-space: nowrap;
    }

    &__info-desc {
      display: flex;
      flex-wrap: wrap;
    }

    &__salary {
      margin-right: 4px;
    }
  }

  @media (max-width: 1600px) {
    display: grid;
    grid-template-columns: 1fr auto auto;

    &__info {
      @include flex-column();
      min-width: 0;
      grid-column: 1/-1;
    }

    &__info-desc {
      @include flex-column();
    }

    &__tag {
      grid-column: 2/3;
      grid-row: 1/2;
      margin-right: 10px;
    }

    &__actions {
      grid-column: 3/4;
      grid-row: 1/2;
    }
  }
}

.addEmployment {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;
  @media (max-width: $lg) {
    grid-template-columns: 100%;
  }

  &__field {
    &--wide {
      grid-column: 1/-1;
    }
  }

  &__double {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
}
