@import "src/core/styles/mixins";
@import "src/core/styles/variables";

.accountFinalization {
  width: 100%;
  @include flex-center();

  &__form-wrapper {
    width: 100%;
    max-width: 450px;
    margin: 40px 10px;
    padding: 32px;
    border: $border-main-gray;
    border-radius: 10px;

    @media (max-width: $md) {
      padding: 20px;
    }
  }

  &__logo {
    display: block;
    margin-bottom: 32px;

    @media (max-width: $lg) {
      margin-bottom: 16px;
    }
  }

  &__title {
    margin-bottom: 15px;
    color: $main-dark-blue;

    @media (max-width: $lg) {
      font-size: $fsz-20;
    }
  }

  &__form {
    display: grid;
    gap: 20px;
  }

  &__field {
    position: relative;

    .passwordInput-error,
    .input-error {
      position: absolute;
      top: 99%;
    }

    @media (max-width: $lg) {
      .ant-input {
        min-height: 40px;
        height: 40px;
      }
    }
  }

  &__tooltip {
    font-size: $fsz-14;
    @media (max-width: $md) {
      max-width: 180px;
      font-size: $fsz-12;
      margin: 0 -4px;
    }

    ul {
      margin-bottom: 0;
      padding-left: 0;
    }

    li {
      list-style-type: initial;
      list-style-position: inside;
    }
  }

  &__checkbox {
    @include table-checkbox(sm);
  }

  &__actions {
    margin-top: 16px;

    @media (max-width: $md) {
      margin-top: 8px;
    }
  }
}

.passwordInfo {
  @include flex-column();
  align-items: flex-start;
}
