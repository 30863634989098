@import '/src/core/styles/variables';
@import 'src/core/styles/mixins';

.view-details-tabs-actions {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;
  @media (max-width: $xl) {
    grid-template-columns: 100%;
    gap: 15px;
    margin-bottom: 15px;
  }

  &--large-tabs {
    @media (min-width: $xl + 1) {
      grid-template-columns: 3fr 2fr;
    }
  }

  &__segmented {
    width: 100%;

    .ant-segmented-group {
      justify-content: space-between;

      .ant-segmented-item,
      .ant-segmented-item-label {
        width: 100%;
      }

      .ant-segmented-item-label {
        padding: 14px 10px !important;
      }
    }
  }

  &__selects-wrapper {
    width: 100%;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    @media (max-width: $lg) {
      grid-template-columns: 100%;
      gap: 15px;
    }
  }

  &__select {
    &--highlighted {
      @include highlighted-select-value();
    }
  }

  &:empty {
    margin-bottom: 0;
  }
}
