@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.noteEvent {
  min-width: 120px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px;
  background-color: $color-white;
  border-radius: 10px;
  font-weight: $font-weight-bold;

  .dropdownTriggerBtn {
    margin-left: 5px;
  }

  .dropdownTriggerBtn .fa-ellipsis-vertical {
    transform: translateX(0);
  }

  .ant-dropdown-trigger, .ant-space-item {
    display: flex;
  }
}
