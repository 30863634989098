@import '../../../../../core/styles/variables';

.reorderLevelsModal {
  &__buttons {
    margin-top: 30px;
    @media (max-width: $lg) {
      margin-top: 20px;
    }
  }

  &__form {
    font-size: $fsz-16;
    @media (max-width: $lg) {
      font-size: $fsz-14;
    }
  }

  &__text {
    margin-bottom: 10px;
  }

  &__input-wrapper {
    & + & {
      margin-top: 10px;
    }
  }
}
