@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.partRequestChangesTable {
  margin: 0 -24px;
  padding: 0 24px;

  @media (max-width: $lg) {
    position: static;
    margin: 0 -16px;
    padding: 0 16px;
  }

  .highlight-new {
    color: $main-yellow;
    font-weight: $font-weight-bold;

    &.updated {
      .table-new-td {
        padding-top: 0;
      }
    }
  }

  .hovered {
    background-color: $main-light-gray;
  }

  .crossed {
    text-decoration: line-through;
  }

  .light-gray,
  .crossed {
    color: darken($main-light-gray, 25%);

    .tag {
      background-color: darken($main-light-gray, 25%);
      color: $color-white;
      text-decoration: line-through;

      &.linePriceTag {
        position: relative;
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 7px;
          right: 7px;
          height: 1px;
          background-color: $color-white;
        }
      }
    }
  }

  .activity-type {
    color: $main-dark-blue;
    font-weight: $font-weight-regular;

    svg {
      margin-left: 5px;
    }
  }

  .new-sources {
    .tag {
      background-color: $main-yellow;
      color: $main-dark-blue;
    }
  }

  .status {
    svg {
      margin: 0 8px;
    }
  }
}

.partRequestChangesTh {
  &--activity {
    min-width: 115px;
  }
  &--number {
    min-width: 60px;
  }
  &--category {
    min-width: 120px;
    width: 10%;
  }
  &--productNumber {
    min-width: 140px;
    width: 10%;
  }
  &--productName {
    min-width: 145px;
    width: 10%;
  }
  &--notes {
    min-width: 100px;
    width: 10%;
  }
  &--quantity {
    min-width: 95px;
    width: 10%;
  }
  &--unitPrice {
    min-width: 125px;
    width: 10%;
  }
  &--priceSources {
    min-width: 125px;
  }
  &--discount {
    min-width: 90px;
    width: 10%;
  }
  &--netPrice {
    min-width: 125px;
    width: 10%;
  }
  &--tax {
    min-width: 65px;
    width: 10%;
  }
  &--weight {
    min-width: 105px;
    width: 10%;
  }
  &--status {
    min-width: 130px;
  }
}
