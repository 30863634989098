@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.employeesHolidays {
  &__year {
    width: 150px;
    margin-bottom: 15px;
  }

  &__actions {
    display: grid;
    grid-template-columns: 5fr 3fr 3fr;
    gap: 10px 20px;
    @media (max-width: $md) {
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: $sm) {
      grid-template-columns: 100%;
    }
  }

  &__add-btn {
    transform: translateY(22px);
    @media (min-width: $md + 1) and (max-width: $lg) {
      height: 50px;
    }
    @media (max-width: $md) {
      grid-column: 1/-1;
      transform: translateY(0);
    }
  }
}

.employeesHolidaysTh {
  &--number {
    min-width: 70px;
  }
  &--name {
    width: 55%;
    min-width: 200px;
  }
  &--date {
    width: 45%;
    min-width: 120px;
  }
}
