@import "../../../../core/styles/variables";
@import "../../../../core/styles/mixins";

.invoicingSummary {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 25px;

  @media (max-width: $lg) {
    grid-template-columns: 100%;
  }

  &__col {
    background-color: $color-gray;
    border-radius: 10px;
    padding: 15px;
    @media (max-width: $lg) {
      padding: 10px;
    }
  }

  &__title {
    width: 100%;
    @include summaryTitle();

    @media (max-width: $lg) {
      margin-bottom: 10px;
    }
  }

  &__data {
    @include list-unstyled();
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-auto-flow: column;

    @media (min-width: $lg + 1) and (max-width: 1400px) {
      grid-template-rows: repeat(4, auto);
      grid-template-columns: 100%;
    }
    @media (max-width: $md) {
      grid-template-rows: repeat(4, auto);
      grid-template-columns: 100%;
    }
  }

  &__line {
    @include flex-between();
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: $fsz-14;

    &:nth-child(1),
    &:nth-child(2) {
      padding-right: 10px;
      border-right: $border-dark-blue;
    }

    &:nth-child(3),
    &:nth-child(4) {
      padding-left: 10px;
    }

    @mixin disable-lines-styles() {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        padding-left: 0;
        padding-right: 0;
        border-right: 0;
      }
    }

    @media (min-width: $lg + 1) and (max-width: 1400px) {
      @include disable-lines-styles();
    }
    @media (max-width: $md) {
      @include disable-lines-styles();
    }
  }

  &__icon {
    margin-left: 3px;
  }
}
