@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.pricelistPricesTh {
  &--categoryId {
    width: 12%;
    min-width: 200px;
  }
  &--subcategoryId {
    width: 12%;
    min-width: 220px;
  }
  &--productNumber {
    width: 12%;
    min-width: 200px;
  }
  &--productName {
    width: 20%;
    min-width: 300px;
  }
}
