@import "src/core/styles/variables";

.customerViewPageDetailsHead {
  @media (max-width: $lg) {
    height: 180px;
  }

  .head__inner-info-item {
    @media (min-width: $lg + 1) {
      &:nth-child(2) {
        grid-column: 2/4
      }

      &:nth-child(3) {
        grid-column: 4/5
      }
    }
  }
}
