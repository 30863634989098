@import "src/core/styles/mixins";
@import "src/core/styles/variables";

.stockVehicles {
  &__label {
    font-size: $fsz-14;
    color: #3A4557;
  }

  &__vehicles {
    @include scroll();
    @include list-unstyled();
    @include flex-column();
    min-height: 120px;
    max-height: 350px;
    overflow: auto;
    padding: 15px;
    background-color: #ffffff;
    border-radius: 10px;
    border: $border-dark-gray;
  }

  &__button {
    .svg-inline--fa {
      margin-right: 10px;
    }
  }
}
