@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.dashboardFilters {
  @include flex-column();

  &.dashboardFiltersHidden {
    display: none;
  }

  &__head {
    @include flex-between();
    flex-wrap: wrap;
    margin: -5px 0;
    & > * {
      &:nth-child(1),
      &:nth-child(2) {
        margin: 5px 0;
      }
      &:nth-child(1) {
        margin-right: 10px;
      }
    }
  }

  &__extra-actions {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    column-gap: 15px;

    @media (max-width: $lg) {
      column-gap: 5px;
    }
  }

  &__action {
    &--active {
      @include button-badge();
    }
  }

  .keywordFiltersSection {
    @media (max-width: $lg) {
      margin-top: 10px;
    }
  }

  &--expandable-filters {
    .keywordFiltersSection {
      margin-top: 20px;
      @media (max-width: $lg) {
        margin-top: 15px;
      }
    }
  }
}
