@import "./src/core/styles/mixins";
@import "./src/core/styles/variables";

.comment {
  margin: 10px 0;
  margin-bottom: 24px;

  .ant-input.input-field {
    @include form-input();
  }
}

.fileItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}