@import "src/core/styles/mixins";
@import "src/core/styles/variables";

.expandedDetails {
  &__main {
    @include flex-column();
  }

  &__info {
    @include flex-center();
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: $border-dark-gray;

    @media (max-width: $lg) {
      margin-bottom: 10px;
      padding-bottom: 10px;
    }
    @media (max-width: $md) {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
  }

  &__info-data {
    @include flex-column();
    max-width: 100%;
    flex: 1;
    min-width: 0;
    margin-right: 15px;
    font-size: $fsz-16;

    @media (max-width: $md) {
      margin-top: 5px;
      margin-right: 0;
    }
  }

  &__account-number {
    color: $dark-gray;
  }

  &__customer-name {
    @include text-overflow($max-width: 100%);
    font-weight: $font-weight-bold;
  }

  &__fields {
    display: grid;
    align-content: start;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 15px;
    border-top: $border-dark-gray;
    margin-top: 15px;
    padding-top: 15px;

    @media (max-width: $lg) {
      border-bottom: $border-dark-gray;
      margin-top: 10px;
      margin-bottom: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    @media (max-width: $md) {
      gap: 10px 15px;
    }
  }
}

.expandedField {
  @include flex-column();

  &--wide {
    grid-column: 1/-1;
  }

  &__value {
    font-weight: $font-weight-bold;
  }
}
