@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.distributeQtyTh {
  &--checkbox {
    min-width: 60px;
    width: 60px;
    max-width: 60px;
  }
  &--name {
    width: 100%;
    min-width: 220px;
  }
}

.distributeQtySummary {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 20px;
}

.distributeQtyTotal {
  @include flex-column();
  padding: 8px;
  background-color: $color-gray;
  border-radius: 10px;

  &__title {
    margin-bottom: 6px;
    font-size: 16px;
  }

  &__list {
    @include list-unstyled();
  }

  &__list-line {
    @include flex-between();
    color: $main-dark-blue;
    font-weight: $font-weight-regular;

    &:not(:last-of-type) {
      margin-bottom: 4px;
    }
  }
}

.distributeQtyLine {
  &__input {
    width: 80px;
    margin: 0 auto;
    .input-field {
      height: 32px;
      min-height: 32px;
      text-align: center;
    }
  }
}


