@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.customRadioGroup {
  @include flex-column();
  &-label {
    @include field-label();
    margin-bottom: 2px;
  }

  &-error {
    @include error-message();
  }

  .ant-radio-button-wrapper {
    min-width: 100px;
    text-align: center;
    height: 40px;
    padding: 0 18px;
    line-height: 38px;
    color: $main-dark-blue;
    border: $border-dark-gray;
    border-radius: 6px;
    font-weight: $font-weight-bold;

    &:not(:last-of-type) {
      margin-right: 15px;
    }
    &:not(:first-child)::before {
      display: none;
    }
    &:hover {
      background-color: $main-dark-blue;
      color: $color-white;
    }
    &:focus-within {
      box-shadow: 0 0 0 3px rgba($main-dark-blue, 0.15)
    }

    &.ant-radio-button-wrapper-disabled {
      background-color: $main-light-gray;
      color: $accent-gray;
      border-color: $accent-gray;
    }

    &.ant-radio-button-wrapper-checked {
      background-color: $main-dark-blue;
      border-color: $main-dark-blue;
      color: $main-yellow;

      &.ant-radio-button-wrapper-disabled {
        background-color: $dark-gray;
        border-color: $dark-gray;
        color: $color-white;
      }

      &:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
        border-right-color: $main-dark-blue;
      }
    }
  }
}
