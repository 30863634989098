@import "../../core/styles/variables";
@import "../../core/styles/mixins";

.stockFilters {
  @media (max-width: $lg) {
    &:not(:empty) {
      margin-bottom: 15px;
    }
  }
}

