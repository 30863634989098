@import "./src/core/styles/mixins";
@import "./src/core/styles/variables";

.input {
  @include flex-column();

  .input-label {
    @include field-label();
  }

  .input-field {
    @include form-input();
    &:read-only {
      border-color: $main-gray !important;
      &:focus {
        border-color: $main-gray !important;
      }
    }

    &.ant-input-disabled,
    &:disabled,
    &:read-only {
      @include form-disabled();
    }

    &:read-only {
      cursor: auto;
      pointer-events: auto !important;
    }
  }

  .input-error {
    @include error-message();
  }
}
