.cancelledLinesTh {
  &--number {
    min-width: 70px;
  }
  &--customerName {
    min-width: 150px;
  }
  &--entityNumber {
    min-width: 200px;
  }
  &--categoryName {
    min-width: 150px;
  }
  &--partCode {
    min-width: 180px;
  }
  &--partName {
    min-width: 200px;
  }
  &--quantity {
    min-width: 110px;
  }
  &--activityType {
    min-width: 115px;
  }
  &--actionInitiator {
    min-width: 180px;
  }
  &--actionReason {
    min-width: 140px;
  }
  &--actionDate {
    min-width: 160px;
  }
}
