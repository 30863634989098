@import '../../../../core/styles/variables';

.addNominalCodeForm {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 10px 15px;

  @media (max-width: $lg) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (max-width: $sm) {
    grid-template-columns: 100%;
  }

  &__radio,
  &__select,
  &__buttons {
    grid-column: 1/-1;
  }
}
