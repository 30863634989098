.rectificationsByProductReportTh {
  &--number {
    min-width: 70px;
  }
  &--category {
    width: 20%;
    min-width: 135px;
  }
  &--subcategory {
    width: 20%;
    min-width: 165px;
  }
  &--partCode {
    width: 24%;
    min-width: 165px;
  }
  &--partName {
    width: 24%;
    min-width: 180px;
  }
  &--usedInRectifications {
    width: 12%;
    min-width: 260px;
  }
}
