@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.ant-slider {
    &:hover {
        .ant-slider-track {
            background-color: $main-dark-blue;
        }
        .ant-slider-handle:not(.ant-tooltip-open) {
            border-color: $main-dark-blue;
        }
        .ant-slider-rail {
            background-color: rgba($main-dark-blue, 0.5);
        }
        .ant-slider-dot:not(.ant-slider-dot-active) {
            border-color: rgba($main-dark-blue, 0.5);
        }
    }
}
.ant-slider-track {
    background-color: $main-dark-blue;
}



.ant-slider-handle,
.ant-slider-handle.ant-tooltip-open {
    border-color: $main-dark-blue;
}

.ant-slider-handle:focus {
    border-color: $main-dark-blue;
    box-shadow: 0 0 0 5px rgba($main-dark-blue, 0.12)
}

.slider-label {
    @include field-label();
}

.ant-slider-rail {
    background-color: rgba($main-dark-blue, 0.25);
}

.ant-slider-dot {
    transition: border-color 0.3s;
    border-color: rgba($main-dark-blue, 0.25);
}

.ant-slider-dot-active {
    border-color: $main-dark-blue;
}
