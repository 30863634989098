@import "src/core/styles/mixins";
@import "src/core/styles/variables";

.checkbox {
  transition: $transition;

  &__tooltip {
    @include tooltip-sm();
  }

  &-label {
    @include field-label();
    &--truncated {
      @include text-overflow($max-width: 100%)
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $main-dark-blue;
    border-color: $main-dark-blue;
  }

  .ant-checkbox-checked::after {
    border-color: $main-dark-blue;
  }

  .ant-checkbox-inner::after {
    left: 25%;
  }

  .ant-checkbox-disabled {
    .ant-checkbox-inner {
      background-color: rgba(0, 0, 0, .2);
    }
  }

  &.checkbox--table {
    @include table-checkbox();
    .ant-checkbox + span {
      display: none;
    }
    .ant-checkbox-wrapper {
      transform: none;
    }

    .ant-checkbox-indeterminate .ant-checkbox-inner::after {
      left: 50%;
      width: 65%;
      height: 65%;
      border-radius: 3px;
      background-color: $main-dark-blue;
    }
  }
}

.ant-checkbox-group {
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $main-dark-blue;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $main-dark-blue;
    border-color: $main-dark-blue;
  }

  .ant-checkbox-checked::after {
    border-color: $main-dark-blue;
  }
}

