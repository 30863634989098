.numberOfConvertedVehiclesReportTh {
  &--number {
    min-width: 70px;
  }
  &--businessArea {
    width: 50%;
    min-width: 170px;
  }
  &--jobType {
    width: 50%;
    min-width: 150px;
  }
  &--convertedVehicles {
    min-width: 240px;
  }
}
