@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.dashboardCardSkeleton {
    width: 278px;
    border-radius: 10px;
    background-color: $color-white;
    border: $border-main-gray;

    @media (max-width: $lg) {
        width: 260px;
    }

    & + & {
        margin-top: 15px;
    }

    &__header {
        @include flex-between();
        padding: 3px;
    }

    &__label {
        height: 26px;
        border-radius: 8px;

        &--left {
            width: 60px;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }

        &--right {
            width: 105px;
        }
    }

    &__name {
        width: 130px;
        height: 16px;
        margin: 17px auto 15px;
    }

    &__info {
        width: 130px;
        height: 22px;
        margin-bottom: 10px;
        margin-left: 10px;
    }

    &__price {
        width: 70px;
        height: 22px;
        margin-bottom: 16px;
        margin-left: 10px;
    }

    &__entities {
        padding: 8px;
    }

    &__linked {
        height: 30px;
        margin-bottom: 8px;
        border-radius: 10px;
    }

    &__tracking-wrap {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 8px;
        margin-bottom: 8px;
        padding: 0 8px;
    }

    &__tracking-item {
        @include flex-column();
        justify-content: center;
        align-items: center;
        height: 48px;
        border: $skeleton-border;
        border-radius: 10px;
    }

    &__tracking-title {
        height: 11px;
        width: 50px;
        margin-bottom: 5px;
    }

    &__tracking-value {
        height: 11px;
        width: 35px;
    }

    &__footer {
        @include flex-end();
        padding: 6px;
        position: relative;

        &::after {
            content: "";
            display: block;
            position: absolute;
            height: 1px;
            top: 0;
            right: 3px;
            left: 3px;
            background-color: $dark-gray;
        }
    }

    &__icon {
        width: 24px;
        height: 24px;
        border-radius: 50%;

        & + & {
            margin-left: 5px;
        }
    }
}
