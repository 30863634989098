@import "src/core/styles/mixins";
.createCategoryInput {
  margin-bottom: 20px;
}

.skillsCategories {
  margin-top: -5px;
  .ant-tabs-nav {
    position: sticky;
    z-index: 1;
    top: 165px;
    margin-bottom: 5px;
    margin-right: -20px;
    margin-left: -20px;
    padding: 5px 20px 15px;
    @media (max-width: $lg) {
      top: 135px;
      margin-right: -10px;
      margin-left: -10px;
      padding-right: 10px;
      padding-left: 10px;
    }
  }
}

.skillsTableTh {
  &--item {
    width: 30%;
    min-width: 250px;
  }
  &--description {
    width: 40%;
    min-width: 300px;
  }
  &--createDate,
  &--modifiedDate {
    width: 15%;
    min-width: 170px;
  }
}

.affectedEmployeeSkillTh {
  &--no {
    width: 60px;
    min-width: 60px;
  }
  &--firstName,
  &--lastName {
    width: 25%;
    min-width: 130px;
  }
  &--email {
    width: 50%;
    min-width: 150px;
  }
}
