@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.employeeWorkCalendarWrap {
    @include scroll();
    flex: 1;
    overflow: auto;
    margin-bottom: -20px;
    margin-right: -20px;

    @media (max-width: $lg) {
        margin-left: -10px;
        margin-right: -10px;
        margin-bottom: -10px;
    }
}

.employeeWorkCalendar {
    display: grid;
    grid-template-columns: 300px repeat(7, 200px) 60px;
    width: calc(300px + (7 * 200px) + 60px);
    margin-bottom: 8px;

    @media (max-width: $lg) {
        grid-template-columns: 240px repeat(7, 150px) 50px;
        width: calc(240px + (7 * 150px) + 50px);
    }

    @media (max-width: $sm) {
        grid-template-columns: 145px repeat(7, 105px) 55px;
        width: calc(145px + (7 * 105px) + 55px);
    }

    &__col-head {
        position: sticky;
        top: 0;
        padding: 7px 5px;
        background-color: $color-white;
        z-index: 1;

        &:not(.col-fixed-left):not(.col-fixed-right) {
            @include flex-column();
            align-items: center;
        }

        &.col-fixed-left {
            z-index: 2;
            text-align: right;
        }
    }

    .empty-content {
        padding: 0;
        margin: 30px 0;

        &__icon {
            width: 70px;
            height: 70px;
        }
    }
}

.workCalendarHeader {
    &__date {

        @media (max-width: $sm) {
            font-size: $fsz-14;
        }
    }

    &__day {
        color: $dark-gray;
    }

    &__date-skeleton {
        width: 100px;
        height: 16px;
        margin-bottom: 5px;
        background-color: darken($skeleton-color, 5%);
        @media (max-width: $sm) {
            width: 75px;
        }
    }

    &__day-skeleton {
        width: 50px;
        height: 16px;
        background-color: darken($skeleton-color, 5%);
        @media (max-width: $sm) {
            width: 45px;
        }
    }
}

.workCalendarLine {
    &__col {
        padding: 7px 5px;
        background-color: white;
        border-right: $border-main-gray;
        transition: background-color $transition;

        &.col-fixed-left {
            @include flex-column();
            justify-content: center;
            @media (max-width: $lg) {
                font-size: $fsz-14;
            }
        }
    }

    &__name {
        @include text-overflow($max-width: 100%);
        font-weight: $font-weight-bold;
    }

    &__role {
        @include text-overflow($max-width: 100%);
        color: $dark-gray;
    }

    &__pagination {
        &.col-fixed-left {
            bottom: 0;
            flex-direction: row;
            align-items: center;
        }
        span {
            margin: 0 10px;
            @media (max-width: $sm) {
                margin: 0 5px;
            }
        }

        .customButton.ant-btn-icon-only {
            width: 40px;
            height: 40px;

            @media (max-width: $md) {
                width: 30px;
                height: 30px;
            }

            & > svg {
                font-size: $fsz-20;
                @media (max-width: $md) {
                    font-size: $fsz-16;
                }
            }
        }
    }
}

.workingHoursCard {
    position: relative;
    @include flex-center();
    width: 130px;
    height: 90px;
    margin: 0 auto;
    border: $border-accent-gray;
    background-color: transparent;
    border-radius: 10px;
    font-weight: $font-weight-bold;
    cursor: pointer;
    transition: border-color $transition;

    @media (max-width: $lg) {
        width: 110px;
        height: 80px;
        font-size: $fsz-14;
    }

    @media (max-width: $sm) {
        width: 85px;
        height: 65px;
    }

    &:hover {
        border-color: $main-dark-blue;
    }

    &--na {
        border-color: transparent;
    }

    &--striped {
        border-color: $main-light-gray-100;
        background-image: $gray-stripes;
    }

    &--dark {
        border-color: $main-light-gray-100;
        background-color: $main-light-gray-100;
    }

    &__tag {
        position: absolute;
        top: 3px;
        right: 3px;
        padding: 0;
        line-height: 20px;

        span {
            padding: 0 10px;
        }

        @media (max-width: $sm) {
            font-size: $fsz-10;
            line-height: 18px;
            top: 2px;
            right: 2px;
        }
    }

    &__tooltip {
        @include tooltip-sm();
    }
}

.col-fixed-left {
    z-index: 1;
    position: sticky;
    left: 0;
    grid-column: 1/2;
}

.col-fixed-right {
    z-index: 1;
    position: sticky;
    right: 0;
    grid-column: -1/-2;
}

.col-hovered {
    background-color: lighten($main-light-gray, 1%);
}
