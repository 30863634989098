@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.termsOfUseLink {
  font-size: $fsz-14;
  color: currentColor;
  text-decoration: underline;

  @include hover-focus-active() {
    color: currentColor;
    text-decoration: underline;
  }
}
