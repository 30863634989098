@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.partPurchases {
  &__pagination {
    margin-bottom: 20px;
  }
}

.purchaseItem {
  @include line-link();
  align-items: center;
  display: grid;
  grid-template-columns: 160px 1fr auto;

  @media (max-width: $md) {
    grid-template-columns: 1fr auto;
    row-gap: 10px;
  }

  &__title {
    display: flex;
    align-items: center;
    padding-right: 20px;
    @media (max-width: $md) {
      order: 1;
      padding-right: 0;
    }
  }

  &__icon {
    width: 30px;
    height: 30px;
    margin-right: 20px;

    @media (max-width: $md) {
      width: 24px;
      height: 24px;
    }
  }

  &__id {
    font-size: $fsz-16;
    font-weight: $font-weight-bold;
  }

  &__info {
    @include flex-column();
    padding: 0 20px;
    border-left: 1px solid $main-dark-blue;
    @media (max-width: $md) {
      order: 3;
      grid-column: 1/-1;
      padding: 0;
      border-left: 0;
    }
  }

  &__supplier {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
  }

  &__supplier-icon {
    font-size: $fsz-16;
  }

  &__supplier-name {
    @include text-overflow($max-width: 100%);
    margin-left: 10px;
    font-weight: $font-weight-bold;
  }

  &__tag {
    margin-left: 10px;
    @media (max-width: $md) {
      order: 2;
    }
  }
}
