@import '../../../../core/styles/variables';
@import '../../../../core/styles/mixins';

.invoicingSkeletonItem {
  height: 69px;
  display: grid;
  grid-template-columns: auto 520px 3fr 2fr 160px;

  @media (max-width: $xl) {
    grid-template-columns: auto auto 5fr 3fr 160px;
  }

  @media (max-width: 1400px) {
    height: 66px;
  }

  @media (max-width: $lg) {
    @include flex-start();
    align-content: space-between;
    flex-wrap: wrap;
    height: 208px;
  }

  @media (max-width: $sm) {
    height: 198px;
  }

  &__icon {
    width: 30px;
    height: 30px;
    align-self: center;
    margin-right: 25px;
    background-color: $skeleton-color;
    border-radius: 4px;

    @media (max-width: $lg) {
      order: -2;
    }
  }

  &__data {
    @include flex-column();

    padding: 0 8px;

    @media (max-width: $sm) {
      padding: 0 5px;
    }

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }

    &::before,
    &::after {
      content: '';
      display: block;
      background-color: $skeleton-color;
    }

    &::before {
      height: 14px;
      width: 50px;
      margin-bottom: 5px;

      @media (max-width: $md) {
        margin-bottom: 3px;
      }
    }

    &::after {
      height: 16px;
      width: 80px;
    }
  }

  &__info {
    display: grid;
    grid-template-columns: 2fr 3fr;
    align-items: center;
    padding: 0 15px 0 0;

    @media (max-width: $xl) {
      grid-template-columns: 140px 160px;
    }

    @media (max-width: 1400px) {
      grid-template-columns: 140px 120px;
    }

    @media (max-width: $lg) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__price {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    align-items: center;
    padding: 0 15px;
    border-left: $skeleton-border;

    @media (max-width: $lg) {
      margin: 5px 0;
      padding: 5px 0;
      border-top: $skeleton-border;
      border-bottom: $skeleton-border;
    }
  }

  &__entities {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: 0 15px;
    border-left: $skeleton-border;
  }

  &__info,
  &__price,
  &__entities {
    @media (max-width: $lg) {
      width: 100%;
      padding-right: 0;
      padding-left: 0;
      border-left: 0;
    }
  }

  &__tag-wrapper {
    align-self: center;
    justify-self: end;

    @media (max-width: $lg) {
      order: -1;
      margin-left: auto;
    }
  }

  &__tag {
    height: 26px;
    width: 100px;
    border-radius: 8px;
  }
}
