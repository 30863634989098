.fitterReturnsTh {
  &--number {
    min-width: 65px;
  }
  &--customerName {
    width: 10%;
    min-width: 135px;
  }
  &--entityNumber {
    width: 10%;
    min-width: 135px;
  }
  &--category {
    width: 10%;
    min-width: 135px;
  }
  &--partCode {
    width: 10%;
    min-width: 160px;
  }
  &--partName {
    width: 13%;
    min-width: 170px;
  }
  &--quantity {
    width: 7%;
    min-width: 60px;
  }
  &--deletionInitiator {
    width: 10%;
    min-width: 190px;
  }
  &--deletionReason {
    width: 10%;
    min-width: 185px;
  }
  &--deletionDate {
    width: 10%;
    min-width: 165px;
  }
  &--deletionConfirmed {
    width: 10%;
    min-width: 212px;
  }
}
