@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.purchaseOrderViewPageDetailsHead {
  .head__inner-info-item:nth-child(3) {
    @media (min-width: $lg + 1) {
      grid-column: 3/5;
    }
  }
  @media (max-width: $lg) {
    height: 180px;
  }
}

.purchaseOrderContentTabs {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;
  @media (max-width: $xl) {
    grid-template-columns: 100%;
  }
  &__segmented {
    justify-self: start;
  }
  &__actions {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 15px 20px;
    @media (max-width: $lg) {
      grid-template-columns: 100%;
      margin-bottom: 15px;
    }
  }
  &__select-highlighted {
    @include highlighted-select-value();
  }
}

.finalizedToSentWrap {
  @include flex-column();
}
