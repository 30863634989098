.invoicesAndCreditNotesTh {
  &--number {
    min-width: 65px;
  }
  &--entityNumber {
    width: 15%;
    min-width: 140px;
  }
  &--type {
    width: 10%;
    min-width: 110px;
  }
  &--xeroRef {
    width: 15%;
    min-width: 120px;
  }
  &--invoiceDate {
    width: 10%;
    min-width: 140px;
  }
  &--dueDate {
    width: 10%;
    min-width: 140px;
  }
  &--net {
    width: 13.333%;
    min-width: 125px;
  }
  &--vat {
    width: 13.333%;
    min-width: 125px;
  }
  &--gross {
    width: 13.333%;
    min-width: 125px;
  }
  &--status {
    min-width: 120px;
  }
}
