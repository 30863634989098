@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.addInvoiceModal {
  &__fields {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    column-gap: 20px;
    margin-bottom: 20px;

    @media (max-width: $lg) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      row-gap: 10px;
    }

    @media (max-width: $sm) {
      grid-template-columns: 100%;
      row-gap: 5px;
    }
  }
}

.addInvoiceTotal {
  @include list-unstyled();
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  margin-top: 20px;
  margin-bottom: 0;
  padding: 10px 0;
  border-radius: 10px;
  text-align: center;
  background-color: $color-gray;

  @media (max-width: $lg) {
    grid-template-columns: 100%;
    padding: 5px 10px;
  }

  &__item {
    @include flex-between();
    flex-wrap: wrap;
    padding: 0 15px;
    border-right: $border-dark-blue;

    @media (max-width: $lg) {
      grid-template-columns: 100%;
      border-right: 0;
      border-top: $border-dark-blue;
      padding: 5px 0;

      &:first-of-type {
        border-top: 0;
      }
    }

    &:last-of-type {
      border-right: 0;
    }
  }
}

.addInvoiceNominalCodesTh {
  &--number {
    min-width: 60px;
  }
  &--productNumber {
    width: 17%;
    min-width: 145px;
  }
  &--productName {
    width: 19%;
    min-width: 170px;
  }
  &--nominalCodeIds {
    min-width: 100px;
  }
  &--description {
    width: 26%;
    min-width: 140px;
  }
  &--net {
    width: 19%;
    min-width: 100px;
  }
  &--vat {
    width: 19%;
    min-width: 100px;
  }
  &--state {
    min-width: 110px;
  }
}
