.fullStockReportTh {
  &--number {
    min-width: 70px;
  }
  &--category {
    width: 11%;
    min-width: 135px;
  }
  &--subcategory {
    width: 11%;
    min-width: 160px;
  }
  &--partCode {
    width: 11%;
    min-width: 160px;
  }
  &--partName {
    width: 13%;
    min-width: 160px;
  }
  &--free {
    width: 6%;
    min-width: 90px;
  }
  &--freeWithoutSellingPrices {
    width: 10%;
    min-width: 280px;
  }
  &--reserved {
    width: 10%;
    min-width: 130px;
  }
  &--allocated {
    width: 10%;
    min-width: 130px;

  }
  &--picked {
    width: 9%;
    min-width: 110px;

  }
  &--collected {
    width: 9%;
    min-width: 130px;

  }
  &--dueIn {
    width: 9%;
    min-width: 110px;
  }
}
