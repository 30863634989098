@import "src/core/styles/mixins";
@import "src/core/styles/variables";

.detailsActivityWrap {
  &__activity {
    &-inner-log {
      padding-top: 12px !important;
      margin-bottom: 24px !important;
      @media (max-width: $lg) {
        margin-bottom: 10px !important;
      }
    }
  }

  .ant-pagination {
    text-align: right;
    margin-bottom: 16px;
  }
}
