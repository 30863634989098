@import "src/core/styles/variables";

.missingQuoteLinesModal {
  &__content {
    display: grid;
    gap: 15px;
  }

  &__text {
    @media (min-width: $lg + 1) {
      font-size: $fsz-16;
    }
  }
}

.missingQuoteLinesTh {
  &--entity {
    width: 15%;
    min-width: 85px;
  }
  &--productNumber {
    width: 25%;
    min-width: 120px;
  }
  &--productName {
    width: 40%;
    min-width: 130px;
  }
  &--quantity {
    width: 20%;
    min-width: 80px;
  }
}
