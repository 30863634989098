@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.rectificationViewPageHead {
  @include head-info-full();
}

.rectificationDetailsSection {
  &__xero-id {
    grid-column: 1/2;
  }
}
