@import "src/core/styles/mixins";
@import "src/core/styles/variables";

.empty-content {
  @include flex-column();
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding-top: 100px;
  color: $main-dark-blue;

  @media (max-width: $lg) {
    padding-top: 50px;
  }

  &__icon {
    height: 100px;
    width: 100px;
    margin-bottom: 24px;
    @media (max-width: $lg) {
      height: 60px;
      width: 60px;
      margin-bottom: 10px;
    }

    path {
      fill: currentColor;
    }
  }

  &__text {
    font-size: $fsz-16;
    font-weight: $font-weight-bold;
    @media (max-width: $lg) {
      font-size: $fsz-14;
    }
  }

  &--sm {
    .empty-content__icon {
      height: 35px;
      width: 35px;
    }
  }
  &--no-offset-top {
    padding-top: 0;
  }
}
