@import 'src/core/styles/mixins';
@import 'src/core/styles/variables';

.attachmentsTableWrap {
  scroll-behavior: auto;

  .ant-switch:not(.ant-switch-disabled):active.ant-switch-checked .ant-switch-handle::before {
    right: 0;
    left: -0%;
  }
  .ant-switch:not(.ant-switch-disabled):active .ant-switch-handle::before {
    right: -0%;
    left: 0;
  }
}

.attachmentsTh {
  &--name {
    width: 30%;
    min-width: 250px;
  }
  &--size {
    width: 15%;
    min-width: 150px;
  }
  &--uploadDate {
    width: 15%;
    min-width: 190px;
  }
  &--showInLinkedJobs{
    min-width: 190px;
  }
  &--comment {
    width: 40%;
    min-width: 260px;
  }
  &--extra-actions {
    width: 50px;
    text-align: right;
  }
}

.attachmentsTd {
  &__file {
    @include link();
    display: inline-flex;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
  }

  &__file-icon {
    margin-right: 12px;
    font-size: $fsz-18;
    transform: translateY(2px);
    @media (max-width: $lg) {
      margin-right: 8px;
    }
  }

  &--size,
  &--uploadDate {
    white-space: nowrap;
  }
}
