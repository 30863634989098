@import "./src/core/styles/variables";
@import "./src/core/styles/mixins";

.duplicateVehicle {
  @include flex-column();
  font-size: $fsz-16;
  @media (max-width: $lg) {
    font-size: $fsz-14;
  }

  &__text {
    margin-bottom: 10px;
  }
  &__input {
    max-width: 150px;
    margin-bottom: 18px;
    .regularInputNumber-field {
      @include form-input();
    }
  }

  &__checkbox {
    @include table-checkbox();
    margin-bottom: 18px !important;

    .ant-checkbox-inner {
      width: 20px;
      height: 20px;

      &::after {
        width: 6px !important;
        height: 10px !important;
        border-width: 2px !important;
      }
    }

    &.ant-checkbox-wrapper:hover .ant-checkbox-inner {
      border: $border-dark-blue;
    }
  }
}
