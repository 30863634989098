@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.copyLine {
  display: grid !important;
  grid-template-columns: repeat(2, 1fr) !important;
  max-height: 200px !important;
  overflow: auto !important;
  @include scroll(4px);
  margin-bottom: 24px !important;
  [class*=ant-checkbox-wrapper] {
    margin-left: 0 !important;
  }
}
