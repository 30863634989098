@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.jobListExtraFilters {
  @include flex-between();
  flex-wrap: wrap;
  padding-bottom: 20px;
  @media (min-width: $lg + 1) {
    & > * {
      margin: 5px 10px 5px 0;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  @media (max-width: $lg) {
    padding-bottom: 15px;
  }

  .selectable--sm {
    width: 150px;
  }
}

.jobLine {
  @include line-link();
  @media (max-width: $md) {
    padding: 10px;
  }

  &__inner {
    display: grid;
    grid-template-columns: 50px 750px 1fr auto 50px;
    @media screen and (max-width: 1599px) {
      grid-template-columns: 50px 660px 1fr auto 50px;
    }
    @media screen and (max-width: 1399px) {
      grid-template-columns: 50px 3fr 2fr 50px;
    }
    @media screen and (max-width: $md) {
      grid-template-columns: auto 1fr auto 45px;
    }
  }

  &__icon {
    align-self: center;
    width: 35px;
    height: 35px;
    @media (max-width: $lg) {
      width: 30px;
      height: 30px;
    }
    @media (max-width: $md) {
      width: 24px;
      height: 24px;
    }
    path {
      fill: $main-dark-blue;
    }
  }

  &__button.ant-btn-icon-only {
    width: 38px;
    height: 38px;
    align-self: center;
    border: 0;
  }

  &__button--start.ant-btn-icon-only {
    border-radius: 50%;

    & > svg {
      font-size: $fsz-38;
      @media (max-width: $md) {
        font-size: $fsz-32;
      }
    }

    @media (max-width: $md) {
      width: 32px;
      height: 32px;
    }
  }

  &__button--expand.ant-btn-icon-only {
    justify-self: end;

    &:not(:disabled) {
      color: $main-dark-blue;
    }

    & > svg {
      font-size: $fsz-28;
    }
  }

  &__info {
    @include list-unstyled();
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    border-right: $border-dark-blue;
    @media screen and (max-width: 1399px) {
      @include flex-column();
    }
    @media (max-width: $md) {
      border-right: 0;
    }
  }

  &__info-item {
    @include flex-column();
    padding: 0 20px;
    @media screen and (max-width: 1399px) {
      display: grid;
      grid-template-columns: 140px 1fr;
    }
    @media (max-width: $md) {
      grid-template-columns: 100%;
      padding: 0 10px;
    }
  }

  &__info-label {
    @media (max-width: $md) {
      display: none;
    }
  }

  &__info-value {
    @include text-overflow($max-width: 100%);
    font-size: $fsz-15;
    font-weight: $font-weight-bold;

    @media (max-width: $md) {
      display: none;
      &--number {
        display: block;
        font-size: $fsz-12;
        font-weight: $font-weight-regular;
      }

      &--description {
        display: block;
        font-size: $fsz-14;
      }
    }

    &--categories {
      display: flex;
      width: 100%;
      white-space: nowrap;
    }
  }

  &__first-category {
    @include text-overflow($width: auto, $max-width: 100%);
    margin-right: 5px;
  }

  &__description {
    @include text-overflow($max-width: 100%);
    align-self: center;
    padding: 0 20px;
    font-size: $fsz-16;
  }

  &__additional-data {
    @include flex-center();
    @media (max-width: 1400px) {
      justify-content: flex-end;
    }
  }

  &__multiple-icon {
    padding: 0 20px;
    font-size: $fsz-28;
    @media (max-width: $lg) {
      font-size: $fsz-20;
    }
    @media (max-width: $md) {
      font-size: $fsz-18;
      padding: 0 15px 0 10px;
    }
  }

  &__tag {
    margin-right: 20px;
    margin-left: 20px;
    @media (max-width: $lg) {
      margin: 0;
    }
    @media (max-width: $md) {
      font-size: $fsz-10;
    }
  }
}

.jobLineContent {
  border-top: $border-dark-blue;
  margin: 20px 0;
  padding-top: 20px;

  @media (max-width: $lg) {
    margin: 10px 0;
    padding-top: 10px;
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    margin-top: -5px;
    margin-bottom: 15px;
    & > * {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  &__tabs-wrapper {
    margin-right: 40px;
    @media (max-width: 1500px) {
      margin-right: auto;
    }
    .selectable--sm {
      width: 140px;
    }
  }

  &__assignees-wrap {
    display: flex;
    margin-right: auto;
    @media (max-width: 1500px) {
      order: 1;
      width: 100%;
    }
  }

  &__assignees {
    display: inline-flex;
    align-items: center;
    border: $border-dark-gray;
    padding: 8px 10px;
    border-radius: 8px;
  }

  &__assignee-tab-value {
    @include text-overflow($max-width: 160px);
    display: block;
    @media (max-width: 1500px) {
      max-width: 200px;
    }
  }

  &__plus-value {
    margin-left: 10px;
    margin-right: 5px;
    font-weight: $font-weight-bold;
  }

  &__tooltip {
    @include tooltip-sm();
  }

  &__extra-list {
    @include list-unstyled();
    font-size: $fsz-16;
    @media (max-width: $md) {
      font-size: $fsz-14;
    }
  }

  &__mark-btn {
    @media (min-width: $lg + 1) {
      font-size: $fsz-16;
    }
  }
}

.emptyJobList {
  @include flex-column();
  align-items: center;
  padding-top: 40px;
  &__text{
    font-size: $fsz-20;
  }
  &__iconJob {
    width: 48px;
    height: 48px;
    margin-bottom: 20px;
    path {
      fill: $main-dark-blue;
    }
  }
}

.jobLineExpandedData {
  @media (min-width: $lg + 1) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    & > div {
      &:first-child {
        padding-right: 15px;
        border-right: $border-dark-gray;
      }
      &:last-child {
        padding-left: 15px;
      }
    }
  }
}

.jobLineNotes {
  position: relative;
  margin-bottom: 20px;
  padding: 25px 15px 0 15px;
  background-color: $color-gray;
  border-radius: 8px;

  .empty-content {
    padding-bottom: 20px;
  }
}
