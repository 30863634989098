@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.partReorderLevels {
  &__content {
    @include scroll();
    height: 270px;
    overflow: auto;

    @media (max-width: $md) {
      height: 350px;
    }
  }

  &__loading {
    display: block;
    padding: 10px 0;
    text-align: center;
    font-weight: $font-weight-bold;
  }

  &__list {
    @include list-unstyled();
  }

  &__item {
    &:not(:last-of-type) {
      margin-bottom: 15px;
    }
  }

  &__empty {
    padding-top: 0;
    height: 100%;
    justify-content: center;

    .empty-content__icon {
      @media (min-width: $lg + 1) {
        height: 80px;
        width: 80px;
      }
    }
  }
}

.partReorderLine {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 150px;
  gap: 15px;
  color: $main-dark-blue;
  background-color: $color-white;
  border: 0;
  padding: 15px 10px;
  text-align: left;
  border-radius: 8px;
  user-select: text;
  cursor: default;

  @media (max-width: $md) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    gap: 5px;
  }

  &:not(.not-link) {
    cursor: pointer;
    transition: $transition;
    &:hover,
    &:focus,
    &:active {
      color: $main-dark-blue;
    }
  }

  &__code,
  &__name {
    @include text-overflow($max-width: 100%);
  }

  &__count {
    display: inline-grid;
    grid-template-columns: auto auto;
    justify-content: start;
    align-items: center;
    column-gap: 5px;
    white-space: nowrap;
    font-weight: $font-weight-bold;
  }

  &__required {
    color: $secondary-dark-gray;
  }
}
