@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.affectedEntitiesModal {
    &__text {
        display: block;
        margin-bottom: 15px;

        @media (min-width: $lg + 1) {
            font-size: $fsz-16;
        }
    }

    &__pagination {
        margin-bottom: 10px;
    }
}
