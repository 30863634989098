@import 'src/core/styles/mixins';

.partsKitsLine {
  @include line-link();
  @media (min-width: $lg + 1) {
    @include flex-start();
    align-items: stretch;
  }
  @media (max-width: $lg) {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    row-gap: 15px;
    padding: 10px;
  }

  &__title {
    flex: 1;
    display: grid;
    align-items: center;
    column-gap: 20px;
    grid-template-columns: auto 1fr;
    margin-right: 20px;
    font-size: $fsz-16;

    @media (max-width: $lg) {
      grid-column: 1/-2;
      column-gap: 10px;
      font-size: $fsz-14;
    }
  }

  &__icon {
    grid-row: span 2;
    width: 30px;
    height: 30px;

    @media (max-width: $lg) {
      width: 24px;
      height: 24px;
    }
  }

  &__id {
    color: $accent-gray;
  }

  &__name {
    font-weight: $font-weight-bold;
    @include text-overflow($max-width: 100%);
  }

  &__tag {
    @media (min-width: $lg + 1) {
      align-self: center;
    }
    @media (max-width: $lg) {
      align-self: start;
      justify-self: end;
    }
  }

  &__info {
    display: flex;
    margin-left: 20px;
    border-left: $border-dark-blue;
    @media (max-width: $lg) {
      grid-column: 1/-1;
      margin-left: 0;
      font-size: $fsz-12;
      border-left: 0;
    }
  }

  &__category {
    @include vertical-text-overflow();
    align-self: center;
    text-align: center;
    width: 180px;
    padding: 0 20px;
    @media (max-width: $lg) {
      width: 33.333%;
      padding: 0 10px;
    }
  }

  &__areas {
    @include flex-center();
    text-align: center;
    width: 210px;
    padding: 0 20px;
    border-left: $border-dark-blue;
    border-right: $border-dark-blue;
    @media (max-width: $lg) {
      width: 33.333%;
      padding: 0 10px;
    }
  }

  &__total {
    text-align: center;
    @media (min-width: $lg + 1) {
      @include flex-column();
      justify-content: center;
      width: 120px;
      padding-left: 16px;
    }
    @media (max-width: $lg) {
      @include flex-center();
      flex-wrap: wrap;
      width: 33.333%;
      padding: 0 10px;
    }
  }

  &__total-label {
    @media (max-width: $lg) {
      margin-right: 5px;
    }
  }

  &__total-value {
    font-weight: $font-weight-bold;
  }
}
