@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.partVehicleAndPrices {
  .info-grid {
    &__column {
      padding-bottom: 20px;
      &:not(:last-child) {
        margin-bottom: 20px;
        @media (max-width: $lg) {
          margin-bottom: 15px;
        }
      }
    }
    &__title,
    &__title-inner {
      font-size: $fsz-20;
      @media (max-width: $lg) {
        font-size: $fsz-18;
      }
    }
  }
}

.partViewPricesTable {
  .partViewPricesParentRow {
    .table-new-td {
      font-weight: $font-weight-bold;
      padding-top: 15px;
      padding-bottom: 10px;
    }
  }

  .partViewPricesChildRow {
    .table-new-td {
      white-space: nowrap;
      &:first-child {
        padding-left: 60px;
        white-space: normal;
      }
    }

    &.hidden {
      display: none;
    }
  }
}

.partViewPricesTh {
  &--priceSource {
    width: 35%;
    min-width: 250px;
  }
  &--quantity {
    width: 15%;
    min-width: 110px;
  }
  &--costPrice {
    width: 25%;
    min-width: 145px;
  }
  &--sellingPrice {
    width: 25%;
    min-width: 145px;
  }
}
