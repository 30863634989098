@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.createFolderInput {
  margin-bottom: 20px;
}

.formsFolders {
  .ant-tabs-nav {
    position: sticky;
    z-index: 1;
    top: 80px;
    margin-bottom: 5px;
    margin-right: -20px;
    margin-left: -20px;
    padding: 20px 20px 15px;
    @media (max-width: $lg) {
      top: 70px;
      padding-top: 15px;
      padding-bottom: 10px;
    }
  }
}
