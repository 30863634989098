@import "src/core/styles/mixins";
@import "src/core/styles/variables";

.receivedQtyWrap {
  @include flex-column();
  &__field {
    margin-bottom: 20px;
    @media (max-width: $md) {
      margin-bottom: 10px;
    }
  }
  &__section {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 20px;
    margin-bottom: 20px;
    position: relative;
    &:after {
      content: '';
      height: 1px;
      background-color: $main-dark-blue;
      width: 100%;
      position: absolute;
      bottom: -20px;
    }
    @media (max-width: $md) {
      grid-template-columns: 100%;
      gap: 10px;
    }
  }
  &__sectionDouble {
    @media (min-width: $lg + 1) {
      display: grid;
      margin: 20px 0;
      grid-template-rows: repeat(2, 1fr);
      gap: 20px;
    }

    @media (max-width: $lg) {
      padding: 20px 0;
    }

    &-wrap {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 0 20px;
      align-items: end;

      @media (max-width: $lg) {
        grid-template-columns: 100%;
        row-gap: 10px;
        margin-top: 15px;
      }
    }
  }

  &__modal-buttons {
    margin-top: 40px;
    @media (max-width: $lg) {
      margin-top: 20px;
    }
  }
  .regularInputNumber__numFieldWrap-postfix {
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }

  &__table {
    &-title {
      font-weight: $font-weight-bold;
      margin-bottom: 20px;
      @media (max-width: $md) {
        margin-bottom: 10px;
      }
    }
  }

  &__actions {
    display: grid;
    grid-template-columns: 120px 220px auto;
    justify-content: start;
    align-items: end;
    gap: 20px;

    @media (max-width: $lg) {
      .regularInputNumber__numFieldWrap-field,
      .ant-select-selector {
        height: 40px;
        min-height: 40px;
      }
    }
  }

  .regularInputNumber__numFieldWrap-field,
  .ant-select-selector {
    font-size: $fsz-14;
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: $color-gray;
  }
}

.linkReservedToOrders-input {
  .regularInputNumber__numFieldWrap-postfix {
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
}

.linkReservedToOrdersTh {
  &--ordinalNumber {
    min-width: 55px;
  }
  &--linkedTo {
    width: 70%;
    min-width: 150px;
  }
  &--quantity {
    width: 30%;
    min-width: 90px;
  }
}

.linkReservedToOrders-actions {
  display: grid;
  grid-template-columns: minmax(0, 5fr) minmax(0, 2fr) minmax(0, 3fr);
  align-items: end;
  gap: 15px;
  margin-top: 15px;

  @media (max-width: $lg) {
    .regularInputNumber__numFieldWrap-field,
    .ant-select-selector {
      height: 40px;
      min-height: 40px;
    }
  }

  @media (max-width: $sm) {
    grid-template-columns: 100%;
    gap: 10px;
  }
}
