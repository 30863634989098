@import "src/core/styles/mixins";
@import "src/core/styles/variables";

.userContent {
  margin-top: 20px;
}

.userDetailsForm {
  .ant-input-affix-wrapper .ant-input {
    font-size: $fsz-16;
  }

  &__tooltip {
    @include tooltip-sm();
  }
}

.userActivitySelect {
  width: auto;
  height: auto;
  border: 0;
  background-color: transparent;
  box-shadow: none;
  font-size: $fsz-24;
  font-weight: $font-weight-bold;

  &.ant-btn {
    @include hover-focus-active() {
      background-color: transparent;
    }
  }

  & > .anticon.anticon-down {
    font-size: $fsz-18;

    @media (max-width: $lg) {
      font-size: $fsz-14;
    }
  }

  @media (max-width: $lg) {
    padding: 0;
    font-size: $fsz-20;
  }
}
