@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.selectVehiclesModes {
  margin-bottom: 15px;

  @media (max-width: $sm) {
    .customRadioGroup-group {
      display: grid;
      row-gap: 5px;

      .ant-radio-button-wrapper {
        margin-right: 0;
      }
    }
  }
}

.vehicleGroupsSectionTh {
  &--name {
    width: 50%;
    min-width: 150px;
  }

  &--vehicleProfilesCount {
    width: 50%;
    min-width: 250px;
  }
}
