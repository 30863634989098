@import "src/core/styles/variables";
@import "src/core/styles/mixins";

@mixin border-line($side, $offset-top: false, $offset-bottom: false) {
  content: '';
  position: absolute;
  display: block;
  width: 2px;
  background-color: $accent-gray;
  @if $offset-top == true {
    top: 15px;
  } @else {
    top: 0;
  }
  @if $offset-bottom == true {
    bottom: 15px;
  } @else {
    bottom: 0;
  }
  @if $side == left {
    left: 0;
  }
  @if $side == right {
    right: 0;
  }
  @if $side == bottom {
    top: auto;
    right: 0;
    left: 0;
    bottom: 0;
    width: auto;
    height: 2px;
    background-color: $main-dark-blue;
  }
}

@mixin hover-effect($position) {
  content: "";
  position: absolute;
  background-color: $color-gray;
  z-index: -1;
  @if $position == vertical {
    left: 0;
    height: 5000px;
    width: 100%;
    transform: translateY(-100%);
  }
  @if $position == horizontal {
    top: 0;
    right: 0;
    bottom: 0;
    width: 5000px;
  }
}

.permissions-table {
  border: $border-dark-gray;
  border-radius: 8px;
  scroll-behavior: auto;

  @media (max-width: $lg) {
    margin: 0;
    padding-left: 0;
    position: static;
  }

  .table-head .table-tr {
    border: 0;
  }

  table {
    min-height: auto;
    margin-top: -2px;
    font-size: $fsz-16;
    border: 0;
    border-radius: 0;
    background-color: initial;
    &::after {
      display: none;
    }

    .thRole,
    .thRoleLevel {
      position: sticky;
      z-index: 1;
      background-color: $color-white;
      &:first-child {
        z-index: 2;
        left: 0;
      }
    }

    .thRole {
      top: 0;
      height: 60px;
      padding: 0 5px;

      &:nth-child(1)::before {
        @include border-line($side: right, $offset-top: true);
      }
      &:nth-child(1n + 3)::before {
        @include border-line($side: left, $offset-top: true);
      }
    }

    .thRoleLevel {
      top: 60px;
      height: 35px;
      min-width: 90px;
      width: 90px;
      font-size: $fsz-14;
      color: $dark-gray;
      white-space: nowrap;
      vertical-align: top;

      &:nth-child(1)::before {
        @include border-line($side: right, $offset-bottom: true);
      }
      &--1::before {
        @include border-line($side: left, $offset-bottom: true);
      }

      &::after {
        @include border-line($side: bottom);
      }
      &:first-child::after {
        left: 15px;
      }
      &:last-child::after {
        right: 15px
      }
    }

    .permissionTr {
      display: none;
      &.visible {
        display: table-row;
      }

      &:first-child {
        .permissionTh, .permissionTd {
          padding-top: 15px;
          &::before {
            top: 15px;
          }
        }
      }
    }

    .permissionTh {
      $levels: (
              1: ('desktop': 10px, 'mobile': 5px),
              2: ('desktop': 10px, 'mobile': 5px),
              3: ('desktop': 40px, 'mobile': 25px),
              4: ('desktop': 80px, 'mobile': 55px),
              5: ('desktop': 120px, 'mobile': 70px),
      );
      @each $level, $padding in $levels {
        &--level-#{$level} {
          @if $level == 1 {
            font-weight: $font-weight-bold;
          }
          padding-left: map-get($padding, 'desktop');
          @media (max-width: $md) {
            padding-left: map-get($padding, 'mobile');
            overflow-wrap: break-word;
            word-wrap: break-word;
            word-break: break-word;
          }
        }
      }

      position: sticky;
      left: 0;
      z-index: 1;
      width: 350px;
      min-width: 350px;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-right: 8px;
      font-weight: $font-weight-regular;
      line-height: 1.3;
      text-align: left;
      background-color: $color-white;

      &::before {
        @include border-line($side: right);
      }

      @media (max-width: $lg) {
        font-size: $fsz-14;
      }
      @media (max-width: $md) {
        width: 200px;
        min-width: 200px;
        max-width: 200px;
      }
    }

    .permissionTd {
      .checkWrap {
        display: flex;
        justify-content: center;
      }

      .checkWrap-inner {
        position: relative;
        padding: 6px 8px;
        &:not(.checkWrap-inner--admin):hover {
          &::after {
            @include hover-effect(vertical);
          }
          &::before {
            @include hover-effect(horizontal);
          }
        }
      }
      .permCheckbox {
        @include table-checkbox();
        .ant-checkbox {
          & + span {
            display: none;
          }
        }
      }

      &--level-1 {
          position: relative;
          &::before {
            @include border-line($side: left)
          }
      }
    }
  }
}
