@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.segmentedCustom {
  padding: 0 !important;
  border-radius: 10px !important;
  height: 50px !important;
  border: 1px solid rgba($main-dark-blue, .5);
  background-color: $color-white !important;

  .ant-segmented-group {
    height: 100%;
    .ant-segmented-item {
      @include flex-center();
      border-radius: 10px;
      transition: $transition;

      &:hover .ant-segmented-item-label {
        color: $color-white;
        background-color: $main-dark-blue;
      }

      &-selected .ant-segmented-item-label {
        color: $main-yellow;
        background-color: $main-dark-blue;
      }

      &-selected:hover .ant-segmented-item-label {
        color: $main-yellow;
      }

      &-label {
        height: 50px;
        padding: 14px 25px;
        font-size: 18px;
        font-weight: $font-weight-bold;
        line-height: 1.2;
        color: $main-dark-blue;
        border-radius: 10px;
        transition: $transition;
      }
    }

    .ant-segmented-item-input:focus-visible + .ant-segmented-item-label {
      color: $color-white;
      background-color: $main-dark-blue;
    }
  }
}
