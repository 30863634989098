@import "./src/core/styles/mixins";
@import "./src/core/styles/variables";

.switch {
  --antd-wave-shadow-color: #3A4557;
  display: inline-flex;
  align-items: center;
  font-size: $fsz-14;
  font-weight: $font-weight-regular;
  color: $main-dark-blue;

  &__labelLeft {
    margin-right: 8px;
  }
  &__component {
    outline: 1px solid transparent;
    outline-offset: 2px;
    transition: $transition;
    background-color: $main-dark-blue !important;

    &:hover,
    &:focus {
      &:not(:disabled) {
        outline: $border-main-yellow !important;
        box-shadow: none !important;
      }
    }

    &--single-background {
      background-image: none !important;
    }
  }
  &__labelRight {
    margin-left: 8px;
  }
  .ant-switch-handle::before {
    background-color: $main-yellow;
  }
}
