@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.workCalendarLineSkeleton {
    &__name {
        width: 160px;
        height: 20px;
        margin-bottom: 6px;
        background-color: darken($skeleton-color, 5%);

        @media (max-width: $sm) {
            width: 100px;
            height: 18px;
        }
    }

    &__role {
        width: 85px;
        height: 16px;
        background-color: lighten($skeleton-color, 5%);

        @media (max-width: $sm) {
            width: 40px;
            height: 18px;
        }
    }

    &__card {
        width: 130px;
        height: 90px;
        margin: 0 auto;
        border-radius: 10px;
        background-color: lighten($skeleton-color, 7%);

        @media (max-width: $lg) {
            width: 110px;
            height: 80px;
        }

        @media (max-width: $sm) {
            width: 85px;
            height: 65px;
        }
    }
}
