.outstandingOverduePurchaseOrdersTh {
  &--number {
    min-width: 65px;
  }
  &--supplierName {
    width: 20%;
    min-width: 150px;
  }
  &--purchaseOrderNumber {
    width: 16%;
    min-width: 232px;
  }
  &--purchaseOrderDate {
    width: 16%;
    min-width: 220px;
  }
  &--costNet {
    width: 11%;
    min-width: 150px;
  }
  &--costGross {
    width: 11%;
    min-width: 165px;
  }
  &--dueDate {
    width: 11%;
    min-width: 160px;
  }
  &--assigneeFullName {
    width: 15%;
    min-width: 150px;
  }
}
