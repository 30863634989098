@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.autocompleteCustom {
  @include flex-column();
  &__label {
    @include field-label();
  }
  &__field {
    .ant-select-selector {
      @include flex-center();
      @include form-input();
      min-width: 130px !important;
      padding: 0 10px !important;
      border: $border-dark-gray !important;

      &::before {
        display: none;
      }

      .ant-select-selection-search-input {
        cursor: pointer !important;
      }

      .ant-select-selection-item {
        height: 30px;
      }

      .ant-select-selection-search {
        right: 11px !important;
      }
    }

    &.ant-select-focused {
      .ant-select-selector {
        border: $border-dark-blue !important;
        background-color: $color-gray;
      }
    }

    &.ant-select-disabled {
      .ant-select-selector {
        @include form-disabled();
      }
      .ant-select-selection-search-input {
        cursor: not-allowed !important;
      }
    }

    &:hover {
      .ant-select-selector {
        border: $border-dark-blue !important;
      }
    }
  }

  &__error {
    @include error-message();
  }
}
