@import "src/core/styles/mixins";
@import "src/core/styles/variables";

.partsKit {
  @include flex-column();
  &__buttons {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px 32px;
    margin-bottom: 20px;
    @media (max-width: $lg) {
      gap: 10px;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.partsTable {
  &__empty {
    padding: 20px 0;
    padding-top: 30px;
    @include flex-column();
    align-items: center;
    &-icon {
      width: 48px;
      height: 48px;
      margin-bottom: 20px;
    }
    &-text {
      font-size: $fsz-16;
    }

    &--lg {
      padding-top: 100px;
      padding-bottom: 100px;

      .partsTable__empty-icon {
        width: 80px;
        height: 80px;
      }
    }
  }

  &__showBox {
    width: 100%;
    margin-top: 20px;

    .ant-checkbox-wrapper {
      @include table-checkbox;
      align-items: center;

      .ant-checkbox {
        transform: translateY(-3px);
        margin-right: 6px;
      }
    }
  }
}

.customLineForm {
  &__field {
    &--wide {
      grid-column: 1/-1;
    }
    &--semi {
      @media (min-width: $md + 1) {
        grid-column: span 2;
      }
    }
  }

  &__section {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 20px 32px;
    @media (max-width: $lg) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 10px 20px;
    }
    @media (max-width: $md) {
      grid-template-columns: 100%;
    }
  }

  &__checkbox {
    @include table-checkbox(sm);
  }

  &__disabled-weight {
    .ant-input-suffix {
      position: absolute;
      right: 32px;
      top: 13px;
      font-size: $fsz-14;
      pointer-events: none;
    }
  }

  .buttonActions__cancel {
    @media (min-width: $lg + 1) {
      margin-right: 32px;
    }
  }

  &__radio {
    margin: 20px 0 10px;
  }
}

.partsModeTh,
.partsKitModeTh {
  &--number {
    min-width: 75px;
    width: 75px;
    max-width: 75px;
  }
  &--action {
    min-width: 105px;
    width: 105px;
    max-width: 105px;
  }
}

.partsModeTh {
  &--partCode {
    width: 25%;
    min-width: 158px;
  }
  &--manufacturerCode {
    width: 25%;
    min-width: 245px;
  }
  &--name {
    width: 25%;
    min-width: 170px;
  }
  &--description {
    width: 25%;
    min-width: 175px;
  }
}

.partsKitModeTh {
  &--partsKitCode {
    width: 27%;
    min-width: 195px;
  }
  &--name {
    width: 27%;
    min-width: 200px;
  }
  &--partsCount {
    width: 19%;
    min-width: 80px;
  }
  &--description {
    width: 27%;
    min-width: 175px;
  }
}
