.linkVehicleTh {
    &--ordinalNumber {
        min-width: 70px;
    }
    &--manufacturer {
        width: 25%;
        min-width: 195px;
    }
    &--modelDescription {
        width: 25%;
        min-width: 180px;
    }
    &--regNumber {
        width: 20%;
        min-width: 130px;
    }
    &--chassisNumber {
        width: 20%;
        min-width: 140px;
    }
    &--status {
        width: 10%;
        min-width: 120px;
    }
    &--action {
        min-width: 105px;
    }
}
