@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.employeeViewPageDetailsHead {
  .head__inner-info-item {
    &:nth-child(2) {
      grid-column: 2/5;
    }
    &:nth-child(3) {
      grid-column: 5/6;
    }
    &:nth-child(4) {
      grid-column: 6/7;
    }
    &:nth-child(5) {
      grid-column: 7/8;
    }

    @media (max-width: $lg) {
      &:nth-child(2) {
        grid-column: 2/6;
      }
      &:nth-child(3) {
        grid-column: 1/3;
      }
      &:nth-child(4) {
        grid-column: 3/5;
      }
      &:nth-child(5) {
        grid-column: 5/7;
      }
    }
  }
}

.employeeViewPage {
  display: grid;
  align-items: start;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;
  padding-top: 20px;

  @media (max-width: $lg) {
    grid-template-columns: 100%;
    padding-top: 15px;
    gap: 15px;
  }
}

.employeeViewPageTabs {
  @include segmented-sm();
}

.employeeMainInfo {
  display: grid;
  grid-template-columns: 100%;
  gap: 15px;
  margin-bottom: 15px;

  @media (min-width: $md + 1) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin-bottom: 20px;
  }

  &__list {
    @include details-activity();
    @include flex-column();
    height: 250px;
    justify-content: space-between;
    margin-bottom: 0;
    padding-bottom: 20px;

    @media (max-width: $lg) {
      padding-bottom: 15px;
    }

    @media(max-width: $sm) {
      height: auto;
    }
  }

  &__item {
    @include flex-column();

    @media(max-width: $sm) {
      &:not(:last-of-type) {
        margin-bottom: 8px;
      }
    }
  }

  &__label {
    font-size: $fsz-14;
  }

  &__value {
    @include text-overflow($max-width: 100%);
    font-weight: $font-weight-bold;
  }

  &__tooltip {
    @include tooltip-sm();
  }

  &__img-wrapper {
    @include flex-center();
    position: relative;
    &:hover {
      .employeeMainInfo__change-btn,
      .employeeMainInfo__remove-btn {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &__remove-btn.ant-btn-icon-only {
    width: 32px;
    height: 32px;

    & > svg {
      font-size: $fsz-16;
    }
  }

  &__remove-btn,
  &__change-btn {
    position: absolute;
    bottom: 10px;
    opacity: 0;
    visibility: hidden;
  }

  &__remove-btn.ant-btn-icon-only {
    right: 10px;

    & > svg {
      font-size: $fsz-16;
    }
  }

  &__change-btn {
    position: absolute;
    right: 50px;
    transition: $transition;

    .ant-btn-icon-only {
      width: 32px;
      height: 32px;
      border-radius: 10px !important;

      & > svg {
        font-size: $fsz-16;
      }
    }
  }

  .customDragger {
    width: 100%;
    height: 100%;

    .ant-upload.ant-upload-drag {
      background-color: $color-gray;
      margin-bottom: 0;
    }
  }

  .ant-image {
    width: 100%;
    height: 100%;
    max-height: 250px;
  }

  .ant-image-mask {
    border-radius: 10px;
  }

  img {
    width: 100%;
    height: 100%;
    max-height: 258px;
    object-fit: contain;
    border-radius: 10px;
  }
}

.skillsWrap {
  position: relative;
  .skillsDropdown {
    position: absolute;
    top: 20px;
    right: 10px;
  }
}
