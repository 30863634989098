.linksTableTh {
    &--entity {
        width: 30%;
        min-width: 320px;
    }
    &--status {
        width: 20%;
        min-width: 200px;
    }
    &--createdDate,
    &--modifiedDate {
        width: 25%;
        min-width: 185px;
    }
}

.linksRows {
    &__parent-row {
        margin: 8px 0 6px;
    }
}
