@import 'src/core/styles/mixins';
@import 'src/core/styles/variables';

$column-width: 278px;
$column-width-mobile: 260px;

.drag-and-drop-area {
  @include scroll(6px);
  flex: 1;
  overflow: auto;
  margin-bottom: -20px;
  margin-right: -20px;
  padding-bottom: 10px;
  padding-right: 20px;
  @media (max-width: $lg) {
    margin-right: -10px;
    margin-bottom: -10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }

  &__heading {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 8px 5px;
    width: $column-width;
    box-sizing: content-box;
    position: sticky;
    top: 0;
    z-index: 1;
    box-shadow: 0 -7px 0 0 $color-white;
    border-radius: 10px 10px 0 0;
    margin: 0 -12px;
    background-color: $color-gray;
    padding: 12px;
    margin-bottom: 18px;

    &::after {
      content: '';
      position: absolute;
      right: 12px;
      left: 12px;
      bottom: 0;
      height: 2px;
      background-color: $main-dark-blue;
    }
    @media (max-width: $lg) {
      padding: 12px;
      padding-top: 14px;
      margin-bottom: 16px;
      width: $column-width-mobile;
    }
  }

  &__content {
    display: inline-grid;
    grid-auto-flow: column;
    column-gap: 20px;
    min-height: 100%;
  }

  &__column {
    @include flex-column();
    background-color: $color-gray;
    min-height: 240px;
    border-radius: 10px;
    padding: 0 12px 18px 12px;

    &-head {
      @include flex-between();
      position: relative;
      box-sizing: content-box;
      width: $column-width;
      border-bottom: 2px solid transparent;
      border-radius: 10px 10px 0 0;
      padding: 18px 12px 12px 12px;
      background-color: $color-gray;

      &::after {
        content: '';
        position: absolute;
        right: 12px;
        left: 12px;
        bottom: 0;
        height: 2px;
        background-color: $main-dark-blue;
      }
      @media (max-width: $lg) {
        width: $column-width-mobile;
        padding: 12px;
        padding-top: 14px;
      }

      &__label {
        @include text-overflow($max-width: 100%);
        grid-column: 1/2;
        font-size: $fsz-15;
        font-weight: $font-weight-bold;
        @media (max-width: $lg) {
          font-size: $fsz-14;
          margin-right: 8px;
        }
      }

      &__tooltip-overlay {
        @include tooltip-sm();
      }

      &__total {
        grid-column: 2/3;
        font-size: $fsz-14;
        white-space: nowrap; // bugfix: this line should be displayed in 1 row only
        color: $primary-dark-blue;

        .totalPerFilter {
          font-weight: $font-weight-bold;
        }
      }

      &__cost {
        grid-column: 1/-1;
        border-radius: 6px;
        background-color: $color-white;
        text-align: center;
        font-size: $fsz-15;
        font-weight: $font-weight-bold;
      }
    }

    &-content {
      width: $column-width;
      overflow-anchor: auto !important;
      flex: 1;
      border-radius: 0 0 5px 5px;
      transition: $transition;
      height: 100%;
      @media (max-width: $lg) {
        width: $column-width-mobile;
      }

      &.dragginOver {
        background-color: rgba(115,108,237, .1);
        transition: $transition;
      }
    }

    &-loadMore {
      width: 100%;
      height: 30px;
      justify-content: center;
      padding: 0;
      font-size: $fsz-14;

      &.loadMoreHidden {
        display: none;
      }
    }
  }
}

.draggableCard {
  border-radius: 10px;
  border: $border-main-gray;
  box-shadow: 0 3px 9px 1px rgba(0, 0, 0, 0.1);
  background-color: $color-white;
  position: relative;
  margin-bottom: 15px;
  transition: $transition;
  cursor: pointer;
  &:hover,
  &:focus {
    border: $border-dark-blue;
  }

  &__type,
  &__category {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  &__type {
    width: 54px;
  }

  &__category {
    width: 43%;
    text-transform: none;
  }

  &__category-value {
    display: block;
    @include text-overflow($max-width: 100%)
  }

  .customer {
    @include flex-between();
    align-items: flex-start;
    padding: 3px;

    &__tag {
      @include flex-center();
      max-width: 55%;

      svg {
        order: 1;
        margin-left: 5px;
      }
    }

    &__name {
      @include text-overflow($max-width: 100%);
      text-transform: none;
      &:not(:last-child) {
        margin-right: 4px;
      }
    }

    &__list-wrapper {
      display: flex;
      width: 100%;
    }

    &__icon {
      font-size: $fsz-16;
    }
  }

  .cardContent {
    @include flex-column();
    .entityLink {
      color: $secondary-dark-gray;
      font-size: $fsz-14;
      font-style: italic;
      text-transform: uppercase;
      text-align: center;
      padding: 15px 8px 10px 8px;
    }

    .clientName {
      @include vertical-text-overflow;
      color: $main-dark-blue;
      font-size: $fsz-16;
      font-weight: $font-weight-bold;
      line-height: 22px;
      margin-bottom: 10px;
      padding: 0 10px;
      padding-right: 30px;
      word-break: break-word;
    }

    .costValue {
      font-size: $fsz-16;
      font-weight: $font-weight-bold;
      color: $main-dark-blue;
      padding: 0 10px 16px 10px;
    }

    .linkedEntities {
      @include flex-column();
      padding: 8px;
      &__line {
        @include flex-between();
        border-radius: 10px;
        padding: 4px 8px;
        background-color: $color-gray;
        font-size: 14px;
        margin-bottom: 8px;
        &-left {
          flex-shrink: 0;
          margin-right: 8px;
          &-icon {
            margin-right: 4px;
          }
        }
        &-right {
          @include text-overflow($max-width: 100%);
          font-weight: $font-weight-bold;
          text-align: right;
        }
      }
    }

    .timeTracking {
      @include list-unstyled();
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      column-gap: 8px;
      padding: 0 8px;
      margin-bottom: 8px;
      &__item {
        @include flex-column();
        align-items: center;
        border-radius: 10px;
        padding: 4px;
        background-color: $color-white;
        border: $border-dark-blue;
        font-size: 12px;

        &-value {
          font-weight: $font-weight-bold;
        }
      }
    }

    .icons {
      position: relative;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 6px;

      &::before {
        content: '';
        display: block;
        position: absolute;
        height: 1px;
        top: 0;
        right: 3px;
        left: 3px;
        background-color: $dark-gray;
      }

      &__item {
        @include flex-center();
        margin-right: 5px;

        &:last-child {
          margin-right: 0;

          .icons__item-icon {
            margin-right: 0;
          }
        }
      }

      &__item-icon {
        width: 24px;
        margin-right: 5px;
      }

      .itemPriority {
        @include flex-center();
        margin-right: 5px;
        border-radius: 100px;
        width: 24px;
        height: 24px;
        background-color: $main-yellow !important;
        &-icon {
          color: $main-dark-blue !important;
        }
      }
    }
  }
}

.card-tooltip-overlay {
  @include tooltip-sm();
}
