.rectificationsBySupplierReportTh {
  &--number {
    min-width: 70px;
  }
  &--supplier {
    width: 18%;
    min-width: 135px;
  }
  &--category {
    width: 15%;
    min-width: 135px;
  }
  &--subcategory {
    width: 15%;
    min-width: 165px;
  }
  &--partCode {
    width: 14%;
    min-width: 165px;
  }
  &--partName {
    width: 20%;
    min-width: 180px;
  }
  &--usedInRectifications {
    width: 10%;
    min-width: 260px;
  }
}
