@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.summarySection {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;
  @media (max-width: $lg) {
    grid-template-columns: 100%;
    gap: 15px;
  }

  &__item {
    background-color: $color-gray;
    border-radius: 10px;
    padding: 15px 20px 20px;
    @media (max-width: $lg) {
      padding: 14px 10px;
    }

    &-title {
      width: 100%;
      @include summaryTitle();
    }

    &-linesWrap {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      @media (max-width: $lg) {
        display: flex;
        flex-direction: column;
      }

      .vehSum {
        @include flex-column();
        border-right: $table-line;
        padding-right: 30px;
        @media (max-width: $lg) {
          padding-right: 0;
        }

        &:first-child {
          @media (max-width: $lg) {
            padding-bottom: 24px;
            margin-bottom: 20px;
            border-right: none;
            border-bottom: $table-line;
          }
        }

        &:last-child {
          border-right: none;
          padding-right: 0;
          padding-left: 30px;
          @media (max-width: $lg) {
            padding-left: 0;
          }
        }

        .sumLine {
          &:not(:last-child) {
            margin-bottom: 20px;
          }

          .sumLabel {
            color: $main-dark-blue;
          }
        }
      }
    }

    &-lines {
      @include flex-column();

      .sumLine {
        &:not(:last-child) {
          margin-bottom: 20px;
        }

        .sumLabel {
          color: $main-dark-blue;
        }
      }
    }
  }
}

.sumLine {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  &--bold {
    font-weight: $font-weight-bold;
  }
  .sumLabel {
    color: $dark-gray;
    font-size: $fsz-14;
  }
  .sumValue {
    white-space: nowrap;
    font-size: $fsz-14;
    text-align: end;
  }
}
