@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.vehicle-section {
  overflow: auto;
  @include scroll();
  background-color: $color-white;
  padding: 8px;
  border-radius: 5px;

  &--edge {
    background-color: transparent;
    padding: 0;
    border-radius: 0;
  }

  @media (min-width: $md + 1) {
    max-height: 140px;

    &--edge {
      max-height: none;
    }
  }

  &--bordered {
    border: $border-dark-gray;
    border-radius: 10px;
  }

  &--horizontal {
    @media (min-width: $lg + 1) {
      display: flex;
      align-items: center;

      .vehicle-section {
        &__content {
          min-width: 35%;
          margin-right: auto;
        }

        &__specific {
          margin-top: 0;
          min-width: 0;
        }
      }
    }
  }

  &__content {
    @include flex-center();
    justify-content: flex-start;
    @media (max-width: $md) {
      flex-direction: column-reverse;
      align-items: stretch;
    }
  }

  &__info {
    min-width: 0;
    margin-right: auto;
    @media (max-width: $md) {
      width: 100%;
    }
  }

  &__action {
    @include flex-center();
    margin-left: 10px;
    flex-shrink: 0;
    @media (max-width: $md) {
      justify-content: space-between;
      margin-left: 0;
      margin-bottom: 5px;
    }
  }

  &__status.tag:not(:last-child) {
    margin-right: 10px;
  }

  &__text {
    @include text-overflow($max-width: 100%);
    display: block;
    text-transform: uppercase;

    &:not(:last-of-type) {
      margin-bottom: 4px;
    }

    &--bold {
      font-weight: $font-weight-bold;
    }
  }

  &__specific {
    display: flex;
    margin-top: 8px;

    @media (max-width: $md) {
      flex-wrap: wrap;
    }
  }

  &__specific-tag.tag {
    flex-shrink: 0;
    min-width: 0;
    max-width: 100%;
    margin: 2px 8px 2px 0;

    &:last-of-type {
      margin-right: 0;
    }
  }
  &__specific-tag--shrink.tag {
    flex-shrink: 1;
  }

  &__specific-tag-content {
    display: block;
    @include text-overflow($max-width: 100%);
  }

  .dropdownTriggerBtn .fa-ellipsis-vertical {
    transform: translateY(1px);
  }
}
