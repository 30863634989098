@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.tableWrap {
  @include scroll();
  scroll-behavior: smooth;
  overflow: auto;
  padding-right: var(--desktop-scroll-offsite, 0);
  padding-left: var(--desktop-scroll-offsite, 0);
  margin-right: calc(var(--desktop-scroll-offsite, 0) * -1);
  margin-left: calc(var(--desktop-scroll-offsite, 0) * -1);
  @media (max-width: $lg) {
    padding-right: var(--mobile-scroll-offsite, 0);
    padding-left: var(--mobile-scroll-offsite, 0);
    margin-right: calc(var(--mobile-scroll-offsite, 0) * -1);
    margin-left: calc(var(--mobile-scroll-offsite, 0) * -1);
  }

  &--offset-bottom {
    margin-bottom: 24px;

    @media (max-width: $lg) {
      margin-bottom: 15px;
    }
  }

  &--borderless {
    .table-new {
      border: 0;
      background-color: $color-gray;

      tbody .table-tr:not(.emptyTableSection) {
        &:hover {
          background-color: $color-white;
        }
      }
    }
  }
}

.table-new {
  position: relative;
  width: 100%;
  border: 1px solid $dark-gray;
  border-radius: $table-border-radius;
  border-collapse: separate;
  border-spacing: 0;
  font-size: $fsz-14;
  background-color: $color-white;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 60px;
    left: 20px;
    right: 20px;
    height: 2px;
    transform: translateY(-50%);
    background-color: $main-dark-blue;
    @media (max-width: $lg) {
      left: 15px;
      right: 15px;
    }
  }

  &--small {
    &::after {
      top: 45px;
    }

    .table-new-th {
      font-size: $fsz-14;
      height: 45px;
    }
  }

  .privateLineLink {
    display: block;
    width: fit-content;
    @include link();
  }

  &:not(.table-new--has-footer) {
    tbody .table-tr:last-of-type > td {
      &:first-of-type {
        border-bottom-left-radius: $table-border-radius;
      }
      &:last-of-type {
        border-bottom-right-radius: $table-border-radius;
      }
    }
  }

  &:not(.table-new--disable-hover) tbody .table-tr:not(.emptyTableSection) {
    &:hover {
      background-color: $main-light-gray;
    }
  }
}

.table-new-th,
.table-new-td {
  padding: 10px;

  &:first-child {
    padding-left: 20px;
    @media (max-width: $lg) {
      padding-left: 15px;
    }
  }

  &:last-child {
    padding-right: 20px;
    @media (max-width: $lg) {
      padding-right: 15px;
    }
  }
}

.table-new-th {
  height: 60px;
  font-size: $fsz-16;
  text-align: left;
  white-space: nowrap;

  &--checkbox {
    min-width: 70px;
    width: 70px;
    max-width: 70px;

    @media (max-width: $lg) {
      width: 60px;
      min-width: 60px;
    }
  }

  &--action {
    min-width: 60px;
    width: 60px;
    max-width: 60px;
    text-align: right;

    .dropdownTriggerBtn:disabled .svg-inline--fa {
      color: $main-gray;
    }
  }

  &--centered {
    text-align: center;
  }
}

.table-new-td {
  &--action {
    text-align: center;

    .dropdownTriggerBtn .fa-ellipsis-vertical {
      transform: translateY(2px);
    }

    .dropdownTriggerBtn:disabled .svg-inline--fa {
      color: $main-gray;
    }
  }

  &--centered {
    text-align: center;
  }

  &--lg {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  &--offset-left,
  &--offset-left:first-child {
    padding-left: 60px;
    @media (max-width: $lg) {
      padding-left: 50px;
    }
  }
}

.table-footer {
  position: relative;
  border: none;

  &::before {
    content: '';
    position: absolute;
    left: 20px;
    right: 20px;
    height: 2px;
    background-color: $dark-gray;
    @media (max-width: $lg) {
      left: 15px;
      right: 15px;
    }
  }

  .table-new-td {
    padding: 15px 10px;

    &:first-child {
      padding-left: 20px;
    }

    &:last-child {
      padding-right: 20px;
    }
  }

  .customButton.ant-btn-text {
    margin-right: 10px;
    font-size: $fsz-16;

    & > svg {
      font-size: $fsz-12;
    }
  }
}

.table-fixed-footer {
  border: $border-dark-gray;
  border-radius: 8px;

  .tableWrap {
    max-height: 350px;
    position: static;
    margin: 0;
    padding-left: 0;
  }

  .table-new {
    border: 0;
  }

  &__footer {
    padding: 10px 20px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 20px;
      right: 20px;
      height: 2px;
      background-color: $dark-gray;
    }
  }
}
