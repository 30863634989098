@import 'src/core/styles/variables';

.addressSuggestions-back {
  position: absolute;
  top: 7px;
  left: 0;
  border: 0;

  & > svg {
    font-size: $fsz-18;
  }
}

.addressSuggestions-search {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 15px;
  margin-top: 40px;
  margin-bottom: 20px;

  @media (max-width: $lg) {
    grid-template-columns: 100%;
    row-gap: 10px;

    .customButton {
      justify-self: center;
    }
  }
}

.addressSuggestions-select-col {
  text-align: center;

  .ant-spin {
    font-size: 20px;
  }
}

.addressSuggestionsTh {
  &--number {
    min-width: 65px;
  }
  &--address {
    width: 100%;
    min-width: 300px;
  }
  &--actions {
    min-width: 105px;
  }
}
