@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.login {
  width: 100%;
  @include flex-center();

  &__form-wrapper {
    width: 100%;
    max-width: 350px;
    margin: 40px 10px;
    padding: 32px;
    border: $border-main-gray;
    border-radius: 10px;

    @media (max-width: $md) {
      padding: 20px;
    }
  }

  &__logo {
    display: block;
    margin-bottom: 32px;

    @media (max-width: $lg) {
      margin-bottom: 16px;
    }
  }

  &__form {
    display: grid;
    gap: 20px;
  }

  &__field {
    position: relative;

    .passwordInput-error,
    .input-error {
      position: absolute;
      top: 99%;
    }

    @media (max-width: $lg) {
      .ant-input {
        min-height: 40px;
        height: 40px;
      }
    }
  }

  &__forgot-link {
    color: $accent-gray;
    font-style: italic;
    font-size: $fsz-14;
    transition: color $transition;
    justify-self: start;

    &:hover, &:focus, &:active {
      color: $primary-dark-blue;
    }

    @media (max-width: $lg) {
      font-size: $fsz-12;
    }
  }
}
