@import 'src/core/styles/mixins';

.supplierLine {
  @include flex-start();
  @include line-link();

  @media (max-width: $lg) {
    flex-direction: column;
    align-items: stretch;
    padding: 10px;
    font-size: $fsz-14;
  }

  &__title {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto;
    align-items: center;
    column-gap: 10px;
    margin-right: 10px;
    @media (min-width: $lg + 1) {
      flex-grow: 1;
      column-gap: 20px;
      font-size: $fsz-16;
    }
    @media (max-width: $lg) {
      margin-bottom: 10px;
      font-size: $fsz-14;
    }
  }

  &__icon {
    width: 30px;
    height: 30px;
    grid-row: span 2;

    @media (max-width: $lg) {
      width: 24px;
      height: 24px;
    }
  }

  &__id {
    color: $accent-gray;
  }

  &__name {
    @include text-overflow($max-width: 100%);
    font-weight: $font-weight-bold;
  }

  &__info-list {
    @include list-unstyled();
    display: grid;
    gap: 24px;
    font-size: $fsz-14;
    @media (min-width: $lg + 1) {
      flex-shrink: 0;
      grid-template-columns: repeat(3, auto);
      padding-left: 20px;
      border-left: $border-dark-blue;
    }
    @media (max-width: $lg) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 15px;
      font-size: $fsz-12;
    }
    @media (max-width: $md) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 6px;
    }
  }

  &__info-item {
    @media (min-width: $lg + 1) {
      @include flex-column();
      align-items: center;
    }
  }

  &__info-label {
    @media (max-width: $lg) {
      margin-right: 8px;
    }
    @media (min-width: $lg + 1) {
      text-align: center;
    }
  }

  &__info-value {
    font-weight: $font-weight-bold;
  }
}
