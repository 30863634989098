@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.employeesLeaveRequestItem {
  @include line-link();
  display: grid;
  grid-template-columns: auto 195px 195px 1fr auto auto;

  @include expanded-sidebar() {
    position: relative;
    grid-template-columns: auto 150px 1fr auto;
    row-gap: 5px;
  }

  @media (max-width: $lg) {
    position: relative;
    grid-template-columns: 100%;
    gap: 10px;
  }

  &__tooltip {
    @include tooltip-sm();
    padding-bottom: 6px;

    .ant-tooltip-arrow {
      display: none;
    }
  }

  &__icon {
    align-self: center;
    font-size: $fsz-36;
    margin-right: 15px;

    @include expanded-sidebar() {
      grid-column: 1/2;
      font-size: $fsz-32;
    }

    @media (max-width: $lg) {
      display: none;
    }
  }

  &__employee {
    @include flex-column();
    padding-right: 20px;
    border-right: $border-dark-blue;

    @include expanded-sidebar() {
      grid-column: 2/-1;
      border-right: 0;
    }

    @media (max-width: $lg) {
      padding-right: 0;
      border-right: 0;
    }
  }

  &__name {
    @include text-overflow($max-width: 100%);
    font-weight: $font-weight-bold;

    @media (min-width: $lg + 1) {
      font-size: $fsz-16;
    }
  }

  &__role {
    @include text-overflow($max-width: 100%);
  }

  &__info-icon {
    margin-left: 10px;

    @media (min-width: $lg + 1) {
      font-size: $fsz-16;
    }
  }

  &__type {
    align-self: center;
    padding: 0 20px;
    font-weight: $font-weight-bold;

    @include expanded-sidebar() {
      grid-column: 1/3;
      padding-left: 0;
    }

    @media (min-width: $lg + 1) {
      font-size: $fsz-16;
    }

    @media (max-width: $lg) {
      padding: 0;
    }
  }

  &__info {
    display: grid;
    grid-template-columns: 245px auto;
    gap: 20px;
    padding-left: 20px;
    border-left: $border-dark-blue;

    @media (max-width: $lg) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      padding-left: 0;
      border-left: 0;
      gap: 10px;
    }

    @media (max-width: $sm) {
      grid-template-columns: 100%;
    }
  }

  &__info-item {
    @include flex-column();

    b {
      @media (min-width: $lg + 1) {
        font-size: $fsz-16;
      }
    }
  }

  &__tag-wrapper {
    align-self: center;
    padding: 0 15px 0 10px;

    @include expanded-sidebar() {
      padding-right: 0;
    }

    @media (max-width: $lg) {
      grid-row: 1/2;
      padding: 0;
    }
  }

  &__actions-wrapper {
    align-self: center;

    @include expanded-sidebar() {
      position: absolute;
      top: 20px;
      right: 10px;
    }

    @media (max-width: $lg) {
      position: absolute;
      top: 12px;
      right: 10px;
    }

    .fa-ellipsis-vertical {
      transform: translateX(0);
    }
  }
}
