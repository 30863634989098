@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.header {
  @include flex-between();
  position: sticky;
  top: 0;
  height: $header-height-desktop;
  background-color: $color-gray;
  z-index: $z-index-header;
  padding-left: $sidebar-wide + 20px;
  width: 100%;
  @media (max-width: $lg) {
    height: $header-height-mobile;
    padding-left: $sidebar-collapsed-mobile + 10px;
  }

  &.headerNotCollapsed {
    padding-left: $sidebar-collapsed + 20px;
    @media (max-width: $lg) {
      padding-left: $sidebar-collapsed-mobile + 10px;
    }
    @media (max-width: $sm) {
      padding-left: $sidebar-collapsed-mobile;
    }
  }

  .headerLeft {
    margin-right: 22px;
    display: flex;
    align-items: center;
    @media (max-width: $lg) {
      margin-right: 10px;
    }

    &__collapse-btn {
      position: absolute;
      left: 0;
      width: 70px;
      height: 70px;
      padding: 6px 10px 0;
      border-radius: 0;
      border: 0;
      overflow: hidden;
      background-color: $main-dark-blue;

      @media (max-width: $sm) {
        width: 60px;
        padding: 6px 8px 0 6px;
      }
    }

    .selectable {
      @media (max-width: $lg) {
        &__menu {
          min-width: 130px;
        }
      }
      @media (max-width: $sm) {
        width: 90px;
        &__menu {
          min-width: 90px;
        }
      }
    }
    .headerDropdown {
      height: 50px;
      padding: 0 25px;
      margin-left: 20px;
      font-size: $fsz-18;
      font-weight: $font-weight-bold;
      background-color: $main-dark-blue;
      border-radius: 10px;
      color: $main-yellow;
      transition: $transition;
      .svg-inline--fa {
        height: 14px;
        color: inherit;
        transition: none;
        transform: translateY(-1px);
      }
      @include hover-focus-active() {
        color: $color-white;
      }
      @media (max-width: $lg) {
        margin: 0 5px;
        padding: 0;
        height: 40px;
        width: 40px;
      }
    }

    .select {
      &-field {
        .ant-select-selector {
          @media (max-width: $sm) {
            width: 90px !important;
            min-width: 90px !important;
            padding: 0 6px !important;
          }
        }

        .ant-select-selector {
          padding-right: 22px !important;
          .ant-select-selection-item {
            @media (max-width: $sm) {
              padding-right: 0;
            }
          }
        }

        .ant-select-arrow {
          @media (max-width: $sm) {
            right: 9px;
          }
        }
      }
    }
  }

  .headerRight {
    display: flex;
    height: 100%;

    &__actions {
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 20px;
      @media (max-width: $lg) {
        padding-left: 0;
        padding-right: 6px;
      }

      .headerRight__bellIcon-button {
        background-color: transparent;
        border: 1px solid transparent;
        color: $main-dark-blue;

        @include hover-focus-visible() {
          border: $border-dark-blue-alpha;
          @media (max-width: $sm) {
            border-color: transparent;
          }
        }

        & > svg {
          font-size: $fsz-20;
        }
      }
    }

    &__user {
      display: flex;
      align-items: center;
      height: 100%;
      background-color: $main-dark-blue;
      padding: 0 25px 0 20px;
      @media (max-width: 1500px) {
        width: 90px;
        padding-left: 12px;
        padding-right: 10px;
      }
      @media (max-width: $lg) {
        width: 70px;
      }

      @media (max-width: $sm) {
        width: 60px;
        padding-left: 8px;
      }

      &-icon {
        position: absolute;
        left: 10px;
        height: 30px !important;
        color: $main-yellow;
        @media (max-width: $lg) {
          left: 0;
          height: 21px !important;
        }
      }

      &-wrapper {
        position: relative;
        display: flex;
        align-items: center;

        @media (max-width: 1500px) {
          width: 100%;
        }
      }

      &-dropdown {
        position: relative;
        cursor: pointer;
        font-size: $fsz-16;
        color: $color-white;
        background: none;
        outline: none;
        border: none;
        padding-left: 0;

        @media (min-width: 1501px) {
          padding: 10px 15px 10px 10px;
          width: 230px;
          border: 1px solid transparent;
          border-radius: 10px;
          transition: $transition;

          &:hover,
          &:focus {
            border-color: $main-yellow;
          }
        }

        @media (max-width: 1500px) {
          margin-left: auto;
          padding-right: 0;
        }

        & .ant-space-align-center {
          justify-content: space-between;
          width: 100%;
          gap: 20px !important;
          padding-left: 40px;
          @media (max-width: $lg) {
            padding-left: 25px;

            @media (max-width: $sm) {
              padding-left: 20px;
            }
          }
        }

        .ant-space-item {
          &:first-child {
            text-align: center;
            @include text-overflow($max-width: 130px);
            @media (max-width: 1500px) {
              display: none;
            }
          }

          .anticon-down svg {
            color: $main-yellow !important;
            stroke: $main-yellow;
            stroke-width: 60px;
          }
        }
      }

      .ant-dropdown-open {
        [class*=ant-space-item] {
          .anticon-down svg {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

.headerRight__user-menu  {
  position: fixed !important;
  top: 80px !important;
  @media (max-width: $lg) {
    top: 70px !important;
  }

  .ant-dropdown-menu {
    transform: none;
  }

  .ant-dropdown-menu-item-divider {
    display: block;
  }
}
