@import "src/core/styles/mixins";
@import "src/core/styles/variables";

.detailsWrap {
  &__activity {
    &-log {
      padding-top: 12px !important;
      margin-bottom: 24px !important;
      @media (max-width: $lg) {
        margin-bottom: 10px !important;
      }
    }

    .ant-pagination {
      text-align: right;
      margin-bottom: 16px;
    }

    .spinner {
      position: static;
      height: auto;
      padding-top: 50px;
      padding-bottom: 30px;
      background-color: transparent;

      @media (max-width: $lg) {
        padding: 20px 0;
      }
    }
  }
}

.generateEmailSelectAll {
  margin-right: 24px;
}

.generateContactsWrapper {
  @include scroll();
  max-height: 460px;
  overflow: auto;
  margin-bottom: 20px;
  @media (max-width: $lg) {
    max-height: 380px;
  }
}
