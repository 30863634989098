@import 'src/core/styles/variables';

.allocatePricelistQtyWrap {
  font-size: $fsz-16;
  @media (max-width: $lg) {
    font-size: $fsz-14;
  }
  &__list {
    list-style-type: disc;
    padding-left: 0;

    li {
      list-style: inside;
    }
  }

  &__table {
    margin-top: 5px;
  }
}

.allocatePricelistQtyTh {
  &--remaining {
    width: 20%;
    min-width: 100px;
  }
  &--costPrice {
    width: 15%;
    min-width: 95px;
  }
  &--sellingPrice {
    width: 15%;
    min-width: 110px;
  }
  &--addedDate {
    width: 20%;
    min-width: 110px;
  }
  &--purchaseOrderNumber {
    width: 30%;
    min-width: 165px;
  }
}
