@import "src/core/styles/mixins";
@import "src/core/styles/variables";

.select {
  @include flex-column();

  .select-label {
    @include field-label();
  }

  .select-error {
    @include error-message();
  }

  .ant-select.ant-select-focused {
    .ant-select-selector {
      border: $border-dark-blue;
      background-color: $color-gray;
    }
  }

  .ant-select-selector {
    @include flex-center();
    @include form-input();
    min-width: 130px !important;
    padding-right: 42px !important;
    border: $border-dark-gray !important;

    .ant-select-selection-item {
      height: 30px;
    }
  }

  .ant-select-single.ant-select-open {
    .ant-select-selection-item {
      color: $main-dark-blue;
    }
  }

  .ant-select-selection-item::before {
    content: '';
    position: absolute;
    right: 0;
    background-color: rgba($main-dark-blue, 0.5);
    width: 1px;
    height: 30px;
  }

  .ant-select-arrow {
    right: 16px;
    color: $main-dark-blue;
    font-size: $fsz-14;
  }

  .ant-select-clear {
    font-size: $fsz-16;
    right: 17px;
  }

  .ant-select-disabled {
    .ant-select-selector {
      @include form-disabled();
    }

    .ant-select-selection-item::before {
      background-color: $main-gray;
    }

    .ant-select-selector::before {
      background-color: $main-gray;
    }

    .ant-select-arrow {
      color: $main-gray;
    }
  }
}

.select--dark {
  max-width: fit-content;
  .ant-select-selector {
    min-width: 150px;
    background-color: rgba($main-dark-blue, 0.15) !important;
    font-size: $fsz-16 !important;
    @media (max-width: $lg) {
      min-height: 40px;
      min-width: 130px;
      font-size: $fsz-14 !important;
    }
  }

  .ant-select-selector {
    padding-right: 32px !important;

    &::before {
      display: none;
    }
  }

  .ant-select-arrow {
    top: 60%;
    right: 16px;
    color: $main-dark-blue;
    height: 6px;
    width: 10px;
  }

  .ant-select-selection-item::before {
    display: none;
  }

  .ant-select.ant-select-focused {
    .ant-select-selector {
      background-color: rgba($main-dark-blue, 0.15) !important;
    }
  }
}

.ant-select-disabled.ant-select-multiple {
  .ant-select-selection-item {
    background-color: $main-gray !important;
    color: $dark-gray;
  }

  .ant-select-selection-item-content {
    @include text-overflow;
  }
}

.ant-select-item-option-disabled {
  background-color: $main-gray !important;
  color: $dark-gray;
}

.ant-select-single .ant-select-selector,
.ant-select-multiple .ant-select-selector {
  position: relative;

  .ant-select-selection-search {
    right: 50px !important;
  }

  .ant-select-selection-overflow-item {
    .ant-select-selection-search {
      right: 0 !important;
    }
  }

  .ant-select-selection-search-input {
    height: 100% !important;
  }

  &::before {
    content: '';
    position: absolute;
    right: 42px;
    background-color: $dark-gray;
    width: 1px;
    height: 30px;
  }

  &::after {
    content: '' !important;
  }
}

.ant-select-multiple .ant-select-selector {
  padding-left: 10px;

  .ant-select-selection-item {
    align-items: center;
    margin-right: 8px;
    padding: 0 8px;
    background-color: $main-yellow;
    border-radius: 10px;
    font-weight: $font-weight-bold;
    border: none;

    &::before {
      display: none;
    }

    .anticon-close {
      font-size: $fsz-12;
      width: 16px;
      height: 16px;
      transform: translateY(2px);
    }
  }
}

.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown-placement-topLeft {
  padding: 0 !important;
  border: $border-dark-blue-alpha;
  border-radius: 10px !important;
  font-family: $montserrat-font;
  z-index: $z-index-select-dropdown;

  .ant-select-item {
    padding: 10px;
    font-family: $montserrat-font;

    &-option-selected {
      background-color: $color-gray !important;
    }

    &-option-content {
      color: $main-dark-blue;
    }
  }

  .svg-inline--fa {
    min-width: 12px;
    margin-right: 5px;
  }
}


.ant-select-multiple .ant-select-selection-search-input,
.ant-select-multiple .ant-select-selection-search-mirror {
  font-family: inherit;
}
