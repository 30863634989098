@import '/src/core/styles/variables';
@import '/src/core/styles/mixins';

.deleteEmployeeModal {
  &__text {
    @include flex-column();
    font-size: $fsz-16;
    margin-bottom: 24px;
    @media (max-width: $lg) {
      font-size: $fsz-14;
    }
  }

  &__actions {
    @include flex-center();
    flex-wrap: wrap;
    margin: -2px 0;
  }

  &__actions-delete {
    @include flex-center();
    flex-wrap: wrap;
  }

  &__button {
    margin: 2px 5px 2px 5px;
    padding: 10px 15px;

    @media (max-width: $sm) {
      width: 100%;
      max-width: 240px;
      margin: 3px 0;
    }
  }
}
