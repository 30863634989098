@import "../../../../core/styles/mixins";
@import "../../../../core/styles/variables";

.itemProperties {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 2fr) minmax(0, 1fr);
  gap: 15px;
  background-color: $color-gray;
  border-radius: 10px;
  padding: 12px;
  margin-bottom: 30px;

  @media (max-width: $lg) {
    grid-template-columns: 100%;
    gap: 10px;
    margin-bottom: 20px;
  }

  &__item {
    @include flex-column();
    align-items: flex-start;
  }
}

.assignNominalCodesTh {
  &--number {
    min-width: 65px;
  }
  &--type {
    min-width: 95px;
  }
  &--code {
    min-width: 90px;
  }
  &--description {
    width: 34%;
    min-width: 120px;
  }
  &--net {
    width: 19%;
    min-width: 90px;
  }
  &--vat {
    width: 19%;
    min-width: 90px;
  }
  &--linkedTo {
    width: 28%;
    min-width: 125px;
  }
  &--state {
    min-width: 100px;
  }
}

.assignNominalCodesSummaryTh {
  &--status {
    min-width: 170px;
  }
  &--quantity {
    width: 25%;
    min-width: 115px;
  }
  &--net {
    width: 25%;
    min-width: 115px;
  }
  &--vat {
    width: 25%;
    min-width: 115px;
  }
  &--gross {
    width: 25%;
    min-width: 115px;
  }
}
