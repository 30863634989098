.categoryModal {
  &__content {
    display: grid;
    grid-template-columns: 100%;
    gap: 15px;
    margin-bottom: 24px;
  }

  &__text {
    margin-bottom: 0;
  }

  .ant-select-selector {
    min-height: 40px;
  }

  .ant-select-selector,
  .ant-select-selection-item {
    &::before {
      display: none;
    }
  }

  .ant-select-selection-search-input {
    height: 100% !important;
  }
}
