@import "src/core/styles/mixins";

.employeeItem {
  @include line-link();
  @include expanded-sidebar() {
    grid-template-columns: repeat(3, minmax(0, 1fr)) 200px;
  }
  display: grid;
  grid-template-columns: 380px repeat(2, minmax(0, 1fr)) 200px;

  @media (max-width: $lg) {
    grid-template-columns: 100%;
  }

  &__title {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto;
    padding-right: 20px;
    align-items: center;
    column-gap: 20px;
    font-size: $fsz-16;

    @media (max-width: $lg) {
      column-gap: 10px;
      margin-bottom: 8px;
      padding-right: 0;
      font-size: $fsz-14;
    }
  }

  &__icon {
    font-size: $fsz-30;
    grid-row: 1/-1;

    @media (max-width: $lg) {
      font-size: $fsz-24;
    }
  }

  &__name {
    @include text-overflow($max-width: 100%);
    font-weight: $font-weight-bold;
  }

  &__position {
    @include text-overflow($max-width: 100%);
    color: $accent-gray;
  }

  &__data {
    @include flex-start();
    padding: 0 20px;
    font-size: $fsz-16;

    @media (max-width: $lg) {
      margin-bottom: 6px;
      padding: 0;
      font-size: $fsz-14;
    }
  }

  &__data-icon {
    margin-right: 10px;
    font-size: $fsz-24;

    @media (max-width: $lg) {
      margin-right: 16px;
      font-size: $fsz-18;
    }

    &--department {
      @media (min-width: $lg + 1) {
        display: none;
      }
    }
  }

  &__data-value {
    @include text-overflow($max-width: 100%);
  }

  &__department {
    @include flex-center();
    text-align: center;
    padding-left: 20px;
    border-left: 1px solid $main-dark-blue;
    font-size: $fsz-16;

    @media (max-width: $lg) {
      justify-content: flex-start;
      padding-left: 0;
      border-left: 0;
      font-size: $fsz-14;
      text-align: left;
    }
  }
}
