@import "variables";

/*
  1. General
  2. Media queries
  3. Flex model
  4. Typography
  5. Links
  6. Buttons
  7. Components
  8. Filters
*/

/* 1. General */

@mixin scroll($height: 6px) {
  &::-webkit-scrollbar {
    top: 0;
    width: 6px;
    height: $height;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    width: 6px;
    background-color: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: $main-gray;
    transition: $transition;
  }
}

@mixin scroll-hidden() {
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin text-overflow($width: 100%, $max-width: 370px) {
  overflow: hidden;
  width: $width;
  max-width: $max-width;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin vertical-text-overflow($line-clamp: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line-clamp;
  -webkit-box-orient: vertical;
  visibility: visible;
}

@mixin list-unstyled() {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

@mixin btn-unstyled() {
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  box-shadow: none;
}

@mixin visually-hidden() {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}

@mixin absolute-centering() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin tooltip-sm() {
  .ant-tooltip-inner {
    @include scroll();
    max-width: 300px;
    max-height: 300px;
    overflow: auto;
    font-size: $fsz-16;
    padding: 10px !important;

    ul {
      @include list-unstyled();
    }
  }
}

@mixin placeholder-italic($self: true) {
  @if ($self == true) {
    &::placeholder {
      font-style: italic;
    }
  } @else {
    .input-field::placeholder {
      font-style: italic;
    }
  }
}

@mixin hover-focus-active() {
  &:hover,
  &:focus,
  &:active {
    @content
  }
}

@mixin hover-focus-visible() {
  &:hover {
    @content;
  }
  // need to separate due to safari <15.4 issues
  &:focus-visible {
    @content;
  }
}

/* 2. Media queries */

/* 3. Flex model */

@mixin flex-center() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-column() {
  display: flex;
  flex-direction: column;
}

@mixin flex-between() {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin flex-start() {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@mixin flex-end() {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@mixin row-reverse() {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

/* 4. Typography */

/* 5. Links */

@mixin link() {
  color: inherit;
  text-decoration: underline;
  cursor: pointer;

  @include hover-focus-active() {
    color: inherit;
    text-decoration: none;
  }
}

/* 6. Buttons */

/* 7. Components */
@mixin field-label() {
  font-size: $fsz-14;
  color: $main-dark-blue;
}

@mixin field-label-disabled() {
  color: $accent-gray;
}

@mixin error-message() {
  font-size: $fsz-11;
  color: $color-red;
  opacity: .8;
}

@mixin details-activity() {
  width: 100%;
  border-radius: 10px;
  background-color: $color-gray;
  padding: 20px;
  padding-bottom: 50px;
  @media (max-width: $lg) {
    padding: 15px 10px 30px;
  }
}

@mixin details-title() {
  border-bottom: $border-dark-blue;
  padding-bottom: 12px;
  margin-bottom: 12px;
  font-size: 24px;
  font-weight: $font-weight-bold;
  width: 100%;
  color: $main-dark-blue;
  @media (max-width: $lg) {
    font-size: $fsz-20;
  }
}

@mixin line-link() {
  padding: 10px 16px;
  font-size: $fsz-14;
  border: $border-dark-gray;
  border-radius: 10px;
  transition: $transition;
  color: $main-dark-blue;
  background-color: $color-white;
  &:not(.not-link) {
    cursor: pointer;
    &:hover, &:focus {
      background-color: $color-gray;
      color: $main-dark-blue;
    }
  }
}

@mixin summaryTitle {
  font-weight: $font-weight-bold;
  font-size: $fsz-18;
  padding-bottom: 15px;
  border-bottom: 2px solid $main-dark-blue;
  margin-bottom: 18px;
}

@mixin form-input($minheight: 50px) {
  cursor: pointer;
  width: 100%;
  min-height: $minheight;
  padding: 10px;
  font-size: $fsz-16;
  color: $main-dark-blue;
  background-color: $color-white;
  border: $border-dark-gray;
  border-radius: 10px !important;
  box-shadow: none !important;
  transition: $transition;

  &:hover {
    border: $border-dark-blue;
  }

  &:focus {
    border: $border-dark-blue;
    background-color: $color-gray;
    outline: 0;
  }

  @media (max-width: $lg) {
    font-size: $fsz-14;
  }
}

@mixin form-disabled() {
  color: $accent-gray;
  background-color: $color-gray;
  border-color: $main-gray !important;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;

  &:hover {
    border-color: $main-gray !important;
  }
}


@mixin form-select() {
  .ant-select.ant-select-focused {
    .ant-select-selector {
      border: $border-dark-blue;
      background-color: $color-gray !important;
    }
  }

  .ant-select-selector {
    @include flex-center();
    @include form-input();
    padding-right: 42px !important;
    border: $border-dark-gray !important;
  }

  .ant-select-selection-item::before {
    content: '';
    position: absolute;
    right: 0;
    background-color: rgba($main-dark-blue, 0.5);
    width: 1px;
    height: 30px;
  }

  .ant-select-arrow {
    right: 16px;
    color: $main-dark-blue;
    font-size: $fsz-14;
  }

  .ant-select-clear {
    font-size: $fsz-16;
    right: 18px;
  }

  .ant-select-disabled {
    .ant-select-selector {
      @include form-disabled();
    }

    .ant-select-selection-item::before {
      background-color: $main-gray;
    }

    .ant-select-arrow {
      color: $main-gray;
    }
  }
}

@mixin table-checkbox($size: md) {
  .ant-checkbox-inner,
  .ant-tree-checkbox-inner {
    @if ($size == sm) {
      width: 18px;
      height: 18px;
      border-radius: 4px;
    } @else {
      width: 25px;
      height: 25px;
      border-radius: 5px;
    }
    border: $border-dark-gray;
  }

  &:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border: $border-dark-blue;
  }

  .ant-checkbox-checked::after {
    border-radius: 5px;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    @if ($size == sm) {
      width: 6px;
      height: 9px;
      border: 2px solid $color-white;
    } @else {
      width: 8px;
      height: 12px;
      border: 3px solid $color-white;
    }
    border-top: 0;
    border-left: 0;
    transition: $transition;
  }
}

@mixin icon-focus() {
  outline-offset: 4px;
  outline: $border-dark-blue;
  border-radius: 4px;
}

@mixin highlighted-select-value() {
  .ant-select-selector {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    position: relative;
    justify-content: flex-start !important;
  }

  .ant-select-selection-item {
    width: fit-content;
    max-width: 320px;
    flex: none;
    background-color: $main-yellow;
    border-radius: 10px;
    font-weight: $font-weight-bold;
    @media (max-width: $sm) {
      max-width: 260px;
    }

    &::before {
      display: none;
    }
  }

  &::before {
    content: '';
    position: absolute;
  }

  .ant-select-single.ant-select-open .ant-select-selection-item {
    color: $main-dark-blue;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    max-width: calc(100% - 20px);
    margin-left: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .ant-select-selection-overflow-item-suffix {
    top: auto !important;
  }
}

@mixin head-info-full() {
  .head__inner-info-item {
    @media (min-width: $lg + 1) {
      grid-column: span 1;
    }
    @media (max-width: $lg) {
      &:nth-child(3),
      &:nth-child(5) {
        grid-column: 1/3;
      }
      &:nth-child(4),
      &:nth-child(6) {
        grid-column: 3/5;
      }
    }
    @media (max-width: $md) {
      &:nth-child(3),
      &:nth-child(4) {
        grid-column: span 3;
      }
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7) {
        grid-column: span 2;
      }
    }
  }
  @media (max-width: $lg) {
    height: 180px !important;
  }
}

@mixin expanded-sidebar($max-width: 1400px) {
  .layoutMain:not(.layoutNotCollapsed) & {
    @media (min-width: $lg + 1) and (max-width: $max-width) {
      @content;
    }
  }
}

@mixin activity-tags() {
  flex-wrap: wrap;
  &__tag {
    width: auto;
    margin-top: 2px;
    margin-bottom: 2px;
    text-transform: none;

    &--prev {
      margin-right: 8px;
    }
  }

  &__arrow {
    margin-right: 8px;
  }
}

@mixin segmented-sm() {
  margin-bottom: 20px;
  @media (max-width: $lg) {
    margin-bottom: 15px;
  }

  .segmentedCustom {
    height: auto !important;
    width: 100%;
    transition: width 0s;
  }

  &--single {
    &.segmentedCustom {
      width: 230px;
    }
  }

  .ant-segmented-item {
    flex-grow: 1;
  }

  .ant-segmented-item-label {
    width: 100%;
    height: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 12px 14px !important;
    white-space: normal;
    text-overflow: clip;
    word-break: normal;
    font-size: $fsz-16 !important;
  }

  .selectable {
    width: 220px;
  }
}

@mixin modal-choice-buttons() {
  &__choice {
    @include flex-column();
    padding-top: 35px;

    &.choiceHidden {
      display: none;
    }
  }

  &__choice-item {
    height: 60px;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-right: 15px;
    padding-left: 15px;

    & > svg {
      font-size: $fsz-20;
    }

    @include hover-focus-active() {
      background: $color-gray;
    }

    @media (max-width: $lg) {
      padding-right: 10px;
      padding-left: 10px;
    }
  }
}

@mixin button-badge() {
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: auto;
    left: auto;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: $main-yellow;
    transform: translate(50%, -30%);
    opacity: 1;
  }
}

@mixin skeleton-image-and-text() {
  &__info {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    column-gap: 20px;
    padding: 3px 0;

    @media (min-width: $lg + 1) {
      margin-right: 20px;
    }

    @media (max-width: $lg) {
      padding: 0;
      column-gap: 10px;
    }
  }

  &__img {
    width: 30px;
    height: 30px;
    background-color: $skeleton-color;
    border-radius: 4px;

    @media (max-width: $lg) {
      width: 24px;
      height: 24px;
    }
  }

  &__text {
    &::before,
    &::after {
      content: "";
      display: block;
      background-color: $skeleton-color;
      height: 18px;
    }

    &::before {
      width: 100%;
      max-width: 140px;
      margin-bottom: 8px;
    }

    &::after {
      width: 100%;
      max-width: 170px;
    }
  }
}

/* 8. Filters */
