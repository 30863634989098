@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.summaryOrderReq {
  background-color: $color-gray;
  border-radius: 10px;
  padding: 15px 20px 20px;
  @media (max-width: $lg) {
    padding: 14px 10px;
  }
  &-title {
    width: 100%;
    @include summaryTitle();
  }

  &-linesWrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @media (max-width: $lg) {
      display: flex;
      flex-direction: column;
    }

    .reqVehSum {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 20px;
      border-right: $table-line;
      padding-right: 30px;
      @media (max-width: $lg) {
        padding-right: 0;
      }
      @media (max-width: $md) {
        grid-template-columns: 100%;
      }

      &:first-child {
        @media (max-width: $lg) {
          padding-bottom: 24px;
          margin-bottom: 20px;
          border-right: none;
          border-bottom: $table-line;
        }
      }

      &:last-child {
        border-right: none;
        padding-right: 0;
        padding-left: 30px;
        @media (max-width: $lg) {
          padding-left: 0;
        }
      }

      .reqSumLine {
        font-size: $fsz-14;
        @include flex-between();
        .reqSumValue {
          white-space: nowrap;
          text-align: end;
        }

        &--bold {
          font-weight: $font-weight-bold;
        }
      }
    }
  }
}

.partRequestSubmissionButtons {
  @include flex-end;

  & > *:not(:last-child) {
    margin-right: 10px;
  }

  &__info-icon {
    margin-right: 10px;
    font-size: $fsz-24;
  }

  &__info-tooltip {
    @include tooltip-sm();

    .ant-tooltip-arrow {
      display: none;
    }
  }
}

.submitCustomLinesModal__text {
  @media (min-width: $lg + 1) {
    font-size: $fsz-16;
  }
}

.submitCustomLinesModalTh {
  &--ordinalNumber {
    width: 7%;
    min-width: 60px;
  }
  &--categoryId {
    width: 15%;
    min-width: 120px;
  }
  &--productName {
    width: 20%;
    min-width: 150px;
  }
  &--quantity {
    width: 13%;
    min-width: 85px;
  }
  &--unitPrice {
    width: 15%;
    min-width: 115px;
  }
  &--priceSources {
    width: 15%;
    min-width: 80px;
  }
  &--status {
    width: 15%;
    min-width: 90px;
  }
}
