@import "./src/core/styles/mixins";
@import "./src/core/styles/variables";

.inputNumber {
  width: 100%;
  @include flex-column();
  &-label {
    @include field-label();
  }
  &-field {
    border-radius: 5px !important;
    width: 100% !important;
  }
  &-error {
    @include error-message();
  }
}

.regularInputNumber {
  width: 100%;
  @include flex-column();
  &-label {
    @include field-label();
  }
  &-tip {
    margin-left: 4px;
  }
  &__numFieldWrap {
    position: relative;
    display: inline-block;

    &-field {
      @include form-input();
      &:disabled {
        @include form-disabled();

        & + .regularInputNumber__numFieldWrap-postfix {
          color: $accent-gray;
        }
      }

      &::placeholder {
        color: $placeholder-color;
      }
    }

    &-postfix {
      position: absolute;
      right: 32px;
      top: 13px;
      pointer-events: none;
    }

    &--short {
      .regularInputNumber__numFieldWrap-field {
        height: 40px;
        min-height: 40px;
      }
    }

    &--negative {
      & > span:first-child {
        position: absolute;
        left: 10px;
        top: 0;
        bottom: 2px;
        @include flex-center();
        pointer-events: none;

        &.disabled {
          color: $accent-gray;
        }

        & + input {
          padding-left: 15px;
        }
      }
    }
  }
  &-error {
    @include error-message();
  }
}
