@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.partRequestsSkeletonItem {
    display: grid;
    grid-template-columns: 185px auto auto 1fr 95px;

    @media (max-width: $lg) {
        grid-template-columns: 1fr auto;
    }

    &__title {
        @include flex-start();

        &::before,
        &::after {
            content: "";
            display: block;
            background-color: $skeleton-color;
        }

        &::before {
            width: 30px;
            height: 30px;
            margin-right: 20px;
            border-radius: 4px;

            @media (max-width: $lg) {
                width: 24px;
                height: 24px;
            }
        }

        &::after {
            width: 110px;
            height: 18px;
        }
    }

    &__info {
        display: grid;
        grid-template-columns: 180px 150px 170px;
        gap: 10px;
        padding: 4px 20px 5px;
        border-right: $skeleton-border;
        border-left: $skeleton-border;

        @media (max-width: $lg) {
            grid-column: 1/-1;
            grid-template-columns: 100%;
            padding: 0;
            border: 0;
            border-bottom: $skeleton-border;
            padding-bottom: 10px;
            margin-top: 13px;
            margin-bottom: 6px;
        }
    }

    &__info-item {
        @include flex-column();
        justify-content: flex-start;
        &::before,
        &::after {
            content: "";
            display: block;
            background-color: $skeleton-color;
            width: 100%;
            max-width: 80px;
            height: 14px;
        }

        &::before {
            margin-bottom: 8px;

            @media (max-width: $lg) {
                margin-bottom: 6px;
            }
        }

        &::after {
            max-width: 100px;
            height: 16px;
        }

        &:first-child {
            &::after {
                max-width: 120px;
            }
        }
    }

    &__icons {
        @include flex-start();
        padding-left: 20px;
        padding-right: 5px;

        @media (max-width: $lg) {
            grid-column: 2/3;
            padding: 0;
            justify-content: flex-end;
        }

        &::before,
        &::after {
            content: "";
            display: block;
            background-color: $skeleton-color;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            margin-right: 4px;
        }
    }

    &__tag {
        height: 26px;
        width: 80px;
        border-radius: 6px;

        @media (min-width: $lg + 1) {
            align-self: center;
            justify-self: end;
            margin-right: 20px;
        }

        @media (max-width: $lg) {
            grid-row: 1/2;
            grid-column: 2/3;
        }
    }

    &__count {
        @include flex-center();
        flex-direction: column;
        padding-left: 20px;
        border-left: $skeleton-border;

        @media (max-width: $lg) {
            grid-row: 3/4;
            grid-column: 1/2;
            align-items: flex-start;
            border-left: 0;
            padding-left: 0;
        }

        &::before,
        &::after {
            content: "";
            display: block;
            height: 14px;
            background-color: $skeleton-color;
        }

        &::before {
            width: 45px;

            @media (max-width: $lg) {
                width: 60px;
            }
        }

        &::after {
            width: 25px;
            margin-top: 5px;

            @media (max-width: $lg) {
                display: none;
            }
        }
    }
}
