@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.partPricelistWrap {
  @include flex-column();
  &__head {
    @include flex-start();
    margin-bottom: 20px;
    font-size: $fsz-14;
    @media (max-width: $lg) {
      font-size: $fsz-16;
    }
    &-title {
      @include vertical-text-overflow();
      text-transform: uppercase;
    }
  }
  &__head-btn.ant-btn-icon-only {
    width: 35px;
    height: 35px;
    flex-shrink: 0;
    margin-right: 8px;
    border: 0;
    &:not(:disabled) {
      color: $main-dark-blue;
    }

    & > svg {
      font-size: $fsz-20;
    }
  }

  &__buttons {
    margin-bottom: 20px;
  }

  &__paging {
    margin-bottom: 20px;
  }
  &__table {
    margin-bottom: 20px;
  }

  &__measures {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 20px;
    margin-bottom: 40px;
    @media (max-width: $lg) {
      gap: 15px;
      grid-template-columns: repeat(2, minmax(0, 1fr))
    }
    @media (max-width: $md) {
      gap: 10px;
    }

    .regularInputNumber__numFieldWrap-postfix {
      right: 15px;
    }
  }

  .regularInputNumber__numFieldWrap-field {
    font-size: $fsz-14;
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: $color-gray;
  }

  .ant-select-selector {
    font-size: $fsz-14;
  }
}

.selectPricelistTableTh {
  &--number {
    min-width: 70px;
  }
  &--name {
    width: 40%;
    min-width: 170px;
  }
  &--pricelistType {
    width: 30%;
    min-width: 160px;
  }
  &--costPrice {
    width: 30%;
    min-width: 140px;
  }
}
