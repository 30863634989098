@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.requisiteSection {
  &__billing {
    @include flex-column();
    &-label {
      @include field-label();
    }

    &-entities {
      @include form-input();

      &.billingEntitiesDisabled {
        @include form-disabled();
        &:hover {
          border-color: $main-gray !important;
        }
      }
      &-line {
        display: flex;
        align-items: flex-start;

        &__icon {
          flex-shrink: 0;
          width: 15px;
          color: $accent-gray;
          margin-right: 10px;
          transform: translateY(3px);
        }

        &__label {
          padding-left: 10px;
          border-left: $border-main-gray;
          font-size: $fsz-14;
          max-width: 90%;
          word-break: break-word;
          @include scroll(4px);
        }

        &.empty {
          display: none;
        }
      }
    }

    &-checkbox {
      @include table-checkbox(sm);
      margin-top: 10px !important;
      @media (max-width: $lg) {
        margin-top: 6px !important;
      }
    }
  }
}

.billingModal {
  &__inner {
    position: relative;
    display: grid;

    @media (min-width: $lg + 1) {
      grid-auto-flow: column;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-template-rows: repeat(5, auto);
      gap: 10px 24px;
    }

    @media (max-width: $lg) {
      row-gap: 10px;
    }

    & > .spinner {
      z-index: 1;
    }
  }

  &__city {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 15px;
  }

  &__postal {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 10px;

    .customButton.ant-btn-icon-only {
      margin-top: 22px;

      @media (max-width: $lg) {
        width: 50px;
        height: 50px;
      }
    }
  }
}

.billingModalSource {
  margin: 10px 0;
}
