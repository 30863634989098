@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.suppliersSkeletonItem {
    display: grid;
    grid-template-columns: 1fr auto;

    @media (max-width: $lg) {
        grid-template-columns: 100%;
        padding: 10px;
    }

    @include skeleton-image-and-text();

    &__list {
        @media (min-width: $lg + 1) {
            @include flex-between();
            width: 404px;
            padding-left: 20px;
            border-left: $skeleton-border;
        }

        @media (max-width: $lg) {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            column-gap: 15px;
            margin-top: 10px;
        }

        @media (max-width: $md) {
            grid-template-columns: 100%;
            row-gap: 3px;
            margin-top: 15px;
        }
    }

    &__list-item {
        @media (max-width: $md) {
            &:nth-child(2) {
                &::before {
                    width: 130px;
                }
            }
            &:nth-child(3) {
                &::before {
                    width: 85px;

                }
            }
        }

        &::before,
        &::after {
            content: "";
            display: block;
            height: 14px;
            background-color: $skeleton-color;

            @media (min-width: $lg + 1) {
                margin: 0 auto;
            }
        }

        &::before {
            width: 100px;
            margin-bottom: 5px;
        }

        &::after {
            width: 30px;

            @media (max-width: $lg) {
                display: none;
            }
        }
    }
}
