@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.upper-details {
  margin-bottom: 20px;
}

.orderVehSectionWrapper {
  padding-bottom: 10px;
}
