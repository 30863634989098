@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.stockDashboard {
  .info-grid {
    @media (min-width: $lg + 1) {
      margin-top: 40px;
      grid-template-columns: 1fr 2fr;
      column-gap: 30px;
    }

    @media (max-width: $lg) {
      margin-top: 20px;
    }

    &__column {
      padding-bottom: 20px;
    }

    &__title,
    &__title-inner {
      font-size: $fsz-20;

      @media (max-width: $sm) {
        font-size: $fsz-18;
      }
    }

    &__title-inner {
      margin-right: 10px;
    }
  }
}

.totalPartQuantity {
  &__list {
    @include list-unstyled();
    display: grid;
    grid-template-columns: repeat(2, minmax(180px, 1fr));
    grid-template-rows: repeat(4, 1fr);
    grid-auto-flow: column;
    gap: 15px;

    @media (max-width: $sm) {
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      grid-auto-flow: row;
    }
  }

  &__item {
    @include flex-between();
    padding: 15px 10px;
    border-radius: 8px;
    background-color: $color-white;
  }

  &__value {
    font-weight: $font-weight-bold;
  }
}
