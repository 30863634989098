@import "src/core/styles/mixins";
@import "src/core/styles/variables";

.vehicleSourcesModal {
  @include modal-choice-buttons();
}

.vehicleInfoSource {
  @include flex-column();

  &--offset-bottom {
    margin-bottom: 15px;
  }

  &__label {
    @include field-label();
  }
}

.existedVehicleCatalogSection {
  &__input,
  &__pagination {
    margin-bottom: 20px;
  }
}

.existedVehicleCatalogColumnsTh {
  &--ordinalNumber {
    min-width: 65px;
  }

  &--code {
    width: 27%;
    min-width: 120px;
  }

  &--manufacturer {
    width: 23%;
    min-width: 165px;
  }

  &--group {
    width: 20%;
    min-width: 135px;
  }

  &--modelDescription {
    width: 30%;
    min-width: 200px;
  }

  &--action {
    min-width: 105px;
  }
}

.chosenVehicleProfile {
  grid-column: 1/-1;
  position: relative;
  padding: 10px 30px 10px 10px;
  background-color: $color-white;
  border-radius: 10px;
  border: $border-dark-gray;

  &--read-only {
    padding-right: 10px;
  }

  &__button {
    position: absolute;
    top: 10px;
    right: 10px;

    .fa-ellipsis-vertical {
      transform: none;
    }
  }

  &__info {
    @include list-unstyled();
    display: grid;
    grid-template-columns: repeat(var(--columns-count), minmax(0, 1fr));
    gap: 20px 10px;

    & + & {
      margin-top: 20px;
      @media (max-width: $md) {
        margin-top: 15px;
      }
      @media (max-width: $sm) {
        margin-top: 10px;
      }
    }

    @media (max-width: $md) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 15px 10px;
    }
    @media (max-width: $sm) {
      grid-template-columns: 100%;
      gap: 10px;
    }
  }

  &__item {
    @include flex-column();

    &--wide {
      grid-column: 1/-1;
    }
  }

  &__label {
    font-size: $fsz-14;
    color: $accent-gray;
  }
}

.existedChosenVehicleForm,
.commonVehicleFields,
.vehicleManualFields {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  grid-column: 1/-1;
  gap: 20px 15px;
  @media (max-width: $lg) {
    gap: 12px 15px;
  }

  &__field {
    &--2 {
      grid-column: span 2;
    }

    &--3 {
      grid-column: span 3;
    }

    &--6 {
      grid-column: span 6;
    }

    &--2,
    &--3,
    &--6 {
      @media (max-width: $md) {
        grid-column: span 6;
      }
    }
  }
}

.commonVehicleFields {
  &--offset-top {
    margin-top: 20px;

    @media (max-width: $lg) {
      margin-top: 12px;
    }
  }
}

.emptySelectedVehicleProfile {
  @include flex-column();
  align-items: center;
  padding: 40px 10px;
  background-color: $color-white;
  border-radius: 10px;
  @media (max-width: $lg) {
    padding: 25px 10px;
  }

  &--large {
    padding: 80px 10px;

    @media (max-width: $lg) {
      padding: 40px 10px;
    }
  }

  &--bordered {
    border: $border-dark-gray;

    &.emptySelectedVehicleProfile--disabled {
      border-color: $main-gray;
    }
  }

  &--disabled {
    background-color: $main-light-gray;
    color: rgba($color-black, 0.25);
  }

  &__icon {
    font-size: 70px;
    margin-bottom: 20px;

    @media (max-width: $lg) {
      font-size: 50px;
    }
  }

  &__text {
    margin-bottom: 15px;
    font-size: $fsz-16;
    @media (max-width: $lg) {
      font-size: $fsz-14;
    }
  }
}
