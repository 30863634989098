@import 'src/core/styles/variables';
@import 'src/core/styles/mixins';

.purchaseOrdersSkeletonItem {
    display: grid;
    grid-template-columns: 180px 1fr 1fr 230px 120px;

    @media (max-width: $lg) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 13px 20px;
    }

    @media (max-width: $md) {
        grid-template-columns: 1fr auto;
    }

    &__title {
        @include flex-start();

        &::before,
        &::after {
            content: "";
            display: block;
            background-color: $skeleton-color;
        }

        &::before {
            width: 30px;
            height: 30px;
            margin-right: 20px;
            border-radius: 4px;

            @media (max-width: $lg) {
                width: 24px;
                height: 24px;
                margin-right: 10px;
            }
        }

        &::after {
            width: 110px;
            height: 18px;
        }
    }

    &__data {
        padding: 6px 20px 5px;
        border-left: $skeleton-border;

        @media (max-width: $lg) {
            border-left: 0;
            padding: 0;
        }

        @media (max-width: $md) {
            grid-column: 1/-1;
        }

        &::before,
        &::after {
            content: "";
            display: block;
            height: 14px;
            background-color: $skeleton-color;
        }

        &::before {
            width: 90px;
            margin-bottom: 6px;

            @media (max-width: $lg) {
                margin-bottom: 8px;
            }
        }

        &::after {
            height: 18px;
            width: 130px;

            @media (max-width: $lg) {
                height: 14px;
            }
        }
    }

    &__tag {
        height: 26px;
        width: 95px;
        border-radius: 6px;
        justify-self: end;

        @media (min-width: $lg + 1) {
            align-self: center;
            margin-right: 20px;
            margin-left: 20px;
        }

        @media (max-width: $lg) {
            grid-row: 1/2;
            grid-column: 2/3;
            margin-bottom: 8px;
        }
    }

    &__count {
        @include flex-center();
        flex-direction: column;
        padding-left: 20px;
        border-left: $skeleton-border;

        @media (max-width: $lg) {
            border-left: 0;
            grid-row: 2/3;
            grid-column: 2/3;
            align-items: flex-start;
            padding-left: 0;
        }

        @media (max-width: $md) {
            grid-row: 4/5;
            grid-column: 1/-1;
            margin-bottom: 4px;
        }

        &::before,
        &::after {
            content: "";
            display: block;
            height: 14px;
            background-color: $skeleton-color;
        }

        &::before {
            width: 45px;
        }

        &::after {
            width: 25px;
            margin-top: 5px;
        }
    }
}
