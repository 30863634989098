.infoMessagesTh {
  &--title {
    width: 25%;
    min-width: 130px;
  }
  &--message {
    width: 30%;
    min-width: 160px;
  }
  &--displayOrder {
    width: 15%;
    min-width: 190px;
  }
  &--status {
    width: 15%;
    min-width: 135px;
  }
  &--createDate {
    width: 15%;
    min-width: 190px;
  }
}

.infoMessagesForm {
  display: grid;
  gap: 15px;
  margin-bottom: 20px;
}
