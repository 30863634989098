@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.editConfirmWrap {
  font-size: $fsz-16;
  @media (max-width: $lg) {
    font-size: $fsz-14;
  }

  &__split {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  &__text {
    display: block;
    margin-bottom: 20px;

    &:empty {
      display: none;
    }
  }
}
