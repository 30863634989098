@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.ant-dropdown-menu {
  padding: 0;
  min-width: 160px;
  border: $border-dark-blue;
}

.ant-dropdown-menu-item {
  padding: 10px;
  font-size: $fsz-16;
  white-space: nowrap;

  &:not(.ant-dropdown-menu-item-disabled) {
    color: $main-dark-blue;

    &:hover {
      background-color: rgba($main-dark-blue, 0.1);
    }
  }

  @media (max-width: $lg) {
    font-size: $fsz-14;
  }
}

.ant-dropdown-menu-item-divider {
  display: none;
}

.ant-dropdown-menu-item-icon {
  font-size: $fsz-16;
  margin-right: 12px;

  @media (max-width: $lg) {
    font-size: $fsz-14 !important;
  }
}

.dropdownTriggerBtn {
  background: none;
  border: none;
  box-shadow: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:hover .svg-inline--fa {
    color: $main-dark-blue;
  }

  &:focus-visible .svg-inline--fa {
    color: $main-dark-blue;
    @include icon-focus();
  }

  .svg-inline--fa {
    color: $dark-gray;
    height: 18px;
    transition: $transition;

    &:hover {
      color: $main-dark-blue;
      outline: none;
    }
  }

  &:disabled,
  &:disabled:hover,
  &:disabled .svg-inline--fa,
  &:disabled:hover .svg-inline--fa, {
    cursor: not-allowed !important;
    color: lighten($accent-gray, 17%) !important;
  }
}
