@import "src/core/styles/variables";
@import "src/core/styles/mixins";

.summary-row {
  @include details-activity();
  margin-top: 20px;
  padding-bottom: 20px;
  @media (max-width: $lg) {
    padding: 14px 10px;
  }

  &__title {
    @include details-title();
    font-size: $fsz-18;
    padding-bottom: 10px;
    margin-bottom: 14px;
    border-width: 2px;
  }

  &__sections {
  @include flex-start();
    @media (max-width: $lg) {
      flex-direction: column;
      align-items: stretch;
    }
  }

  &__data {
    @include flex-between();
    width: 25%;
    border-right: $border-dark-blue;
    border-width: 2px;
    padding: 0 20px;
    font-size: $fsz-14;
    @media (max-width: $lg) {
      width: 100%;
      margin-bottom: 15px;
      padding: 0;
      border: 0;
    }
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
      border-right: 0;
      @media (max-width: $lg) {
        margin-bottom: 0;
      }
    }
    &--bold {
      font-weight: $font-weight-bold;
    }

    & > span:first-child {
      margin-right: 10px;
    }
    & > span:last-child {
      white-space: nowrap;
    }
  }
}
